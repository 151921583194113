import React, { useState } from "react";
import styles from "./styles.module.scss";
import { ReactComponent as CaretDown } from "@wfp/icons/dist/svg/caret--down.svg";
import { Tag } from "@wfp/ui";

export type AccordionItemType = {
  title: string;
  data: {
    label?: string;
    value: boolean | number | string | JSX.Element;
  }[];
};

type Props = {
  item: AccordionItemType;
};

const AccordionItem = ({ item }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = () => setExpanded((p) => !p);
  return (
    <div className={styles.item}>
      <div className={styles.itemHeader} onClick={toggle}>
        <div className={styles.itemHeaderTitle}>{item.title}</div>
        <CaretDown width={15} style={{ transform: `rotate(${expanded ? 180 : 0}deg)` }} fill="#000" />
      </div>
      {expanded && (
        <div className={styles.itemBody}>
          {item.data.map((data) => {
            return (
              <div className={styles.itemBodyRow} key={`${item.title}-${data.label}-accordion-row`}>
                {!!data.label && (
                  <Tag className={styles.itemBodyRowLabel} type="info">
                    {data.label}
                  </Tag>
                )}
                <div>{data.value}</div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default AccordionItem;
