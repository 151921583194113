import React from "react";
import styles from "./styles.module.scss";
import { Link, Modal, Table } from "@wfp/ui";

type Props = {
  isOpened: boolean;
  setOpened: (open: boolean) => void;
};

const ModalCodeRulesModal = ({ isOpened, setOpened }: Props) => {
  return (
    <Modal
      label="Meeting code rules"
      wide
      modalFooter={() => (
        <div className={styles.modalFooter}>
          <div>Please note YYYY is year in four digits, X a progressive number starting with 1 every year</div>
          <div>*A Meeting Code can be used to one or more meeting types.</div>
        </div>
      )}
      open={isOpened}
      onRequestClose={() => setOpened(false)}
    >
      <Table
        style={{
          overflow: "scroll",
          borderCollapse: "separate",
          borderSpacing: "0 15px",
        }}
        className={styles.modalTable}
      >
        <thead className={styles.modalTableTitle}>
          <tr>
            <th className={styles.modalTableTitleItem}>Meeting code format</th>
            <th className={styles.modalTableTitleItem}>Code decrypted</th>
          </tr>
        </thead>
        <thead>
          <tr className={styles.modalTableSectionTitle}>
            <th>EB Informal (and others)</th>
            <th></th>
          </tr>
        </thead>
        <tbody className={styles.modalTableSectionBody}>
          <tr>
            <td>EB1/YYYY</td>
            <td>Executive Board - First Regular Session</td>
          </tr>
          <tr>
            <td>EB2/YYYY</td>
            <td>Executive Board - Second Regular Session</td>
          </tr>
          <tr>
            <td>EBA/YYYY</td>
            <td>Executive Board – Annual Session</td>
          </tr>
          <tr>
            <td>SpecialX/YYYY</td>
            <td>Executive Board - Special Session</td>
          </tr>
        </tbody>
        <thead className={styles.modalTableSectionTitle}>
          <tr>
            <th>EB Informal (and others)</th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ACX/YYYY</td>
            <td>Independent Oversight Advisory Committee</td>
          </tr>
          <tr>
            <td>BriefingX/YYYY</td>
            <td>Briefing/Update</td>
          </tr>
          <tr>
            <td>EBBX/YYYY</td>
            <td>Executive Board - Bureau Meeting</td>
          </tr>
          <tr>
            <td>ELX/YYYY</td>
            <td>
              <div className="mb-4">Electoral List Meetings*</div>
              <div className="mb-4">ED Electoral List Meetings*</div>
            </td>
          </tr>
          <tr>
            <td>EventX/YYYY</td>
            <td>
              <div className="mb-4">Annual Joint Informal Meeting of the RBA governing bodies*</div>
              <div className="mb-4">Annual WFP Partnership Consultation with the Membership*</div>
              <div className="mb-4">Board Awareness/ Governance/ Visits organized by EBS*</div>
              <div className="mb-4">RBA Event*</div>
            </td>
          </tr>
          <tr>
            <td>FAOX/YYYY</td>
            <td>FAO Finance Committee</td>
          </tr>
          <tr>
            <td>ICX/YYYY</td>
            <td>
              <div className="mb-4">Informal Consultation*</div>
              <div className="mb-4">Annual Consulation on Evaluation*</div>
            </td>
          </tr>
          <tr>
            <td>InductionX/YYYY</td>
            <td>Induction Session</td>
          </tr>
          <tr>
            <td>JBMX/YYYY</td>
            <td>Joint Board/management meeting (working groups)</td>
          </tr>
          <tr>
            <td>JointX/YYYY</td>
            <td>Joint Meetings of the Executive Board</td>
          </tr>
          <tr>
            <td>RTX/YYYY</td>
            <td>Round Table</td>
          </tr>
          <tr>
            <td>SeminarX/YYYY</td>
            <td>Seminar</td>
          </tr>
          <tr>
            <td>UNWGX/YYYY</td>
            <td>UN working groups</td>
          </tr>
          <tr>
            <td>VCX/YYYY</td>
            <td>Virtual Consultation</td>
          </tr>
        </tbody>
      </Table>
    </Modal>
  );
};

export default ModalCodeRulesModal;
