import React, { useMemo } from "react";
import styles from "./styles.module.scss";
import TabTable from "~/pages/PersonalArea/TabTable";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import { DocItemType } from "~/document";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import { FetcherState } from "~/pages/PersonalArea/common";
import { PaginatedResult } from "~/util/PaginatedEndpoint";
import paths from "~/paths";
import Accordions, { useDocItemsAccordionState } from "~/components/Accordions/Accordions";
import { useSkipInitialRender } from "~/util/hooks";
import moment from "moment/moment";
import { PUB_DATE_FORMAT } from "~/constants";

type Props = {
  fetcher: FetcherState<DocItemType>;
  items: PaginatedResult<DocItemType>;
};

const HomeDocumentsTable = observer(({ fetcher, items }: Props) => {
  const documentsTableData = useMemo(() => items?.results || [], [items]);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Document title",
          accessor: `title`,
          Cell: ({ value, cell }) => {
            const { protocol } = cell.row.original;
            return (
              <NavLink
                data-testid={`${protocol}-doc`}
                title={value}
                to={paths.construct.docDetail(protocol.toString())}
                className="wfp--link two-lines-text-truncate"
              >
                {value}
              </NavLink>
            );
          },
          width: 350,
          minWidth: 150,
          maxWidth: 600,
        },
        {
          Header: "Document Symbol",
          accessor: "symbol",
        },
        {
          Header: "Meeting codes",
          accessor: "meetings",
          Cell: ({ value }) => {
            return value ? value.join(", ") : [];
          },
        },
        {
          Header: "Date",
          accessor: "dss_data__publication_date",
          Cell: ({ cell }) => {
            return moment(cell.row.original.dss_data_preview?.publication_date).format(PUB_DATE_FORMAT);
          },
        },
        {
          Header: "Language",
          accessor: "language_display",
        },
      ] as Column<DocItemType>[],
    []
  );

  const accordionData = useDocItemsAccordionState(fetcher.itemsCombined);

  const tableInstance = useTable(
    {
      columns: columns,
      data: documentsTableData as DocItemType[],
    },
    usePagination
  );

  const onSort = () => fetcher.onSortingChange(tableInstance.state.sortBy);
  useSkipInitialRender(onSort, [tableInstance.state.sortBy]);

  return (
    <div className={styles.meetingsTableContainer}>
      <div className="hide-mobile">
        <TabTable
          fetcher={fetcher}
          tableInstance={tableInstance}
          emptyTitle="No documents"
          paginationProps={{
            pageSizesDisabled: true,
          }}
          emptyMessage="No documents uploaded"
        />
      </div>
      <Accordions items={accordionData} fetcher={fetcher} containerClassName="only-mobile" />
    </div>
  );
});

export default HomeDocumentsTable;
