import notificationStore, { NotificationStore } from "~/stores/notifications";
import { ReqStatus } from "~/util";
import { makeAutoObservable, runInAction } from "mobx";
import * as api from "~/api";
import { MeetingId } from "~/stores/meeting";

export type LinkId = number;

export interface LinkNode {
  id: LinkId;
  api_url: string;
  meeting: MeetingId;
  description_en: string;
  description_fr?: string;
  description_es?:	string;
  description_ar?:	string;
  url_en: string;
  url_fr?: string;
  url_es?: string;
  url_ar?: string;
}

export class LinkStore {
  notifStore: NotificationStore;
  links: LinkNode[];
  status: ReqStatus = ReqStatus.Initial;

  get loading(): boolean {
    return this.status === ReqStatus.InProcess;
  }

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.links = [];
    this.notifStore = notifStore;
  }

  createLink = async (link: LinkNode) => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    })
    try {
      const resp = await api.post("ebms:links", link);
      runInAction(() => {
        this.status = ReqStatus.Success;
      });
      return resp!.data;
    } catch (e) {
      this.notifStore.error("Failed to create link");
      this.status = ReqStatus.Failed;
      return null;
    }
  }

  updateLink = async (link: LinkNode, id: LinkId) => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    })
    try {
      const resp = await api.patch(`ebms:links/${id}`, link);
      runInAction(() => {
        this.status = ReqStatus.Success;
      });
      return resp!.data;
    } catch (e) {
      this.notifStore.error("Failed to update link");
      this.status = ReqStatus.Failed;
      return null;
    }
  }

  deleteLink = async (id: number | string): Promise<boolean | null> => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    })
    try {
      await api.del(`ebms:links/${id}`);
      runInAction(() => {
        this.status = ReqStatus.Failed;
      })
      return true;
    } catch (e) {
      this.notifStore.error("Failed to delete link");
      this.status = ReqStatus.Failed;
      return null;
    }
  }

  loadLinks = async (): Promise<LinkNode[]> => {
    if (this.status === ReqStatus.Success) return this.links;
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const resp = await api.get("ebms:links");
      const links = resp!.data;
      runInAction(() => {
        this.links = links;
        this.status = ReqStatus.Success;
      });
      return links;
    } catch (err) {
      this.notifStore.error("Failed to load links");
      this.status = ReqStatus.Failed;
      return [];
    }
  }
}

const linkStore = new LinkStore(notificationStore);

export default linkStore;
