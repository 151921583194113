import { makeAutoObservable } from "mobx";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import notifStore from "./notifications";
import { DivisionInfo, DivisionId, Division } from "~/document";

export type { Division, DivisionInfo, DivisionId };

export class DivisionStore {
  f = new PaginatedEndpoint<DivisionInfo>("divisions", notifStore);

  constructor() {
    makeAutoObservable(this);
  }
}

const divisionStore = new DivisionStore();

export default divisionStore;
