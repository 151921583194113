import docStore, { DocumentId } from "~/stores/document";
import { Document, DocumentSearch } from "~/document";
import { observer } from "mobx-react";
import AsyncPaginatedSelect, { AsyncPaginatedSelectProps } from "~/components/AsyncPaginatedSelect";

interface Props extends Partial<Omit<AsyncPaginatedSelectProps<DocumentSearch>, "onChange">> {
  value: number[];
  onChange: (newValue: DocumentSearch[]) => void;
  itemToString?: (item: DocumentSearch) => string;
  idKey?: string;
}

const DocumentSelect = observer((props: Props) => {
  const fetcher = docStore.f;
  const idToOpt = (id: number) => {
    const item = fetcher.items[id];
    if (!item) return null;
    return {
      label: props.itemToString ? props.itemToString(item) : item.title !== "" ? item.title : item.protocol,
      value: item[props.idKey || "id"],
      protocol: item.protocol,
    };
  };
  return (
    <AsyncPaginatedSelect
      {...props}
      idKey={props.idKey}
      fetcher={docStore.f}
      itemIdToOption={idToOpt}
      value={props.value}
      onChange={(ids) => {
        if (ids instanceof Array) {
          props.onChange(ids.map((id) => fetcher.items[id]));
        } else {
          props.onChange([fetcher.items[ids]]);
        }
      }}
    />
  );
});

export default DocumentSelect;
