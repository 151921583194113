import React, { useContext, useEffect } from "react";
import MeetingsSearch from "~/pages/Meetings/MeetingsSearch";
import MeetingsTable from "~/pages/Meetings/MeetingsTable";
import { observer } from "mobx-react";
import meetingStore, { MeetingLanguage } from "~/stores/meeting";
import { CommonTabProps } from "~/pages/PersonalArea/TabTable";
import { useDataFetcher } from "~/pages/PersonalArea/common";
import meetingTypeStore from "~/stores/meetingTypes";

export const MeetingLanguageContext = React.createContext<{ lang: MeetingLanguage }>({ lang: "en" });
export const useLanguage = () => {
  const { lang } = useContext(MeetingLanguageContext);
  return lang;
};

type Props = {
  lang: MeetingLanguage;
} & CommonTabProps;

const MeetingsTab = observer(({ lang, selected }: Props) => {
  useEffect(() => {
    meetingTypeStore.loadMeetingTypes();
  }, []);
  return (
    <MeetingLanguageContext.Provider value={{ lang }}>
      {selected && <MeetingsTable selected />}
    </MeetingLanguageContext.Provider>
  );
});

export default MeetingsTab;
