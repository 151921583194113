import React, { useEffect } from "react";
import styles from "./styles.module.scss";
import cn from "classnames";

interface PageProps {
  title: string;
  children: React.ReactNode;
  className?: string;
}

const Page = (props: PageProps) => {
  useEffect(() => {
    document.title = `EBMS | ${props.title}`;
  }, [props.title]);
  return <div className={cn([styles.page, props.className])}>{props.children}</div>;
};

export const PageBreadcrumbs = (props: { children: React.ReactNode }) => {
  return <div className="p-16">{props.children}</div>;
};

export default Page;
