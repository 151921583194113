import React, { useCallback, useState } from "react";
import { Row, Col } from "react-grid-system";
import LanguageSelect from "~/components/LanguageSelect";
import TagSelect from "~/components/TagSelect";
import TopicSelect from "~/components/TopicSelect";
import CountrySelect from "~/components/CountrySelect";
import DocTypeSelect, { Props as DocTypeSelectProps } from "~/components/DocumentTypeSelect";
import AccessLevelSelect from "~/components/AccessLevelSelect";
import SeriesSelect from "~/components/SeriesSelect";
import { RangedDatePicker, SingleDatePicker } from "~/components/PublicationDateSelect";
import ProjectNumberSelect from "~/components/ProjectNumberSelect";
import OwnerSelect from "~/components/OwnersSelect";
import OfficeSelect from "~/components/OfficeSelect";
import DivisionSelect from "~/components/DivisionSelect";
import DocInstanceSelect from "~/components/DocInstanceSelect";
import { Custom, DateRange, DocDateFields, DocInstanceId, EditableDocPayload } from "~/document";
import ExpirationDateSelect from "~/components/ExpirationDateSelect";
import { TagId } from "~/stores/tags";
import { DocSeriesId, Access } from "~/document";
import { TopicId } from "~/stores/topics";
import { CountryId } from "~/stores/country";
import { ProjectId } from "~/stores/projects";
import { OwnerId } from "~/stores/owners";
import { OfficeId } from "~/stores/offices";
import { DivisionId } from "~/stores/divisions";
import { DocTypeId } from "~/stores/documentTypes";
import DelayedTextInput from "~/components/BouncedInput";
import PDFOptionSelect from "~/components/PDFOptionSelect";
import { declString } from "~/util";
import styles from "./styles.module.scss";
import FormGroup from "~/components/FormGroup";
import { FormHint, TextArea } from "@wfp/ui";
import moment from "moment";
import MeetingCodesSelect from "~/components/MeetingCodesSelect";
import TextAreaWithLink from "~/components/TextAreaWithLink/TextAreaWithLink";
import TitleVisibleForPublicUsersSelect from "~/components/TitleVisibleForPublicUsersSelect";

type CommonFormValue = Pick<EditableDocPayload, "title" | "protocol" | "number"> & {
  topics: Set<TopicId>;
  docTypes: Set<DocTypeId>;
  tags: TagId[];
  countries: CountryId[];
  meeting_codes?: string[];
  languages: string[];
  comment?: string;
  author?: OwnerId | OwnerId[];
  access: Access[];
  instance?: DocInstanceId;
  projects: ProjectId[];
  series: DocSeriesId[];
  owners: OwnerId[];
  offices: OfficeId[];
  divisions: DivisionId[];
  abstract?: string;
  pdf_option?: number;
  custom?: Custom;
};

export type MultipleFormValue = CommonFormValue & {
  [P in DocDateFields]: DateRange | undefined;
};

export type SingleFormValue = CommonFormValue &
  Omit<
    {
      [P in DocDateFields]: Date | undefined;
    },
    "expiration_date"
  > & {
    expiration_date: Date | null | undefined;
  };
type CustomKeys =
  | "custom/comment"
  | "custom/decision"
  | "custom/summary"
  | "custom/disclaimer"
  | "custom/is_title_visible_to_public";

export type SingleOnChangeKeys = CustomKeys | keyof SingleFormValue;
export type MultipleOnChangeKeys = CustomKeys | keyof MultipleFormValue;

interface MultipleProps {
  multipleDocs: true;
  onChange: (key: MultipleOnChangeKeys, value: any) => void;
  value: MultipleFormValue;
  docTypeSelectProps?: Partial<DocTypeSelectProps>;
  formProps?: Partial<Record<keyof MultipleFormValue, any>>;
  typesIncludesGovernanceBody?: boolean;
}

export type SingleFormProps = Partial<Record<keyof SingleFormValue, any>>;
interface SingleProps {
  multipleDocs: false;
  onChange: (key: SingleOnChangeKeys, value: any) => void;
  value: SingleFormValue;
  docTypeSelectProps?: Partial<DocTypeSelectProps>;
  formProps?: SingleFormProps;
  typesIncludesGovernanceBody?: boolean;
}

const tooltipInfoMapping = {
  Title: "Official title of the document – to be entered in the language of the document.",
  Language: "Select the language of the document.",
  Topics:
    "Select from list of taxonomy topics\nMultiple topics from the whole taxonomy can be selected," +
    " but selection of at least one topic term is mandatory. The topics are displayed in a hierarchical format " +
    "of 3 levels.\\nSelect the topic term(s) from the whole taxonomy that best describe(s) the main content of the" +
    " document.\\nE.g. If you upload a new Gender Policy, select the entry-level topic 'Gender'. " +
    "If you upload guidelines on gender mainstreaming, select the sub-topic 'Gender mainstreaming'.\\n" +
    "When selecting sub-topics, do not also select the respective entry-level topics, as they are automatically captured.",
  "Document types":
    "Selection of one document type is mandatory; a second one is optional – to be used if " +
    "appropriate. Select the document type(s) under which most users would expect to find your document.",
  Tags:
    "Tags are free keywords that can be used to describe the main content of your document, if the taxonomy " +
    "is not specific enough. Do not create a tag for terms that are included in the lists of Topics, " +
    "Document types or Geographical coverage. If you create a tag that includes an acronym, write the full name " +
    "first and the acronym behind in parenthesis. Avoid using special characters in tags. " +
    "Create tags in English only.",
  "Geographical coverage":
    "If the content of a document relates to one or more " + "specific country/countries, indicate those.",
  "Author(s)":
    "For documents produced by WFP, enter the name(s) of the main person(s) in " +
    "WFP responsible for writing the document (First name Last name). For documents not produced by" +
    " WFP, enter the name(s) of the external author(s) and/or institutions who published " +
    "the document (e.g. Robert Brown, UNICEF)",
  Access:
    "Indicate if this document should be accessible only to WFP employees or to the public " +
    "(e.g. on the public website).",
  "Document symbol":
    "To be entered only if your document has an official symbol " +
    "(e.g. Executive Board documents, Circulars & Directives, Long-term agreements, Evaluations etc.). " +
    "Ensure consistency in the use of symbols.",
  "Project number":
    "When uploading project documents (EMOPs, PRROs or else), budget revisions, " +
    "standard project reports or project resource updates, enter the WINGS project number of the respective project.",
  Series: "If your document belongs to a defined series of regularly published documents, select the series.",
  "Publication date": "Date on which a document gets officially published.",
  "Release date":
    "Date on which a document gets released in GoDocs. You can set a release date in the " +
    "future (if a document should only be available from a certain date onwards), but not in the past.",
  "Expiration date": "Date on which a document gets archived, unless the expiration date gets extended.",
  "Document manager": "",
  Office: "Indicate the location of the office responsible for managing the document.",
  Division:
    "For documents uploaded at Headquarters, indicate the division unit that is responsible " +
    "for managing the document.",

  Notes: "",
  "PDF Options":
    "Indicate whether the document should appear 1. preferred in pdf format (Convert to PDF); " +
    "2. only in its native file format (Do Not Convert to PDF).",
  Abstract:
    "To be used as a teaser for longer, complex documents – to be entered in the language of " +
    "the document (max. 1,500 characters including spaces).",
};

const getOr = (obj: Record<string, any>, key: string, def: any) => {
  return obj[key] ?? def;
};

type Props = SingleProps | MultipleProps;

function isMultiple(props: Props): props is MultipleProps {
  return props.multipleDocs;
}

const pdfOptionMapping: any = {
  0: "PDF Not Allowed",
  1: "PDF Allowed",
  3: "PDF Preferred download type",
  5: "Only allow pdf format",
};

function DocumentForm(props: Props) {
  const { onChange, multipleDocs, docTypeSelectProps, typesIncludesGovernanceBody } = props;

  const formProps = props.formProps || {};
  const { value: { pdf_option = 1 } = {} } = props;

  return (
    <div className={`${styles.form} doc-form`}>
      <Row className="mb-12">
        <Col xs={12} md={6}>
          <FormGroup
            legendText={
              <>
                <span className={formProps.title?.invalid ? styles.errorLabel : ""}>Title*</span>
                <FormHint>{tooltipInfoMapping["Title"]}</FormHint>
              </>
            }
          >
            <DelayedTextInput
              {...getOr(formProps, "title", {})}
              value={props.value.title ?? ""}
              onChange={(ev) => onChange("title", ev)}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            legendText={
              <>
                Language*
                <FormHint>{tooltipInfoMapping["Language"]}</FormHint>
              </>
            }
            {...getOr(formProps, "languages", {})}
          >
            <LanguageSelect
              value={props.value.languages}
              onChange={(langs) => onChange("languages", langs)}
              selectProps={{
                isMulti: multipleDocs,
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <div className="divider mv-12" />
      <Row className="mb-12">
        <Col xs={12} md={6}>
          <FormGroup
            legendText={
              <>
                <span className={formProps.topics?.invalid ? styles.errorLabel : ""}>Topics*</span>
                <FormHint>{tooltipInfoMapping["Topics"]}</FormHint>
              </>
            }
            {...getOr(formProps, "topics", {})}
          >
            <TopicSelect
              invalid={!!formProps.topics?.invalid}
              value={props.value.topics}
              onChange={(topics) => onChange("topics", topics)}
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            legendText={
              <>
                <span className={formProps.docTypes?.invalid ? styles.errorLabel : ""}>Document types*</span>
                <FormHint>{tooltipInfoMapping["Document types"]}</FormHint>
              </>
            }
            {...getOr(formProps, "docTypes", {})}
          >
            <DocTypeSelect
              invalid={!!formProps.docTypes?.invalid}
              value={props.value.docTypes}
              onChange={(value) => onChange("docTypes", value)}
              {...docTypeSelectProps}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-24">
        <Col xs={12} md={6}>
          <FormGroup
            legendText={
              <>
                Tags
                <FormHint>{tooltipInfoMapping["Tags"]}</FormHint>
              </>
            }
            {...getOr(formProps, "tags", {})}
          >
            <TagSelect
              tagValueKey={multipleDocs ? "id" : "label"}
              value={props.value.tags}
              onChange={(tags) => onChange("tags", tags)}
              isCreatable
            />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            legendText={
              <>
                Geographical coverage
                <FormHint>{tooltipInfoMapping["Geographical coverage"]}</FormHint>
              </>
            }
            {...getOr(formProps, "countries", {})}
          >
            <CountrySelect value={props.value.countries} onChange={(value) => onChange("countries", value)} />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-24">
        <Col xs={12} md={6}>
          <FormGroup
            legendText={
              <>
                <span className={formProps.author?.invalid ? styles.errorLabel : ""}>Author(s)*</span>
                <FormHint>{tooltipInfoMapping["Author(s)"]}</FormHint>
              </>
            }
          >
            <DelayedTextInput
              value={props.value.author}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange("author", e)}
              {...getOr(formProps, "author", {})}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-24">
        <Col xs={12} md={12}>
          <FormGroup
            legendText={
              <>
                <span className={formProps.access?.invalid ? styles.errorLabel : ""}>Access*</span>
                <FormHint>{tooltipInfoMapping["Access"]}</FormHint>
              </>
            }
            {...getOr(formProps, "access", {})}
          >
            <AccessLevelSelect
              value={props.value.access}
              onChange={(value) => onChange("access", value)}
              selectProps={{ isMulti: multipleDocs }}
            />
          </FormGroup>
        </Col>
      </Row>
      {+props.value.access?.[0] === Access.Restricted && (
        <Row className="mb-24">
          <Col xs={12} md={12}>
            <FormGroup legendText="Title visible for public users" {...getOr(formProps, "visibility", {})}>
              <TitleVisibleForPublicUsersSelect
                value={props.value.custom?.is_title_visible_to_public}
                onChange={(value) => onChange("custom/is_title_visible_to_public", value)}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row className="mb-24">
        <Col xs={12} md={12}>
          <FormGroup
            legendText={
              <>
                Document symbol
                <FormHint>{tooltipInfoMapping["Document symbol"]}</FormHint>
              </>
            }
            {...getOr(formProps, "protocol", {})}
          >
            <DelayedTextInput
              value={props.value.number ?? ""}
              onChange={(e) => onChange("number", e)}
              placeholder="e.g. OE/2011/021"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-24">
        <Col xs={12}>
          <FormGroup legendText="Meeting codes" {...getOr(formProps, "", {})}>
            <MeetingCodesSelect
              value={props.value.meeting_codes || []}
              onChange={(value) => onChange("meeting_codes", value as any)}
            />
          </FormGroup>
        </Col>
      </Row>
      {typesIncludesGovernanceBody && !multipleDocs && (
        <Row className="mb-24">
          <Col xs={12}>
            <FormGroup {...getOr(formProps, "instance", {})} legendText={<>Instance*</>}>
              <DocInstanceSelect
                value={props.value.instance}
                onChange={(value: DocInstanceId) => {
                  onChange("instance", value as any);
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row className="mb-24">
        <Col xs={12} md={6}>
          <FormGroup
            {...getOr(formProps, "projects", {})}
            legendText={
              <>
                Project number
                <FormHint>{tooltipInfoMapping["Project number"]}</FormHint>
              </>
            }
          >
            <ProjectNumberSelect value={props.value.projects} onChange={(value) => onChange("projects", value)} />
          </FormGroup>
        </Col>
        <Col xs={12} md={6}>
          <FormGroup
            {...getOr(formProps, "series", {})}
            legendText={
              <>
                Series
                <FormHint>{tooltipInfoMapping["Series"]}</FormHint>
              </>
            }
          >
            <SeriesSelect
              value={props.value.series}
              onChange={(value: DocSeriesId[]) => onChange("series", value)}
              selectProps={{ isMulti: multipleDocs }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-24">
        <Col xs={12}>
          <FormGroup
            {...getOr(formProps, "publication_date", {})}
            legendText={
              <>
                Publication date
                <FormHint>{tooltipInfoMapping["Publication date"]}</FormHint>
              </>
            }
          >
            {isMultiple(props) ? (
              <RangedDatePicker
                value={props.value.publication_date}
                withDefaultValue={false}
                allowPast
                onChange={(value) => onChange("publication_date", value)}
                helperText="Select document publication date"
              />
            ) : (
              <SingleDatePicker
                value={props.value.publication_date}
                allowPast
                onChange={(value) => {
                  onChange("publication_date", value);
                }}
                helperText="Select document publication date"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-24">
        <Col xs={12}>
          <FormGroup
            {...getOr(formProps, "release_date", {})}
            legendText={
              <>
                Release date
                <FormHint>{tooltipInfoMapping["Release date"]}</FormHint>
              </>
            }
          >
            {isMultiple(props) ? (
              <RangedDatePicker
                value={props.value.release_date}
                onChange={(value) => onChange("release_date", value)}
                helperText="Select document release date"
                allowPast
                withDefaultValue={false}
              />
            ) : (
              <SingleDatePicker
                allowPast={false}
                value={props.value.release_date || moment().toDate()}
                onChange={(value) => onChange("release_date", value)}
                helperText="Select document release date"
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-24">
        <Col xs={12}>
          <FormGroup
            {...getOr(formProps, "expiration_date", {})}
            legendText={
              <>
                <span className={formProps.expiration_date?.invalid ? styles.errorLabel : ""}>Expiration date*</span>
                <FormHint>{tooltipInfoMapping["Expiration date"]}</FormHint>
              </>
            }
          >
            {isMultiple(props) ? (
              <RangedDatePicker
                onChange={(value) => onChange("expiration_date", value)}
                disabled={formProps["expiration_date"]?.disabled}
                helperText="Select expiration date range"
                value={props.value.expiration_date}
                allowPast
                withDefaultValue={false}
              />
            ) : (
              <>
                {props.value.expiration_date !== null && (
                  <SingleDatePicker
                    value={props.value.expiration_date}
                    allowPast={false}
                    onChange={(value) => onChange("expiration_date", value)}
                    helperText=""
                  />
                )}
                <div className="mt-6">
                  <ExpirationDateSelect
                    value={props.value.expiration_date}
                    onChange={(value) => onChange("expiration_date", value)}
                    disabled={formProps["expiration_date"]?.disabled}
                    startDate={formProps["expiration_date"]?.startDate}
                  />
                </div>
              </>
            )}
          </FormGroup>
        </Col>
      </Row>
      {!multipleDocs && (
        <Row className="mb-24">
          <Col xs={12} md={12}>
            <FormGroup
              legendText={
                <>
                  Abstract
                  <FormHint>{tooltipInfoMapping["Abstract"]}</FormHint>
                </>
              }
              {...getOr(formProps, "protocol", {})}
            >
              <TextArea
                onChange={(e) => {
                  const target = e.target as unknown as HTMLInputElement;
                  onChange("abstract", target.value);
                }}
                name="abstract"
              />
            </FormGroup>
          </Col>
        </Row>
      )}
      <Row className="mb-24">
        {multipleDocs && (
          <Col xs={12} md={12}>
            <FormGroup {...getOr(formProps, "owners", {})} legendText={declString("Document manager", multipleDocs)}>
              <OwnerSelect
                value={props.value.owners}
                onChange={(value) => onChange("owners", value)}
                selectProps={{
                  isMulti: multipleDocs,
                }}
              />
            </FormGroup>
          </Col>
        )}
        <Col xs={24} md={12}>
          <FormGroup
            {...getOr(formProps, "offices", {})}
            legendText={
              <>
                {declString("Office", multipleDocs)}*<FormHint>{tooltipInfoMapping["Office"]}</FormHint>
              </>
            }
          >
            <OfficeSelect
              value={props.value.offices}
              onChange={(value) => onChange("offices", value)}
              selectProps={{
                isMulti: multipleDocs,
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mb-24">
        <Col xs={12} md={6}>
          <FormGroup
            {...getOr(formProps, "divisions", {})}
            legendText={
              <>
                {declString("Division", multipleDocs)}
                <FormHint>{tooltipInfoMapping["Division"]}</FormHint>
              </>
            }
          >
            <DivisionSelect
              value={props.value.divisions}
              onChange={(value) => onChange("divisions", value)}
              selectProps={{
                isMulti: multipleDocs,
              }}
            />
          </FormGroup>
        </Col>
      </Row>
      {!multipleDocs && (
        <>
          <Row className="mb-24">
            <Col xs={12} md={12}>
              <FormGroup legendText="Notes" {...getOr(formProps, "protocol", {})}>
                <TextArea
                  onChange={(e) => {
                    const target = e.target as unknown as HTMLInputElement;
                    onChange("comment", target.value);
                  }}
                  value={props.value.comment}
                  name="comment"
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-24">
            <Col xs={12} md={12}>
              <FormGroup legendText="PDF Option" {...getOr(formProps, "protocol", {})}>
                <PDFOptionSelect
                  value={pdfOptionMapping[pdf_option]}
                  onChange={(value) => onChange("pdf_option", getPDFOption(value))}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row className="mb-24">
            <Col xs={12} md={12}>
              <FormGroup legendText="Summary">
                <TextAreaWithLink
                  defaultValue={props.value.custom?.summary}
                  onChange={(htmlString) => onChange("custom/summary", htmlString)}
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          <Row className="mb-24">
            <Col xs={12} md={12}>
              <FormGroup legendText="Decisions and recommendations">
                <TextAreaWithLink
                  defaultValue={props.value.custom?.decision}
                  onChange={(htmlString) => onChange("custom/decision", htmlString)}
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          <Row className="mb-24">
            <Col xs={12} md={12}>
              <FormGroup legendText="Disclaimer">
                <TextAreaWithLink
                  defaultValue={props.value.custom?.disclaimer}
                  onChange={(htmlString) => onChange("custom/disclaimer", htmlString)}
                />
              </FormGroup>
            </Col>
          </Row>{" "}
          <Row className="mb-24">
            <Col xs={12} md={12}>
              <FormGroup legendText="Comment">
                <TextAreaWithLink
                  defaultValue={props.value.custom?.comment}
                  onChange={(htmlString) => onChange("custom/comment", htmlString)}
                />
              </FormGroup>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

const getPDFOption = (val: string | null | number) => {
  return Object.keys(pdfOptionMapping).find((key) => pdfOptionMapping[key] === val);
};
export default DocumentForm;
