import React, { useContext, useEffect, useMemo } from "react";
import styles from "./styles.module.scss";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import { Button } from "@wfp/ui";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import paths from "~/paths";
import meetingStore, { MeetingNode, meetingStatusMap } from "~/stores/meeting";
import { MeetingLanguageContext } from "~/pages/Meetings/MeetingsTab";
import { observer } from "mobx-react";
import { FetcherState, useDataFetcher } from "~/pages/PersonalArea/common";
import { PaginatedResult } from "~/util/PaginatedEndpoint";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import Accordions, { useMeetingAccordionState } from "~/components/Accordions/Accordions";
import TabTable from "../PersonalArea/TabTable";
import { useSkipInitialRender } from "~/util/hooks";
import { PUB_DATE_FORMAT } from "~/constants";
import moment from "moment";
import meetingTypeStore from "~/stores/meetingTypes";
import MeetingsSearch from "~/pages/Meetings/MeetingsSearch";

type Props = {
  selected: boolean;
};

const MeetingsTable = observer(({ selected }: Props) => {
  const { lang } = useContext(MeetingLanguageContext);
  let [searchParams] = useSearchParams();

  const fetcher = useDataFetcher(selected, meetingStore.loadMeetings, {
    currentPageKey: "page_num",
    nextKey: "next_page",
    prevKey: "previous_page",
    pagesCountKey: "pages_count",
    itemsCountKey: "total_items_count",
    pageSizeKey: "page_items_count",
    type: "infinite",
  });
  const { items } = fetcher;

  const meetingsTableData = useMemo(() => items.results || [], [items]);
  const meetingTypes = useMemo(() => meetingTypeStore.meetingTypes, [meetingTypeStore.meetingTypesList]);

  const columns = useMemo(
    () => [
      {
        Header: "Meeting code",
        accessor: "code",
      },
      {
        Header: "Event title",
        accessor: `title_${lang}`,
        Cell: ({ cell }) => {
          const meeting = cell.row.original;
          return meeting[`title_${lang}`] || meeting["title_en"] || "";
        },
        minWidth: 200,
        whiteSpace: "normal",
      },
      {
        Header: "Start date",
        accessor: "start_date",
        Cell: ({ cell }) => {
          return moment(cell.row.original.start_date).format(PUB_DATE_FORMAT);
        },
      },
      {
        Header: "Meeting type",
        accessor: "meeting_type",
        Cell: ({ cell }) => {
          const meetingType = cell.row.original.meeting_type;
          return meetingTypes[meetingType] || "";
        },
        width: 180,
        maxWidth: 210,
        whiteSpace: "normal",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell }) => {
          const status = cell.row.original.status;
          return meetingStatusMap[status] || "";
        },
        maxWidth: 90,
        width: 70,
      },
      {
        Header: "Edit",
        Cell: ({ cell }) => (
          <Link to={paths.construct.editMeeting(cell.row.original.id)}>
            <FontAwesomeIcon icon={regular("pen-to-square")} />
          </Link>
        ),
        center: true,
        width: 20,
      },
      {
        Header: "Preview",
        Cell: ({ cell }) => (
          <Link to={paths.construct.meetingPreview(cell.row.original.id)}>
            <FontAwesomeIcon icon={solid("eye")} />
          </Link>
        ),
        center: true,
        width: 20,
      },
      {
        Header: "Clone",
        Cell: ({ cell }) => (
          <Link to={paths.construct.cloneMeeting(cell.row.original.id)}>
            <FontAwesomeIcon icon={regular("clone")} />
          </Link>
        ),
        center: true,
        width: 20,
      },
    ],
    [lang, meetingTypes]
  );

  const tableInstance = useTable(
    {
      columns: columns as Column<MeetingNode>[],
      data: meetingsTableData as MeetingNode[],
      manualSortBy: true,
      initialState: {
        sortBy: [
          {
            id: searchParams.get("ordering")?.replace("-", "") || "start_date",
            desc: searchParams.get("ordering")?.includes("-") || false,
          },
        ],
      },
    },
    useSortBy,
    usePagination
  );

  const onSort = () => fetcher.onSortingChange(tableInstance.state.sortBy);

  useSkipInitialRender(onSort, [tableInstance.state.sortBy?.[0]?.id, tableInstance.state.sortBy?.[0]?.desc]);
  useSkipInitialRender(() => {
    fetcher.reload();
  }, [meetingStore.meetingsQueryParams]);

  const accordionData = useMeetingAccordionState(fetcher.itemsCombined);

  return (
    <div>
      <MeetingsSearch setSortBy={tableInstance.setSortBy} />
      <div className={styles.meetingsTableContainer}>
        <Link to="/meetings/upload" className="mb-10">
          <Button kind="secondary">Add new meeting</Button>
        </Link>
        <div className="hide-mobile w-100">
          <TabTable
            fetcher={fetcher}
            tableInstance={tableInstance}
            paginationProps={{
              pageSizesDisabled: true,
            }}
            emptyTitle="No meetings"
            emptyMessage="No meetings uploaded"
          />
        </div>
        <Accordions items={accordionData} containerClassName="only-mobile" fetcher={fetcher} />
      </div>
    </div>
  );
});

export default MeetingsTable;
