import React from "react";
import DynamicSuggestionInput from "~/components/DynamicSuggestionInput/DynamicSuggestionInput";
import meetingStore from "~/stores/meeting";
import { observer } from "mobx-react";

interface Props {
  value: string[];
  onChange: (newValue: string[]) => void;
}

const MeetingCodesSelect = observer(({ onChange, value }: Props) => {
  const addMeetingCode = (code: string) => onChange([...value, code]);
  const removeMeetingCode = (code: string) => onChange(value.filter((item) => item !== code));
  return (
    <DynamicSuggestionInput
      load={meetingStore.searchMeetingCodes}
      loading={meetingStore.loadingCodes}
      results={meetingStore.meetingCodesSearch.results}
      currentSearchValue={meetingStore.meetingCodesSearch.search}
      shouldBeCleared={meetingStore.codesSearchEmpty}
      addItem={addMeetingCode}
      removeItem={removeMeetingCode}
      selected={value}
      isUploadStyle
      inputProps={{
        placeholder: "Nothing selected",
      }}
    />
  );
});

export default MeetingCodesSelect;
