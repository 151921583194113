/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios";
import jwt_decode from "jwt-decode";

export enum LocalStorageKeys {
  TOKEN = "auth__user-token",
  ID_TOKEN = "auth__user-id-token",
  REFRESH_TOKEN = "auth__user-refresh-token",
  CODE_VERIFIER = "auth__pkce-code-verifier",
  AUTH_TOKEN = "auth__auth-token",
  EXPIRATION = "expiration",
  ACCESS_TOKEN = "auth__access-token",
  USERNAME = "username",
  EMAIL = "email",
}

export const config = {
  authorization_endpoint: `${process.env.REACT_APP_AUTH_AUTHORIZATION_ENDPOINT}`,
  client_id: `${process.env.REACT_APP_AUTH_CLIENT_ID}`,
  redirect_uri: `${process.env.REACT_APP_AUTH_REDIRECT_URI}`,
  token_endpoint: `${process.env.REACT_APP_AUTH_TOKEN_ENDPOINT}`,
  logout_endpoint: `${process.env.REACT_APP_AUTH_LOGOUT_ENDPOINT}`,
  scopes: ["email", "openid", "profile"],
};

type TokenPayload = {
  agency: string;
  email: string;
  exp: string;
  identities: { [key: string]: string }[];
  preferred_username: string;
};

const accessToken = localStorage.getItem(LocalStorageKeys.TOKEN);
const idToken = localStorage.getItem(LocalStorageKeys.ID_TOKEN);
const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);

let tokens = accessToken === null ? null : { idToken, refreshToken };

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getTokens = () => tokens;

export const deleteTokens = (): void => {
  localStorage.clear();
  tokens = null;
  // window.location.href = "/";
};

export const logout = () => {
  return axios.get(config.logout_endpoint);
};

export const getAuthTokens = async (code: string, grantType = "authorization_code") => {
  try {
    const code_verifier = localStorage.getItem(LocalStorageKeys.CODE_VERIFIER);

    const params = new URLSearchParams();
    params.append("grant_type", grantType);
    params.append(grantType === "authorization_code" ? "code" : "refresh_token", code || "");
    params.append("client_id", config.client_id || "");
    params.append("redirect_uri", config.redirect_uri || "");
    params.append("code_verifier", code_verifier || "");

    const { data } = await axios.post(config.token_endpoint, params, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });

    const { id_token, refresh_token } = data;

    if (id_token && refresh_token) {
      const { email, exp, preferred_username } = jwt_decode<TokenPayload>(id_token);

      localStorage.removeItem(LocalStorageKeys.AUTH_TOKEN);
      localStorage.setItem(LocalStorageKeys.AUTH_TOKEN, id_token);
      localStorage.setItem(LocalStorageKeys.EXPIRATION, exp);
      localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, refresh_token);
      localStorage.setItem(LocalStorageKeys.EMAIL, email);
      localStorage.setItem(LocalStorageKeys.USERNAME, preferred_username);
    }

    localStorage.removeItem("pkce_state");
    localStorage.removeItem("pkce_code_verifier");

    tokens = {
      idToken: id_token,
      refreshToken,
    };

    return refresh_token;
  } catch (e) {
    localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
    localStorage.removeItem(LocalStorageKeys.ID_TOKEN);
    localStorage.setItem("invalid_grant", "error");

    const loginUrl = await generatePkceAuthUrl();

    if (loginUrl) {
      window.location.href = loginUrl;
    }
  }
};

// Generate a secure random string using the browser crypto functions
export const generatePkceAuthUrl = async (): Promise<string> => {
  // Create and store a random "state" value
  const state = generateRandomString();
  localStorage.setItem("pkce_state", state);

  // Create and store a new PKCE code_verifier (the plaintext random secret)
  const code_verifier = generateCodeVerifier();

  localStorage.setItem(LocalStorageKeys.CODE_VERIFIER, code_verifier);

  const code_challenge = await generateCodeChallengeFromVerifier(code_verifier);

  const params = `response_type=code&client_id=${config.client_id}&state=${state}&scope=${config.scopes.join(
    " "
  )}&redirect_uri=${config.redirect_uri}&code_challenge=${code_challenge}&code_challenge_method=S256`;

  // Build the authorization URL
  return `${config.authorization_endpoint}?${params}`;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const exchangeAuthCodeForTokens = async () => {
  const callbackUrl = new URLSearchParams(window.location.search);

  if (callbackUrl.has("error")) {
    const error = callbackUrl.get("error");
    throw new Error(`Error returned from authorization server: ${error}`);
  }

  // Exchange code for our tokens
  if (callbackUrl.has("code")) {
    try {
      const code = callbackUrl.get("code");
      const res = await getAuthTokens(code || "");
      return res;
    } catch (e: any) {
      console.log(e);
    }
  }
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const refreshAccessToken = async () => {
  const params = new URLSearchParams();
  params.append("grant_type", "refresh_token");
  params.append("client_id", config.client_id || "");
  const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);
  params.append("refresh_token", refreshToken || "");

  try {
    const { data, status } = await axios.post(config.token_endpoint, params, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    const { access_token, id_token, expires_in } = data;

    if (status === 200 && access_token && id_token) {
      localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, access_token);
      localStorage.setItem(LocalStorageKeys.ID_TOKEN, id_token);
      const prevExpiration = localStorage.getItem(LocalStorageKeys.EXPIRATION);
      localStorage.setItem(LocalStorageKeys.EXPIRATION, prevExpiration + expires_in);
      return access_token;
    }

    return "";
  } catch (err: any) {
    const { status } = err.response;
    if (status === 400) {
      localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      localStorage.removeItem(LocalStorageKeys.ID_TOKEN);
      localStorage.setItem("invalid_grant", "error");

      const loginUrl = await generatePkceAuthUrl();

      if (loginUrl) {
        window.location.href = loginUrl;
      }
    }
  }
};

// PKCE HELPER FUNCTIONS

function dec2hex(dec) {
  return ("0" + dec.toString(16)).substr(-2);
}

function generateCodeVerifier() {
  const array = new Uint32Array(56 / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, dec2hex).join("");
}

function sha256(plain) {
  // returns promise ArrayBuffer
  const encoder = new TextEncoder();
  const data = encoder.encode(plain);
  return window.crypto.subtle.digest("SHA-256", data);
}

function base64urlencode(a) {
  let str = "";
  const bytes = new Uint8Array(a);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    str += String.fromCharCode(bytes[i]);
  }
  return btoa(str).replace(/\+/g, "-").replace(/\//g, "_").replace(/=+$/, "");
}

async function generateCodeChallengeFromVerifier(v) {
  const hashed = await sha256(v);
  const base64encoded = base64urlencode(hashed);
  return base64encoded;
}

// Generate a secure random string using the browser crypto functions
const generateRandomString = (): string => {
  const randomItems = new Uint32Array(28);
  crypto.getRandomValues(randomItems);
  const binaryStringItems = randomItems.map((dec) => `0${dec.toString(16).substr(-2)}` as any);

  return binaryStringItems.reduce((acc, item) => `${acc}${item}`, "");
};
