import { makeAutoObservable } from "mobx";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import notifStore, { NotificationStore } from "~/stores/notifications";
import { DocumentRelationType, DocumentRelationTypeId } from "~/document";

export type { DocumentRelationType, DocumentRelationTypeId };

export class DocSeriesStore {
  f = new PaginatedEndpoint<DocumentRelationType>("relation-types", notifStore);

  constructor() {
    makeAutoObservable(this);
  }
}

const docSeriesStore = new DocSeriesStore();

export default docSeriesStore;
