import { createContext } from "react";
import { Document } from "~/document";
import SearchQuery from "~/searchQuery";

interface IHomePageContext {
  onDocumentClick: (doc: Document) => void;
  searchQuery: SearchQuery;
  setSearchQuery: (v: SearchQuery) => void;
  onSearch: (searchQuery: SearchQuery) => void;
}

const DocumentsContext = createContext<IHomePageContext>({} as any);

export default DocumentsContext;
