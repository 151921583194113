import docStore from "~/stores/document";
import { observer } from "mobx-react";
import { CommonTabProps } from "./TabTable";
import { useDataFetcher } from "./common";
import DocsTable from "./DocsTable";
import { useMemo } from "react";

const MyDocsTab = observer((props: CommonTabProps) => {
  const user = docStore.authStore.userInfo;
  const fetcher = useDataFetcher(props.selected, docStore.loadMyDocuments, {
    initialFetchDependencyList: [props.selected, user.user],
    prevKey: "previous",
    nextKey: "next",
  });
  return useMemo(() => {
    if (!user.user) return null;
    return <DocsTable fetcher={fetcher} docsResult={fetcher.items} user={user} />;
  }, [fetcher, user]);
});

export default MyDocsTab;
