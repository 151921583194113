import { DocumentProto } from "~/document";
import SearchQuery from "~/searchQuery";
import { TemplateId } from "./stores/templates";

const paths = {
  match: {
    index: "/", //
    docDetail: "/browse/:id",
    browse: "/documents-search/*",
    editDocument: "/edit/:id",
    editMeeting: "/meetings/edit/:meetingId",
    uploadMeeting: "/meetings/upload",
    cloneMeeting: "/meetings/clone/:meetingId",
    personalArea: "/personal-area/*",
    uploadDocWithTemplate: "/upload/with-template/:templateId",
    uploadSimilar: `/upload/with-document/:documentId`,
    meetingPreview: "/meetings/preview/:meetingId",
    meetings: "/meetings",
    documents: "/documents",
  },
  construct: {
    docDetail: (documentProto: DocumentProto) => `/browse/${documentProto}`,
    docDetailWithSearchQuery: (documentProto: DocumentProto, query: URLSearchParams) =>
      `/browse/${documentProto}?${query.toString()}`,
    notFound: () => "/not-found/",
    uploadDoc: () => "/upload/",
    uploadDocWithTemplate: (template: TemplateId) => `/upload/with-template/${template}`,
    uploadSimilar: (proto: DocumentProto) => `/upload/with-document/${proto}`,

    editDocument: (protocol: DocumentProto) => `/edit/${protocol}`,
    editDocumentRelations: (protocol: DocumentProto) => `/edit/${protocol}#relations`,
    editDocumentLangVersions: (protocol: DocumentProto) => `/edit/${protocol}#translations`,

    editMeeting: (id: number) => `/meetings/edit/${id}`,
    cloneMeeting: (id: number) => `/meetings/clone/${id}`,
    uploadMeeting: () => `/meetings/upload`,
    meetingPreview: (id: number | string) => `/meetings/preview/${id}`,

    personalArea: () => "/personal-area/",
    documents: () => "/documents/",
    meetings: () => "/meetings/",
    index: () => "/",
    help: () => "/help",
    admin: () => "/admin",
    browse: (searchQuery?: SearchQuery) => {
      if (!searchQuery || searchQuery.isEmpty()) return "/";
      const searchParams = searchQuery.toQueryString();
      const searchString = searchParams.toString();
      const path = `/documents-search/?${searchString}`;
      return path;
    },
    statistics: () => "/statistics/",
    advancedSearch: (searchQuery?: SearchQuery | null) => {
      if (!searchQuery || searchQuery.isEmpty()) return "/advanced-search/";
      const searchParams = searchQuery.toQueryString();
      const searchString = searchParams.toString();
      const path = `/advanced-search/?${searchString}`;
      return path;
    },
    savedSearchManagement: () => `/personal-area#saved`,
  },
};

export default paths;
