import React, { useCallback } from "react";
import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import agendaItemDocsStore from "~/stores/agendaItemDocs";
import { MeetingId } from "~/stores/meeting";
import { Link } from "react-router-dom";
import paths from "~/paths";
import moment from "moment";
import { Button, InlineLoading } from "@wfp/ui";
import { AgendaItem, AgendaItemId, AgendaItemUnboundDocument } from "~/stores/agendaItems";
import { useDrag } from "react-dnd";
import cn from "classnames";
import { ReqStatus } from "~/util";

export const AGENDA_ITEM_DOC_DND = "agenda-item-doc";

const AvailableDocument = ({ doc, meetingId }: { doc: AgendaItemUnboundDocument; meetingId: MeetingId }) => {
  const addDocToAgendaItem = useCallback(
    (agendaItemId: AgendaItemId, position?: number) => {
      agendaItemDocsStore.addDocItem(doc.id, agendaItemId, meetingId, position);
    },
    [meetingId, doc.id]
  );

  const [{ isDragging }, drag] = useDrag({
    type: AGENDA_ITEM_DOC_DND,
    item: doc,
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<{ id: number; position?: number; agendaItemId: AgendaItemId }>();
      if (dropResult) {
        addDocToAgendaItem(dropResult.agendaItemId, dropResult.position);
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  const opacity = isDragging ? 0.8 : 1;

  return (
    <div style={{ opacity }} className={styles.docsItem} ref={drag}>
      <Link to={paths.construct.docDetail(doc.protocol)} className={cn(styles.docsItemField, styles.docsItemFieldLg)}>
        {doc.title}
      </Link>
      <div className={styles.docsItemField}>
        {doc.release_date ? moment(doc.release_date).format("DD/MM/YYYY") : null}
      </div>
      <div className={styles.docsItemField}>{doc.language_display}</div>
    </div>
  );
};

type Props = {
  meetingId: MeetingId;
  agendaItems?: AgendaItem[];
};

const AvailableDocumentsField = observer(({ meetingId, agendaItems = [] }: Props) => {
  const availableDocsIds =
    agendaItemDocsStore.getAvailableDocsForMeeting(
      meetingId,
      agendaItems.map(({ id }) => id)
    ) || [];
  const allDocuments = agendaItemDocsStore.documentsByMeeting[meetingId] || [];
  const availableDocuments = allDocuments.filter(({ id }) => availableDocsIds.includes(id));

  return (
    <>
      <div className="section-title df fvc jcs">
        <div>Available documents</div>{" "}
        {agendaItemDocsStore.documentsStatus === ReqStatus.InProcess && (
          <InlineLoading style={{ height: 18 }} className="f-0" />
        )}
      </div>
      <div className={styles.docsContainer}>
        {availableDocuments.map((doc) => (
          <AvailableDocument doc={doc} meetingId={meetingId} key={`available-docs-${doc.id}`} />
        ))}
      </div>
      <Button
        disabled={agendaItemDocsStore.documentsStatus === ReqStatus.InProcess}
        className="mt-4"
        onClick={() => agendaItemDocsStore.loadDocuments(meetingId)}
      >
        <div className="df fvc">Refresh</div>
      </Button>
    </>
  );
});

export default AvailableDocumentsField;
