import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Checkbox, Tag, Text, TextInput, TextInputProps } from "@wfp/ui";
import * as api from "~/api";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";

type Props = {
  inputProps?: TextInputProps;
  load: (search: string) => any;
  loading: boolean;
  shouldBeCleared?: boolean;
  results: string[];
  currentSearchValue: string;
  minimumSearchValueLength?: number;
  selected: string[];
  addItem: (item: string) => any;
  removeItem: (item: string) => any;
  isUploadStyle?: boolean;
};

const DynamicSuggestionInput = (props: Props) => {
  const {
    inputProps,
    load,
    loading,
    results,
    currentSearchValue,
    minimumSearchValueLength = 2,
    addItem,
    removeItem,
    selected,
    shouldBeCleared,
    isUploadStyle,
  } = props;
  const [searchValue, setSearchValue] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);

  const onInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setSearchValue(value);
    },
    [setSearchValue]
  );

  const onCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        addItem(e.target.value);
        setSearchValue("");
        setShowSuggestions(false);
      } else removeItem(e.target.value);
    },
    [addItem, removeItem]
  );

  useEffect(() => {
    if (searchValue.length >= minimumSearchValueLength && searchValue !== currentSearchValue) {
      load(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (shouldBeCleared) {
      setSearchValue("");
      setShowSuggestions(false);
    }
  }, [shouldBeCleared]);

  return (
    <div className={isUploadStyle ? `${styles.container} ${styles.uploadStyle}` : styles.container}>
      <TextInput
        className={isUploadStyle ? styles.uploadStyleInput : ""}
        {...inputProps}
        value={searchValue}
        onChange={onInputChange}
        onFocus={() => setShowSuggestions(true)}
      />
      {showSuggestions && (
        <div className={styles.results}>
          {results.map((item, i) => (
            <div className={`${styles.resultsItem}`} key={`suggestion-${item}`}>
              <Checkbox
                id={`suggestion-${item}-checkbox`}
                checked={selected.includes(item)}
                value={item}
                onChange={onCheckboxChange}
              />
              <Text>{item}</Text>
            </div>
          ))}
          {!results.length && loading && <Text className="p-6">Searching...</Text>}
          {!results.length && searchValue.length >= minimumSearchValueLength && !loading && (
            <Text className="p-6">No results found</Text>
          )}
          {!results.length && searchValue.length < minimumSearchValueLength && (
            <Text className="p-6">Please enter 2 or more characters</Text>
          )}
        </div>
      )}
      {showSuggestions && <div className={styles.overlay} onClick={() => setShowSuggestions(false)} />}

      {!!selected.length && (
        <div className={styles.selected}>
          {selected.map((item, i) => (
            <Tag key={`dynamic-suggestion-${item}-${i}`} type="info" kind="wfp" className="fs-14">
              <Text className="mr-4 fs-12">{item}</Text>
              <FontAwesomeIcon icon={solid("xmark")} cursor="pointer" onClick={() => removeItem(item)} />
            </Tag>
            // <div
            //   className={styles.selectedItem}
            //   onClick={() => removeItem(item)}
            //   key={`dynamic-suggestion-${item}-${i}`}
            // >
            //   <span>{item} </span>
            //   <FontAwesomeIcon className={styles.selectedItemIcon} icon={solid("close")} />
            // </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DynamicSuggestionInput;
