import React, { useEffect, useState } from "react";
import TabsPageView from "~/components/TabsPageView";
import meetingStore, { meetingLanguagesList, MeetingNode, TimeScheduleItem } from "~/stores/meeting";
import { MeetingsUploadTab } from "~/pages/Meetings/MeetingsUpload";
import { useParams } from "react-router";
import { observer } from "mobx-react";
import uuid from "react-uuid";

const MeetingsEdit = observer(() => {
  const { meetingId } = useParams();
  const [meetingForm, setMeetingForm] = useState<Partial<MeetingNode>>({});
  const [timeScheduleState, setTimeScheduleState] = useState<Partial<TimeScheduleItem>[]>([
    {
      key: uuid(),
      default: true,
    },
  ]);

  useEffect(() => {
    if (meetingId) {
      meetingStore.loadSingleMeeting(meetingId)?.then((meetingRes) => {
        setMeetingForm(meetingRes);
        if (meetingRes.time_schedule.length) {
          setTimeScheduleState(
            meetingRes.time_schedule.map((timeSchedule) => ({
              ...timeSchedule,
              key: uuid(),
            }))
          );
        }
      });
    }
  }, [meetingId]);

  return (
    <TabsPageView
      breadcrumbsItems={[{ label: "Meetings", href: "/meetings" }, { label: "Edit Meeting" }]}
      tabs={meetingLanguagesList.map((lang) => ({
        label: lang.title,
        id: lang.key,
        render: (selected) => (
          <MeetingsUploadTab
            lang={lang.key}
            meetingForm={meetingForm}
            setMeetingForm={setMeetingForm}
            timeScheduleState={timeScheduleState}
            setTimeScheduleState={setTimeScheduleState}
            selected={selected}
            uploadType="edit"
          />
        ),
      }))}
      pageTitle="Edit meeting"
      withTabContentBackground
      withTabContentPadding={false}
    />
  );
});

export default MeetingsEdit;
