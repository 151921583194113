import { DateRange } from "~/document";
import { Button, Link } from "@wfp/ui";
import styles from "./styles.module.scss";
import "react-dates/initialize";
import { DateRangePicker } from "react-dates";
import { DateRangePickerInput } from "@wfp/ui";
import moment, { Moment } from "moment";
import { SingleDatePickerInput } from "@wfp/ui";
import { SingleDatePicker as SingleDatePickerLib } from "react-dates";
import { iconChevronLeft, iconChevronRight } from "@wfp/icons";
import { useEffect, useRef } from "react";
import cn from "classnames";

type CommonProps = {
  pickerProps?: any;
  helperText: string;
  disabled?: boolean;
  onBlur?: () => any;
};

interface SingleProps extends CommonProps {
  value: Date | undefined;
  allowPast: boolean | undefined;
  onChange: (a: Date | undefined) => void;
  labelText?: string;
  minDate?: Moment;
  placeholder?: string;
  maxDate?: Moment;
}

interface RangedProps extends CommonProps {
  value: DateRange | undefined;
  onChange: (a: DateRange | undefined) => void;
  withDefaultValue?: boolean;
  allowPast?: boolean;
}

const getTodayDate = (): Date => {
  const d = new Date();
  return new Date(d.getFullYear(), d.getMonth(), d.getDate());
};

const getYesterdayDate = (): Date => {
  const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
  return yesterday;
};

const getTodayRange = (): DateRange => {
  return [getTodayDate(), getTodayDate()];
};

const getYesterdayRange = (): DateRange => {
  return [getYesterdayDate(), getYesterdayDate()];
};

const getLastWeekDateRange = (): DateRange => {
  const lastWeekDay = new Date(new Date().setDate(new Date().getDate() - 7));
  return [lastWeekDay, getTodayDate()];
};

const getLastMonthDateRange = (): DateRange => {
  const lastMonthDay = new Date(new Date().setMonth(new Date().getMonth() - 1));
  return [lastMonthDay, getTodayDate()];
};

const getLastYearDateRange = (): DateRange => {
  const beg = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  return [beg, getTodayDate()];
};

const getDateRangeUntilToday = (): DateRange => {
  return [null, getYesterdayDate()];
};

const renderMonthElement = ({ month, onMonthSelect, onYearSelect }) => {
  return (
    <div className="pub-date-caption">
      <Button
        onClick={() => onMonthSelect(month, month.month() - 1)}
        icon={iconChevronLeft}
        kind="inverse--primary"
        small
      />
      <div>{moment.months()[month.month()]}</div>
      <Button
        onClick={() => onMonthSelect(month, month.month() + 1)}
        icon={iconChevronRight}
        kind="inverse--primary"
        small
      />
      <Button
        onClick={() => onYearSelect(month, month.year() - 1)}
        icon={iconChevronLeft}
        kind="inverse--primary"
        small
      />
      <div>{month.year()}</div>
      <Button
        onClick={() => onYearSelect(month, month.year() + 1)}
        icon={iconChevronRight}
        kind="inverse--primary"
        small
      />
    </div>
  );
};

export const RangedDatePicker = (props: RangedProps) => {
  const { value, onChange, withDefaultValue = true, allowPast } = props;
  const startDateDefault = withDefaultValue ? moment(new Date(0)) : null;
  const endDateDefault = withDefaultValue ? moment(new Date()) : null;
  return (
    <DateRangePickerInput
      datePicker={(props: any[]) => {
        const A = DateRangePicker as any;
        if (allowPast) {
          return (
            <A
              isOutsideRange={() => !allowPast}
              renderMonthElement={renderMonthElement}
              displayFormat={() => "DD/MM/YYYY"}
              customArrowIcon={<></>}
              {...props}
              numberOfMonths={1}
              showDefaultInputIcon
            />
          );
        }
        return (
          <A
            renderMonthElement={renderMonthElement}
            customArrowIcon={<></>}
            {...props}
            numberOfMonths={1}
            displayFormat={() => "DD/MM/YYYY"}
            showDefaultInputIcon
          />
        );
      }}
      helperText={
        <div className="df fdr fac">
          <strong className="mr-8">Set to:</strong>
          <div>
            <Link kind="primary" onClick={() => onChange(getTodayRange())} className={styles.shortcutListItem}>
              Today
            </Link>
            <Link kind="primary" onClick={() => onChange(getYesterdayRange())} className={styles.shortcutListItem}>
              Yesterday
            </Link>
            <Link kind="primary" onClick={() => onChange(getLastWeekDateRange())} className={styles.shortcutListItem}>
              Last 7 days
            </Link>
            <Link kind="primary" onClick={() => onChange(getLastMonthDateRange())} className={styles.shortcutListItem}>
              Last 30 days
            </Link>
            <Link kind="primary" onClick={() => onChange(getLastYearDateRange())} className={styles.shortcutListItem}>
              This year
            </Link>
            <Link kind="primary" onClick={() => onChange(getDateRangeUntilToday())} className={styles.shortcutListItem}>
              Until today
            </Link>
          </div>
        </div>
      }
      // datePicker={DateRangePicker}
      startDatePlaceholderText="Date from"
      endDatePlaceholderText="Date to"
      value={{
        startDate: value && value[0] ? moment(value[0]) : startDateDefault,
        endDate: value && value[1] ? moment(value[1]) : endDateDefault,
      }}
      onChange={({ startDate, endDate }: any) => {
        onChange([startDate, endDate]);
      }}
    />
  );
};

export const SingleDatePicker = (props: SingleProps) => {
  const { value, onChange, placeholder, allowPast = false, labelText = "", minDate, maxDate, onBlur } = props;
  const momentValue = value ? moment(value) : null;

  const isOutsideRange = (date: Moment) => {
    let isOutside = false;
    if (!allowPast) {
      const isToday = date.format("MM DD YYYY") === moment().format("MM DD YYYY");
      if (date.isBefore(moment(new Date())) && !isToday) {
        isOutside = true;
      }
    }
    if (minDate) {
      const isMinDay = date.format("MM DD YYYY") === minDate.format("MM DD YYYY");
      if (date.isBefore(minDate) && !isMinDay) {
        isOutside = true;
      }
    }
    if (maxDate) {
      const isMaxDay = date.format("MM DD YYYY") === maxDate.format("MM DD YYYY");
      if (date.isAfter(maxDate) && !isMaxDay) {
        isOutside = true;
      }
    }
    return isOutside;
  };

  const ref = useRef<SingleDatePickerInput>(null);

  useEffect(() => {
    if (value === null && ref.current) {
      ref.current.setState((prevState) => ({ ...prevState, controlledValue: null }));
    }
  }, [value]);

  return (
    <SingleDatePickerInput
      datePicker={(props: any[]) => {
        const A = SingleDatePickerLib as any;
        if (allowPast) {
          return (
            <A
              isOutsideRange={isOutsideRange}
              displayFormat={() => "DD/MM/YYYY"}
              renderMonthElement={renderMonthElement}
              placeholder={placeholder}
              {...props}
              numberOfMonths={1}
            />
          );
        }
        return (
          <A
            renderMonthElement={renderMonthElement}
            displayFormat={() => "DD/MM/YYYY"}
            {...props}
            isOutsideRange={isOutsideRange}
            numberOfMonths={1}
          />
        );
      }}
      labelText={labelText}
      helperText={props.helperText}
      inputIconPosition="after"
      showClearDate
      invalidText="A valid value is required"
      onBlur={onBlur}
      value={momentValue}
      ref={ref}
      onChange={(value) => {
        onChange(value ? new Date(value) : undefined);
      }}
      onFocus={() => {}}
      disabled={props.disabled}
      showDefaultInputIcon
      {...(props.pickerProps || {})}
    />
  );
};
