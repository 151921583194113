import React from "react";
import { observer } from "mobx-react";
import { InputGroup, RadioButton } from "@wfp/ui";

type Props = {
  onChange: (value: 1 | 0) => void;
  value?: 1 | 0;
};

const TitleVisibleForPublicUsersSelect = observer((props: Props) => {
  return (
    <InputGroup name="input-group" vertical>
      <RadioButton
        id="title-visible"
        labelText="Visible"
        onChange={() => props.onChange(1)}
        value={props.value}
        checked={props.value === 1}
      />
      <RadioButton
        id="title-not-visible"
        labelText="Not visible"
        onChange={() => props.onChange(0)}
        value={props.value}
        checked={props.value === 0}
      />
    </InputGroup>
  );
});

export default TitleVisibleForPublicUsersSelect;
