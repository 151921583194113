import React, { useCallback, useEffect, useState } from "react";
import AgendaItemComponent from "~/components/AgendaItems/AgendaItem/AgendaItem";
import agendaItemStore, { AgendaItem, AgendaItemGroup } from "~/stores/agendaItems";
import { MeetingId, MeetingLanguage } from "~/stores/meeting";
import { observer } from "mobx-react";
import AgendaItemPlaceholder from "~/components/AgendaItems/AgendaItem/AgendaItemPlaceholder";
import update from "immutability-helper";

type Props = {
  lang: MeetingLanguage;
  meetingId: MeetingId;
  availableDocsIds: number[];
};

const AgendaItemsList = observer(({ availableDocsIds, meetingId, lang }: Props) => {
  const [agendaItems, setAgendaItems] = useState<AgendaItem[]>([]);

  const moveAgendaItem = useCallback((dragIndex: number, hoverIndex: number) => {
    setAgendaItems((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  const countAgendaItemPosition = useCallback(
    (index: number, agendaItem: AgendaItem, group: AgendaItemGroup) => {
      const groups: AgendaItemGroup[] = ["top", "numbered", "bottom"];
      const groupIndex = groups.indexOf(group);
      const groupsToFilter = groups.slice(0, groupIndex);
      const agendaItemsToRemoveNumber = agendaItems.filter(
        ({ group, id }) => groupsToFilter.includes(group) && agendaItem.id !== id
      );
      return index + 1 - agendaItemsToRemoveNumber.length;
    },
    [agendaItems]
  );

  useEffect(() => {
    const meetingAgendaItems = agendaItemStore.agendaItemsByMeeting?.[meetingId] || [];
    setAgendaItems(meetingAgendaItems);
  }, [agendaItemStore.agendaItemsByMeeting?.[meetingId]]);

  return (
    <div style={{ position: "relative" }}>
      {!agendaItems.some(({ group }) => group === "top") && (
        <AgendaItemPlaceholder group="top" moveAgendaItem={moveAgendaItem} />
      )}
      {agendaItems.map((agendaItem, i) => (
        <div key={`agenda-item-${agendaItem.id}`}>
          <AgendaItemComponent
            lang={lang}
            meetingId={meetingId}
            index={i}
            availableDocsIds={availableDocsIds}
            moveAgendaItem={moveAgendaItem}
            countAgendaItemPosition={countAgendaItemPosition}
            agendaItem={agendaItem}
          />
        </div>
      ))}
      {!agendaItems.some(({ group }) => group === "bottom") && (
        <AgendaItemPlaceholder group="bottom" moveAgendaItem={moveAgendaItem} />
      )}
    </div>
  );
});

export default AgendaItemsList;
