import docStore from "~/stores/document";
import { Document } from "~/document";
import { observer } from "mobx-react";
import Page from "~/components/Page/index";
import styles from "./styles.module.scss";
import paths from "~/paths";
import { useNavigate, Routes, Route } from "react-router-dom";
import LatestUploadedList from "./LatestUploadedList";
import SearchBar from "~/components/SearchBar/index";
import cn from "classnames";
import DocumentView from "~/components/DocumentView/index";
import SearchResultsList from "./SearchResultsList";
import DocumentsContext from "./DocumentsContext";
import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import { LayoutMode, useLayoutMode, useQueryURLSearchParams } from "~/util/hooks";
import SearchQuery from "~/searchQuery";
import { useSpring, animated, useTransition, config, Spring, Transition } from "react-spring";
import Skeleton from "~/components/Skeleton/index";
import searchQuery from "~/searchQuery";

const PreviewIcon = () => (
  <svg width="71" height="89" viewBox="0 0 71 89" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_60_13064)">
      <path
        d="M45.5434 47.3566L45.5322 47.3645L45.5434 47.3566ZM45.5434 47.3566L45.5722 47.397L45.5434 47.3566ZM21.8372 46.7823C24.1795 43.8533 27.2867 40.4266 33.2426 40.5792C39.1986 40.7317 42.1264 44.3129 44.3157 47.3578C41.9181 50.2993 38.8675 53.7274 32.9099 53.5749C26.9523 53.4223 24.0811 49.8426 21.8372 46.7823Z"
        stroke="#8C9BA5"
        stroke-width="2"
      />
      <circle
        cx="33.0762"
        cy="47.077"
        r="3.5"
        transform="rotate(1.46674 33.0762 47.077)"
        stroke="#8C9BA5"
        stroke-width="2"
      />
      <circle
        cx="33.0763"
        cy="47.077"
        r="15.5"
        transform="rotate(1.46674 33.0763 47.077)"
        stroke="#8C9BA5"
        stroke-width="2"
      />
      <rect
        x="42.4135"
        y="62.6299"
        width="5.48729"
        height="12.1043"
        rx="2"
        transform="rotate(-43.5333 42.4135 62.6299)"
        stroke="#8C9BA5"
        stroke-width="2"
      />
      <mask id="path-5-inside-1_60_13064" fill="white">
        <path d="M10.6969 9.31148C11.2401 4.92673 15.2351 1.8126 19.6198 2.35587L50.3846 6.16763C53.858 6.59799 53.3618 6.53651 60.133 16.9481C66.9042 27.3597 66.9657 26.8635 66.6583 29.3445L60.6332 77.9727C60.0899 82.3574 56.095 85.4716 51.7102 84.9283L10.029 79.764C5.64421 79.2207 2.53007 75.2258 3.07335 70.841L10.6969 9.31148Z" />
      </mask>
      <path
        d="M19.3739 4.3407L50.1387 8.15245L50.6305 4.18281L19.8658 0.371049L19.3739 4.3407ZM64.6734 29.0986L58.6484 77.7268L62.618 78.2186L68.6431 29.5904L64.6734 29.0986ZM51.9562 82.9435L10.2749 77.7792L9.78304 81.7488L51.4643 86.9131L51.9562 82.9435ZM5.05817 71.0869L12.6817 9.5574L8.71204 9.06556L1.08852 70.5951L5.05817 71.0869ZM10.2749 77.7792C6.98632 77.3717 4.65072 74.3755 5.05817 71.0869L1.08852 70.5951C0.409432 76.076 4.3021 81.0697 9.78304 81.7488L10.2749 77.7792ZM58.6484 77.7268C58.2409 81.0153 55.2447 83.3509 51.9562 82.9435L51.4643 86.9131C56.9453 87.5922 61.939 83.6995 62.618 78.2186L58.6484 77.7268ZM19.8658 0.371049C14.3848 -0.30804 9.39113 3.58463 8.71204 9.06556L12.6817 9.5574C13.0891 6.26884 16.0854 3.93324 19.3739 4.3407L19.8658 0.371049ZM50.1387 8.15245C51.0855 8.26977 51.4228 8.32508 51.683 8.42839C51.8392 8.49041 52.0643 8.60751 52.5081 9.12173C52.9882 9.67791 53.6029 10.5537 54.5688 12.0279C55.5346 13.5019 56.74 15.3993 58.4564 18.0385L61.8096 15.8577C60.1405 13.2911 58.8687 11.2918 57.9146 9.83568C56.9606 8.37969 56.2126 7.29177 55.5362 6.50808C54.8235 5.68242 54.0981 5.08348 53.1589 4.71062C52.3237 4.37906 51.4204 4.28067 50.6305 4.18281L50.1387 8.15245ZM58.4564 18.0385C61.9023 23.337 63.5227 25.684 64.3003 27.0888C64.6655 27.7486 64.7246 27.9987 64.7418 28.1158C64.7636 28.2641 64.7576 28.4189 64.6734 29.0986L68.6431 29.5904C68.7126 29.0296 68.8142 28.316 68.6993 27.5343C68.5799 26.7213 68.2581 25.9794 67.7999 25.1517C66.9308 23.5815 65.1349 20.9708 61.8096 15.8577L58.4564 18.0385Z"
        fill="#8C9BA5"
        mask="url(#path-5-inside-1_60_13064)"
      />
      <path
        d="M49.7039 7.59485L47.6135 24.4658C47.4777 25.562 48.2563 26.5608 49.3524 26.6966L65.231 28.6639"
        stroke="#8C9BA5"
        stroke-width="2"
      />
    </g>
    <defs>
      <clipPath id="clip0_60_13064">
        <rect width="61" height="82" fill="white" transform="translate(10.2949) rotate(7.09155)" />
      </clipPath>
    </defs>
  </svg>
);

const SelectPreviewHelper = () => {
  return (
    <div className={styles.selectPreviewHelperWrapper}>
      <div className={cn([styles.col, styles.selectPreviewHelperWrapperLeft])}>
        <Skeleton isStatic kind="line" count={3} />
        <div>
          <Skeleton isStatic kind="rectangle" count={2} />
        </div>
        <Skeleton isStatic kind="line" count={18} />
      </div>
      <div className={cn([styles.col, styles.selectPreviewHelperWrapperRight])}>
        <Skeleton isStatic kind="picture" count={1} />
        <Skeleton isStatic kind="line" count={4} align="right" />
        <div className="fspace" />
        <Skeleton isStatic kind="rectangle" count={1} align="right" />
      </div>
      <div className={styles.selectPreviewHelper}>
        <PreviewIcon />
        <div className="mt-8" style={{ width: 200 }}>
          Click on a document to see the preview
        </div>
      </div>
    </div>
  );
};

const Documents = observer(() => {
  const navigate = useNavigate();
  const mode = useLayoutMode();

  // Current selected document for preview
  const [currentDocument, setCurrentDocument] = useState<Document | null>(null);

  const onDocumentClick = (doc: Document) => {
    if (mode <= LayoutMode.MD) {
      // if on mobile, just open the detail page
      navigate(paths.construct.docDetail(doc.protocol));
    } else {
      // on larger screens, open a preview
      (async () => {
        await docStore.selectDocument(doc.protocol);
        setCurrentDocument(docStore.currentDocument);
      })();
    }
  };

  const pageQuery = useQueryURLSearchParams();

  // The current active query (null if no query)
  const [prevQuery, setPrevQuery] = useState<SearchQuery | null>(null);

  // Editable query. Updated when input is changed
  const [searchQuery, setSearchQuery] = useState<SearchQuery>(new SearchQuery());

  // Update query and search when the URL search parameter changes
  useEffect(() => {
    const newQuery = SearchQuery.fromQueryString(pageQuery);
    const didQueryChange = !isEqual(prevQuery, newQuery);
    const queryIsValid = newQuery.isValid();
    // if this is the first query, set the "initial query" to be the new one
    if (prevQuery === null) {
      setPrevQuery(newQuery);
    } else {
      if (queryIsValid) {
        setPrevQuery(searchQuery);
      } else {
        setPrevQuery(null);
      }
    }
    newQuery.pageSize = 25;
    setSearchQuery(newQuery);
    if (didQueryChange && queryIsValid) {
      docStore.search(newQuery);
    }
  }, [pageQuery.toString()]);

  const onSearch = (sq: SearchQuery) => {
    setCurrentDocument(null);
    navigate(paths.construct.browse(sq));
  };

  const onClear = () => {
    setCurrentDocument(null);
    navigate(paths.construct.documents());
  };

  const loadingDocumentView = docStore.loadingDocumentView;

  const props = useSpring({ to: { opacity: 1 }, from: { opacity: 0 } });

  const showDocumentView = currentDocument !== null || loadingDocumentView;
  const isOpen = showDocumentView;
  const { opacity } = useSpring({
    from: { height: 0, opacity: 0, y: 0 },
    to: {
      opacity: isOpen ? 1 : 0,
      y: isOpen ? 0 : 20,
    },
  });

  const isSearch = !!pageQuery.toString();

  return (
    <DocumentsContext.Provider
      value={{
        onDocumentClick,
        searchQuery,
        setSearchQuery,
        onSearch,
      }}
    >
      <Page title="Documents" className={styles.page}>
        <div className={styles.searchWrapper}>
          <SearchBar
            searchQuery={searchQuery}
            initialQuery={prevQuery}
            className={styles.search}
            onSearch={onSearch}
            onClear={onClear}
            saveSearchEnabled={!searchQuery.isEmpty()}
          />
        </div>
        <div className={cn(["f-100 df w-100", styles.content])}>
          <div className={styles.leftCol}>{isSearch ? <SearchResultsList /> : <LatestUploadedList />}</div>
          <div className={styles.documentViewColumn}>
            <Transition
              items={showDocumentView}
              from={{ opacity: 0 }}
              enter={{
                opacity: 1,
                boxShadow: "0px 0px 2px 0px #00000017, 0px 0px 8px 0px #00000012",
              }}
              leave={{ opacity: 0 }}
              config={config.stiff}
              delay={50}
            >
              {(props, show) =>
                show ? (
                  <animated.div style={{ ...props }} className={styles.documentViewWrapper}>
                    {docStore.isExpired(currentDocument) && <div className="document-expired-banner">Expired</div>}
                    <DocumentView
                      translations={docStore.currentDocumentTranslations}
                      document={currentDocument}
                      loadingDocument={loadingDocumentView}
                      onClose={() => setCurrentDocument(null)}
                    />
                  </animated.div>
                ) : (
                  <animated.div style={{ ...props }} className={cn([styles.documentViewWrapper])}>
                    <SelectPreviewHelper />
                  </animated.div>
                )
              }
            </Transition>
          </div>
        </div>
      </Page>
    </DocumentsContext.Provider>
  );
});

export default Documents;
