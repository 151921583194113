import React, { useCallback, useEffect, useMemo } from "react";
import tagStore, { TagId, TagInfo } from "~/stores/tags";
import { observer } from "mobx-react";
import AsyncPaginatedSelect from "~/components/AsyncPaginatedSelect";

interface Props {
  value: TagId[];
  onChange: (newValue: string[]) => void;
  isCreatable?: boolean;
  tagValueKey?: "id" | "label";
}

const TagSelect = observer(({ value, tagValueKey = "label", isCreatable, onChange }: Props) => {
  const fetcher = tagStore.f;
  const idToOpt = useCallback(
    (id: string | TagId) => {
      const item = fetcher.itemsList.find((tag) => tag.label === id || tag.id === id);
      if (!item) return null;
      return {
        label: item.label,
        value: item[tagValueKey],
      };
    },
    [fetcher.itemsList]
  );

  const onCreate = async (option: string) => {
    const res = await tagStore.createNewTag(option);
    if (res?.data) {
      onChange([...value, res.data[tagValueKey]]);
    }
    await tagStore.f.load("");
    return res.data;
  };

  useEffect(() => {
    tagStore.f.load("");
  }, []);
  return (
    <AsyncPaginatedSelect
      isCreatable={isCreatable ?? false}
      fetcher={tagStore.f}
      itemIdToOption={idToOpt}
      value={value}
      onCreate={onCreate}
      onChange={onChange}
      selectProps={{
        isMulti: true,
      }}
    />
  );
});

export default TagSelect;
