import { runInAction, makeAutoObservable } from "mobx";
import axios from "axios";
import * as api from "~/api";
import notifStore, { NotificationStore } from "./notifications";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";

export type Country = string;
export type CountryId = string;
export interface CountryInfo {
  id: CountryId;
  name: string;
  uuid?: string;
}

export class CountryStore {
  notifStore: NotificationStore;
  f: PaginatedEndpoint<CountryInfo>;

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.notifStore = notifStore;
    this.f = new PaginatedEndpoint<CountryInfo>("countries", notifStore);
  }

  idToText = (id: CountryId): string | null => {
    if (id in this.f.items) return this.f.items[id].name;
    return null;
  };
}

const countryStore = new CountryStore(notifStore);

export default countryStore;
