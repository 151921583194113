import { Loading, Modal, Text } from "@wfp/ui";
import { DocPayload } from "~/document";
import DocumentSummary from "~/components/DocumentSummary";
import styles from "./styles.module.scss";
import React from "react";

interface ConfirmationModalProps {
  docPayload: DocPayload;
  filename?: string;
  onAgree: () => void;
  close: () => void;
  open: boolean;
  submitting: boolean;
  editing: boolean;
}

const ConfirmationModal = (props: ConfirmationModalProps) => {
  return (
    <Modal
      open={props.open}
      modalHeading={props.editing ? "Confirm edit" : "Confirm upload"}
      modalLabel={props.editing ? "Edit document" : "Upload"}
      onRequestClose={props.close}
      onRequestSubmit={props.onAgree}
      primaryButtonText={props.submitting ? "Submitting..." : "Confirm"}
      primaryButtonDisabled={props.submitting}
    >
      {props.submitting ? (
        <div className="df fac">
          <Loading className="loader loader_sm" withOverlay={false}></Loading>
          <Text className="fs-14 no-margin" kind="p">
            Uploading document...
          </Text>
        </div>
      ) : (
        <div className={styles.confirmationModal}>
          <DocumentSummary doc={props.docPayload} filename={props.filename} />
        </div>
      )}
    </Modal>
  );
};

export default ConfirmationModal;
