import { makeAutoObservable, runInAction } from "mobx";
import * as api from "~/api";
import { Document, FileInfo, LangId, DocumentId, DocumentProto, DocumentSearch } from "~/document";
import notifStore, { NotificationStore } from "./notifications";
import { ReqStatus } from "~/util";

export type { DocumentId };

const enum ConversionStatus {
  CONVERSION_NONE = 0,
  CONVERSION_SCHEDULED = 100,
  CONVERSION_PROGRESS = 101,
  CONVERSION_DONE = 200,
  CONVERSION_NOTNEEDED = 201,
  CONVERSION_FAILED = 501,
}

const conversionStatusToString: Record<ConversionStatus, string> = {
  [ConversionStatus.CONVERSION_NONE]: "None",
  [ConversionStatus.CONVERSION_SCHEDULED]: "Scheduled",
  [ConversionStatus.CONVERSION_PROGRESS]: "In progress",
  [ConversionStatus.CONVERSION_DONE]: "Conversion done",
  [ConversionStatus.CONVERSION_NOTNEEDED]: "Conversion not needed",
  [ConversionStatus.CONVERSION_FAILED]: "Conversion failed",
};

export interface DocLangVersion {
  abstract: string;
  file_info: FileInfo;
  language: string;
  protocol: string;
  title: string;
  uuid: string;
}

export class DocumentLangVersionStore {
  loaded: Map<DocumentProto, DocLangVersion[]> = new Map();
  status: ReqStatus = ReqStatus.Initial;
  notifStore: NotificationStore;

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.notifStore = notifStore;
  }

  async versionsFor(docID: string): Promise<DocLangVersion[]> {
    if (this.loaded.get(docID) !== undefined) {
      return this.loaded.get(docID)!;
    } else {
      this.status = ReqStatus.InProcess;
      try {
        const res: { data: DocLangVersion[] } | null = await api.get(`documents/${docID}/translations`);
        if (res === null) return [];
        this.status = ReqStatus.Success;
        runInAction(() => {
          this.loaded.set(docID, res.data);
        });
        return res.data;
      } catch (err) {
        this.status = ReqStatus.Failed;
        return [];
      }
    }
  }

  appendForDoc(proto: DocumentProto, v: DocLangVersion) {
    let arr = this.loaded.get(proto);
    if (!arr) {
      this.loaded.set(proto, []);
      arr = this.loaded.get(proto);
    }
    arr!.push(v);
  }

  removeForDoc(proto: DocumentProto, langVersionProto: DocumentProto) {
    let arr = this.loaded.get(proto) || [];
    this.loaded.set(
      proto,
      arr.filter((langVersion) => langVersion.protocol !== langVersionProto)
    );
  }

  async addFor(
    proto: DocumentProto,
    file: File,
    payload: { language: LangId; title: string }
  ): Promise<DocLangVersion> {
    this.status = ReqStatus.InProcess;
    try {
      const formData = new FormData();
      if (file) {
        formData.append("file", file);
      }
      formData.append("language", payload.language);
      formData.append("title", payload.title);
      const res: { data: DocLangVersion } | null = await api.postMultipart(
        `ebms:docitems/${proto}/translations`,
        formData
      );
      if (res === null) return null;
      this.status = ReqStatus.Success;
      this.appendForDoc(proto, res.data);
      return res.data;
    } catch (err: any) {
      this.notifStore.error("Failed to add document language version");
      this.status = ReqStatus.Failed;
      return null;
    }
  }

  async deleteLangVersion(proto: DocumentProto, langVersionProto: DocumentProto) {
    try {
      const res = await api.del(`ebms:docitems/${langVersionProto}/translations`);
      if (res === null) return null;
      runInAction(() => {
        this.removeForDoc(proto, langVersionProto);
      });
      return true;
    } catch (err) {
      this.notifStore.error(`Failed to remove document language version: ${err}`);
      return null;
    }
  }

  async linkLangVersion(proto: DocumentProto, doc: DocumentSearch): Promise<DocLangVersion> {
    this.status = ReqStatus.InProcess;
    try {
      const res: { data: DocLangVersion } | null = await api.patch(`ebms:docitems/${proto}/translations`, {
        uuid: doc.uuid,
      });
      if (res === null) return null;
      this.status = ReqStatus.Success;
      this.appendForDoc(proto, res.data);
      return res.data;
    } catch (err: any) {
      this.notifStore.error(`Failed to link document language version: ${err}`);
      this.status = ReqStatus.Failed;
      return null;
    }
  }
}

const docLangVersionStore = new DocumentLangVersionStore(notifStore);

export default docLangVersionStore;
