import { makeAutoObservable } from "mobx";
import * as api from "~/api";
import { TranslationInfo, ID, Document, DocumentProto } from "~/document";
import docStore, { DocumentStore } from "~/stores/document";
import notifStore, { NotificationStore } from "~/stores/notifications";

class BulkDownloadStore {
  selectedDocuments: Set<DocumentProto> = new Set();
  docStore: DocumentStore;
  notifStore: NotificationStore;
  status: api.ReqStatus = api.ReqStatus.Initial;

  constructor(documentStore: DocumentStore, notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.docStore = documentStore;
    this.notifStore = notifStore;
  }

  get selectedSomething() {
    return this.selectedDocuments.size !== 0;
  }

  get selectedDocumentsList(): Document[] {
    const res: Document[] = [];
    this.selectedDocuments.forEach((key) => {
      const doc = this.docStore.loadedDocuments[key];
      if (doc === undefined) return;
      res.push(doc);
    });
    return res;
  }

  setBulkDownloadSelected(itemProto: DocumentProto, value: boolean) {
    if (value) {
      this.selectedDocuments.add(itemProto);
    } else {
      this.selectedDocuments.delete(itemProto);
    }
  }

  isBulkDownloadSelected(itemProto: DocumentProto) {
    return this.selectedDocuments.has(itemProto);
  }

  download = async () => {
    const documents = this.selectedDocumentsList.map((doc) => doc.dbid);
    this.status = api.ReqStatus.InProcess;
    try {
      const resp = await api.post(`documents/download_zip`, { documents }, { responseType: "blob" });
      if (resp === null) return;
      const url = window.URL.createObjectURL(new Blob([resp!.data], { type: resp.headers["content-type"] }));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "documents.zip");
      document.body.appendChild(link);
      link.click();
      this.status = api.ReqStatus.Success;
      // Free the memory
      URL.revokeObjectURL(url);
    } catch (error: any) {
      this.status = api.ReqStatus.Failed;
      if (!error.response) throw error;
      else if (error!.response!.status === 404) {
        this.notifStore.error("Failed to download ZIP: Not found");
      }
    }
  };

  clearSelection = () => {
    this.selectedDocuments = new Set();
  };
}

const bulkDownloadStore = new BulkDownloadStore(docStore, notifStore);

export default bulkDownloadStore;
