import docStore from "~/stores/document";
import { Button } from "@wfp/ui";
import { observer } from "mobx-react";
import paths from "~/paths";
import { iconArrowLeft } from "@wfp/icons";
import { useNavigate } from "react-router-dom";

const BackToSearchResultsButton = observer(() => {
  const navigate = useNavigate();
  if (docStore.prevQuery === null) return null;
  return (
    <Button kind="secondary" onClick={() => navigate(paths.construct.documents())} icon={iconArrowLeft} iconReverse>
      Back to search results
    </Button>
  );
});

export default BackToSearchResultsButton;
