// This module contains searchConverter, a function that, given a config,
// will provide you with a `to` and `from` handlers that can be used to
// convert some object into a URL search query and parse a given search
// query into an object of that kind.
//
// Currently only used in this project for communicating SearchQuery
// between pages using an URL.
//
// You can probably replace this with some kind of a library.

import { searchQueryCustomKeys } from "~/searchQuery";

export type CustomFormatter<V> = {
  to: (val: any) => string | null;
  from: (val: string) => V;
};

type ConfiguredKey<T, P extends keyof T> = SearchConverter | CustomFormatter<T[P]>;

// Check if converter configuration part is custom
function isCustom<T, P extends keyof T>(val: ConfiguredKey<T, P>): val is CustomFormatter<T[P]> {
  return val instanceof Object && "to" in val && "from" in val;
}

type ConverterConfiguration<T> = Partial<{
  [P in keyof T]: ConfiguredKey<T, P>;
}>;

const SPLIT_PART = ",";

export enum SearchConverter {
  String = 0,
  Array = 1,
  Number = 2,
  Set = 3,
  NumberSet = 4,
  NumberToBoolean = 5,
}

export const searchConverter = <T>(config: ConverterConfiguration<T>, newObj: () => T) => {
  return {
    to: (obj: T): URLSearchParams => {
      let searchParams = new URLSearchParams();
      for (const cName of Object.keys(config) as (keyof typeof config)[]) {
        const cType = config[cName]!;
        if (!obj[cName]) {
          if (!(cType === SearchConverter.NumberToBoolean && !Number.isNaN(+obj[cName]))) {
            continue;
          }
        }
        let val: string | null = null;

        if (isCustom(cType)) {
          val = cType.to(obj[cName]);
        } else {
          switch (cType) {
            case SearchConverter.String:
              {
                val = obj[cName] as any as string;
              }
              break;
            case SearchConverter.Number:
              {
                val = String(obj[cName]);
              }
              break;

            case SearchConverter.NumberToBoolean:
              {
                val = (!!obj[cName]).toString();
              }
              break;
            case SearchConverter.NumberSet:
            case SearchConverter.Array:
            case SearchConverter.Set:
              {
                // TODO: Do some compile-time magic to make sure that this is an array type
                let arr = obj[cName] as any;
                if (arr instanceof Set) {
                  arr = Array.from(arr);
                }
                if (arr.length === 0) continue;
                val = arr.join(SPLIT_PART);
              }
              break;
          }
        }
        if (val !== null) {
          searchParams.set(cName as any, val);
        }
      }
      return searchParams;
    },

    from: (query: URLSearchParams): T => {
      const qq = newObj();

      for (const cName of Object.keys(config) as (keyof typeof config)[]) {
        const cType = config[cName]!;

        const queryValue = query.get(cName as any) ?? null;
        if (queryValue === null) continue;

        let val: any = null;

        if (isCustom(cType)) {
          val = cType.from(queryValue as any);
        } else {
          switch (cType) {
            case SearchConverter.String:
              {
                val = queryValue;
              }
              break;
            case SearchConverter.Number:
              {
                val = Number(queryValue);
              }
              break;
            case SearchConverter.NumberToBoolean:
              {
                val = queryValue === "true" ? 1 : 0;
              }
              break;
            case SearchConverter.Array:
              {
                // TODO: Do some compile-time magic to make sure that this is an array type
                val = (queryValue as any).split(SPLIT_PART);
              }
              break;
            case SearchConverter.Set:
              {
                val = new Set((queryValue as any).split(SPLIT_PART));
              }
              break;
            case SearchConverter.NumberSet:
              {
                val = new Set((queryValue as any).split(SPLIT_PART).map((val) => +val));
              }
              break;
          }
        }
        if (val !== null) {
          if (searchQueryCustomKeys.includes(cName.toString())) {
            (qq as any).custom[cName] = val;
          } else {
            (qq as any)[cName] = val;
          }
        }
      }

      return qq;
    },
  };
};
