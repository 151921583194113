import React, { createContext, ReactElement, useEffect, useRef, useState } from "react";
import { useDragDropManager } from "react-dnd";

export const AgendaItemsDNDContext = createContext<{
  isDNDActive: boolean;
  setDNDActive: (val: boolean) => void;
  shouldCollapseAllDocs: boolean;
  setShouldCollapseAllDocs: (val: boolean) => void;
  toggleCollapseAllDocs: () => void;
}>({
  isDNDActive: false,
  setDNDActive: () => {},
  shouldCollapseAllDocs: false,
  setShouldCollapseAllDocs: () => {},
  toggleCollapseAllDocs: () => {},
});

const AgendaItemsDNDWrapper = ({ children }: { children: ReactElement }) => {
  const [isDNDActive, setDNDActive] = useState(false);
  const [shouldCollapseAllDocs, setShouldCollapseAllDocs] = useState(false);
  const toggleCollapseAllDocs = () => {
    setShouldCollapseAllDocs((prev) => !prev);
  };
  return (
    <AgendaItemsDNDContext.Provider
      value={{ isDNDActive, setDNDActive, shouldCollapseAllDocs, setShouldCollapseAllDocs, toggleCollapseAllDocs }}
    >
      {children}
    </AgendaItemsDNDContext.Provider>
  );
};

export default AgendaItemsDNDWrapper;
