import React, { useContext } from "react";
import docStore from "~/stores/document";
import { Loading, Pagination, Text } from "@wfp/ui";
import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import DocItem from "~/components/DocItem";
import DocumentsContext from "./DocumentsContext";
import { useDataFetcher } from "~/pages/PersonalArea/common";
import { docItemToDocument } from "~/document";

const LatestUploadedList = observer(() => {
  const { onDocumentClick } = useContext(DocumentsContext);
  const fetcher = useDataFetcher(true, () => docStore.loadLatestDocuments(null, null, "-creation_date"), {
    pageSizeKey: "page_items_count",
    currentPageKey: "page_num",
    pagesCountKey: "pages_count",
    nextKey: "next_page",
    prevKey: "previous_page",
    itemsCountKey: "total_items_count",
  });
  if (fetcher.loading) {
    return (
      <div className="df h-100 w-100 fc f-100 fdc">
        <Loading className="loader loader_sm" withOverlay={false}>
          Text
        </Loading>
      </div>
    );
  }

  return (
    <div className={styles.leftColContent}>
      <Text kind="h4" className="mb-24">
        Latest uploads
      </Text>
      {!!fetcher.items?.results &&
        fetcher.items.results.map((item) => {
          const doc = docItemToDocument(item);
          return <DocItem key={doc.id} doc={doc} onDocumentClick={() => onDocumentClick(doc)} />;
        })}
      <Pagination
        onChange={({ page }) => fetcher.onPageChange(page)}
        pageSizes={[20]}
        totalItems={fetcher.totalItems}
        page={fetcher.page}
        pageSize={20}
      />
    </div>
  );
});

export default LatestUploadedList;
