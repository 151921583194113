import React, { useCallback, useState } from "react";
import styles from "~/pages/Meetings/styles.module.scss";
import { Blockquote, Button, InlineLoading, Table, TextInput } from "@wfp/ui";
import linkStore, { LinkId, LinkNode } from "~/stores/links";
import { MeetingId, MeetingLanguage, MeetingNode } from "~/stores/meeting";
import { useLocalizedFieldsGetter } from "~/util/hooks";
import { observer } from "mobx-react";

type Props = {
  disabled: boolean;
  links: Partial<LinkNode>[];
  setMeetingForm: React.Dispatch<React.SetStateAction<Partial<MeetingNode>>>;
  meetingId: MeetingId;
  lang: MeetingLanguage;
  withTag: boolean;
};

const MeetingQuickLinks = observer(({ disabled, links, setMeetingForm, meetingId, lang, withTag }: Props) => {
  const [draftLink, setDraftLink] = useState<Partial<LinkNode>>({});
  const [editingLink, setEditingLink] = useState<LinkNode>(null);
  const getLocalizedField = useLocalizedFieldsGetter(lang);

  const addMeetingQuickLink = () => {
    setMeetingForm((prev) => ({
      ...prev,
      links: [...(prev.links || []), {}],
    }));
  };

  const removeLinkLocally = useCallback(
    (index: number) => {
      const linksCopy = links || [];
      linksCopy.splice(index, 1);
      setMeetingForm((prev) => ({
        ...prev,
        links: linksCopy,
      }));
    },
    [links]
  );

  const removeLink = useCallback(
    (index: number, id?: number) => {
      if (!id) {
        removeLinkLocally(index);
      } else {
        linkStore.deleteLink(id).then((success) => (success ? removeLinkLocally(index) : null));
      }
    },
    [removeLinkLocally]
  );

  const saveDraftLink = useCallback(() => {
    linkStore.createLink({ ...draftLink, meeting: meetingId } as LinkNode).then((linkResp) => {
      if (linkResp) {
        setMeetingForm((prev) => ({ ...prev, links: [...prev.links, linkResp] }));
        setDraftLink({
          description_en: "",
          url_en: "",
        });
      }
    });
  }, [meetingId, draftLink]);

  const saveLinkChanges = useCallback(
    (id: LinkId) => {
      linkStore.updateLink(editingLink, id).then((linkResp) => {
        if (linkResp) {
          setMeetingForm((prev) => ({ ...prev, links: links.map((link) => (link.id === id ? linkResp : link)) }));
          setEditingLink(null);
        }
      });
    },
    [editingLink]
  );

  const onLinkEdit = useCallback((link: Partial<LinkNode>) => {
    if (link?.id && link?.description_en && link?.url_en) {
      setEditingLink(link as LinkNode);
    }
  }, []);

  const onDraftLinkChange = (key: keyof LinkNode) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setDraftLink((prev) => ({
      ...prev,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      [key]: e.target.value,
    }));
  };

  const onLinkChange = (linkIndex, key) => (e: React.ChangeEvent<HTMLInputElement>) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setEditingLink((prev) => ({ ...prev, [key]: e.target.value }));
  };

  return (
    <>
      {disabled ? (
        <div className="disabled-btn-container" style={{ alignItems: "center", gap: 0 }}>
          <Button disabled kind="secondary" onClick={addMeetingQuickLink}>
            Add Meeting quick links
          </Button>
          {withTag && <Blockquote>Please save this meeting before adding links</Blockquote>}
        </div>
      ) : (
        <div className="section-title">Quick Links</div>
      )}
      {!disabled && links?.length < 3 && (
        <div className={styles.meetingRow}>
          <div style={{ flexGrow: 1 }}>
            <TextInput
              labelText="Description*"
              value={draftLink[getLocalizedField("description")]}
              onChange={onDraftLinkChange(getLocalizedField("description") as keyof LinkNode)}
            />
            <TextInput
              labelText="Url*"
              value={draftLink[getLocalizedField("url")]}
              onChange={onDraftLinkChange(getLocalizedField("url") as keyof LinkNode)}
            />
            <Button kind="secondary" disabled={linkStore.loading} onClick={saveDraftLink} style={{ width: 80 }}>
              Save
            </Button>
          </div>
        </div>
      )}
      {!!linkStore.loading && <InlineLoading size={40} color="#0087cb" />}
      {!disabled && !!links?.length && (
        <Table className={styles.meetingLinksTable}>
          <thead>
            <th style={{ width: "40%" }}>Description</th>
            <th style={{ width: "40%" }}>Url</th>
            <th style={{ width: "20%" }} />
          </thead>
          <tbody>
            {links?.map((link, i) => {
              const urlField = getLocalizedField("url");
              const descField = getLocalizedField("description");
              const isEditing = editingLink?.id === link.id;
              return (
                <tr>
                  <td>
                    {isEditing ? (
                      <TextInput labelText="" value={editingLink[descField]} onChange={onLinkChange(i, descField)} />
                    ) : (
                      link[descField]
                    )}
                  </td>
                  <td>
                    {isEditing ? (
                      <TextInput labelText="" value={editingLink[urlField]} onChange={onLinkChange(i, urlField)} />
                    ) : (
                      <a href={link[urlField]}>{link[urlField]}</a>
                    )}
                  </td>
                  <td align="center">
                    {isEditing ? (
                      <Button
                        disabled={linkStore.loading}
                        kind="secondary"
                        small
                        onClick={() => saveLinkChanges(link.id)}
                      >
                        Save
                      </Button>
                    ) : (
                      <Button disabled={linkStore.loading} kind="secondary" small onClick={() => onLinkEdit(link)}>
                        Edit
                      </Button>
                    )}
                    <Button
                      disabled={linkStore.loading}
                      kind="secondary"
                      small
                      style={{ marginLeft: 10 }}
                      onClick={() => removeLink(i, link.id)}
                    >
                      Delete
                    </Button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </>
  );
});

export default MeetingQuickLinks;
