import React from "react";
import Page from "~/components/Page";
import { iconWfpHumEmergencyResponsePos } from "@wfp/icons";
import { Wrapper, Story, Icon, Link } from "@wfp/ui";

const PageNotFound = () => {
  return (
    <Page title="404. Not found">
      <Wrapper pageWidth="lg" spacing="md">
        <Story className="wfp--story__center wfp--story__full-height">
          <Icon
            icon={iconWfpHumEmergencyResponsePos}
            fill="#007DBC"
            width={200}
            height={200}
            style={{ marginBottom: "3rem", transform: "rotate(10deg)" }}
          />
          <h1 className="wfp--story__title">404. Page not found</h1>
          <p style={{ maxWidth: 500 }}>
            Uh oh, we can't seem to find the page you're looking for. Try going back to the previous page or contact{" "}
            <Link href="mailto:IT.ServiceDesk@wfp.org">IT.ServiceDesk@wfp.org</Link> if you think there is a problem.
          </p>
        </Story>
      </Wrapper>
    </Page>
  );
};

export default PageNotFound;
