import { runInAction, makeAutoObservable } from "mobx";
import axios from "axios";
import * as api from "~/api";
import notifStore, { NotificationStore } from "./notifications";
import { DocTypeId } from "~/document";
import { ReqStatus, treeItemsById } from "~/util";
import { QueryParams } from "~/api";
export type { DocTypeId };

export interface DocTypeNode {
  id: DocTypeId;
  label: string;
  members?: DocTypeNode[];
}

export class DocTypeStore {
  loadedTypes: DocTypeNode[];
  docTypesById: Map<DocTypeId, DocTypeNode>;
  notifStore: NotificationStore;
  status: ReqStatus = ReqStatus.Initial;

  get loading(): boolean {
    return this.status === ReqStatus.InProcess;
  }

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.loadedTypes = [];
    this.docTypesById = new Map();
    this.notifStore = notifStore;
  }

  loadDocTypes = async (ids?: DocTypeNode["id"][]): Promise<DocTypeNode[]> => {
    if (this.status === ReqStatus.Success) return this.loadedTypes;
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const params: QueryParams = { serializer: "tree", level: 0 };
      if (ids) params["id__inlist"] = ids;
      const resp = await api.get("structure/document-types", params);
      const items = resp!.data.results;
      runInAction(() => {
        this.loadedTypes = items;
        this.status = ReqStatus.Success;
        this.docTypesById = treeItemsById<"members", DocTypeNode, "id">(this.loadedTypes, "members", false, "id");
      });
      return items;
    } catch (err) {
      this.notifStore.error("Failed to load document types");
      this.status = ReqStatus.Failed;
      return [];
    }
  };
}

const docTypeStore = new DocTypeStore(notifStore);

export default docTypeStore;
