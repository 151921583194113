import notificationStore, { NotificationStore } from "~/stores/notifications";
import { ReqStatus } from "~/util";
import { makeAutoObservable, runInAction } from "mobx";
import * as api from "~/api";
import { AgendaItemId, AgendaItemUnboundDocument } from "~/stores/agendaItems";
import { MeetingId } from "~/stores/meeting";

export type AgendaItemDocumentTranslation = {
  display: string;
  has_file: boolean;
  initial: string;
  protocol: string;
  uuid: string;
};

export type AgendaItemDocument = {
  id: number;
  docitem_id: string;
  api_url: string;
  docitem_api_url: string;
  agendaitem?: number;
  position?: number;
  featured: boolean;
  title: string;
  protocol: string;
  symbol: string;
  supplementary_information: {
    [key: string]: {
      [key: string]: {
        initial: string;
        display: string;
      };
    };
  };
  document_translations: AgendaItemDocumentTranslation[];
};

export class AgendaItemDocsStore {
  notifStore: NotificationStore;
  status: ReqStatus = ReqStatus.Initial;
  statusById: { [key: number]: ReqStatus };
  agendaStatusById: { [key: number]: ReqStatus };
  documentsByAgendaItem: { [key: AgendaItemId]: AgendaItemDocument[] };
  documentsByMeeting: { [key: MeetingId]: AgendaItemUnboundDocument[] };
  documentsStatus: ReqStatus = ReqStatus.Initial;

  get loading(): boolean {
    return this.status === ReqStatus.InProcess;
  }

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.documentsByAgendaItem = {};
    this.documentsByMeeting = {};
    this.notifStore = notifStore;
    this.agendaStatusById = {};
    this.statusById = {};
  }

  getAvailableDocsForMeeting = (meetingId: MeetingId, agendaItemsIds: number[]) => {
    const allDocsIds = this.documentsByMeeting[meetingId]?.map(({ id }) => id) || [];
    const agendaItemDocsIds = agendaItemsIds
      .map((id) => this.documentsByAgendaItem[id]?.map((doc) => doc.id) || [])
      .flat(1);
    return allDocsIds.filter((docId) => !agendaItemDocsIds.includes(docId));
  };

  loadDocuments = async (meetingId: MeetingId) => {
    runInAction(() => {
      this.documentsStatus = ReqStatus.InProcess;
    });
    try {
      const resp = await api.get(`ebms:meetings/${meetingId}/meetingdocs`);
      const docs = resp!.data;
      runInAction(() => {
        this.documentsByMeeting = { ...this.documentsByMeeting, [meetingId]: docs };
        this.documentsStatus = ReqStatus.Success;
      });
      return docs;
    } catch (err) {
      this.notifStore.error("Failed to load meeting docs");
      this.documentsStatus = ReqStatus.Failed;
      return [];
    }
  };

  loadRelatedDocuments = async (agendaItemId: AgendaItemId) => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const resp = await api.get(`ebms:agendaitems/${agendaItemId}`);
      const docs = resp!.data!.meetingdocs || [];
      runInAction(() => {
        this.documentsByAgendaItem = {
          ...this.documentsByAgendaItem,
          [agendaItemId]: docs.map((doc) => ({ ...doc, agendaitem: agendaItemId })),
        };
        this.status = ReqStatus.Success;
      });
      return docs;
    } catch (err) {
      this.notifStore.error("Failed to load agenda docs");
      this.status = ReqStatus.Failed;
      return [];
    }
  };

  addDocItem = async (docId: number, agendaItemId: AgendaItemId, meetingId: MeetingId, position?: number) => {
    try {
      runInAction(() => {
        this.status = ReqStatus.InProcess;
        this.agendaStatusById[agendaItemId] = ReqStatus.InProcess;
      });
      await api.patch(`ebms:meetingdocs/${docId}`, { agendaitem: agendaItemId, ...(position ? { position } : {}) });
      await this.loadRelatedDocuments(agendaItemId);
      await this.loadDocuments(meetingId);
      runInAction(() => {
        this.agendaStatusById[agendaItemId] = ReqStatus.Success;
      });
      return true;
    } catch (e) {
      runInAction(() => {
        this.agendaStatusById[agendaItemId] = ReqStatus.Failed;
      });
      this.notifStore.error("Failed to add doc item");
      return null;
    }
  };

  changeDocItemPositionLocal = (docId: number, agendaItemId: AgendaItemId, position: number) => {
    runInAction(() => {
      let docs = this.documentsByAgendaItem[agendaItemId];
      if (!docs) return;
      const isChangedDocLast = Math.max(...docs.map(({ position }) => +position)) === position;
      docs = docs.map((doc) => {
        if (doc.id === docId) {
          return { ...doc, position };
        }
        if (isChangedDocLast) {
          return {
            ...doc,
            position: doc.position - 1,
          };
        }
        if (doc.position >= position) {
          return {
            ...doc,
            position: doc.position + 1,
          };
        }
        return doc;
      });
      this.documentsByAgendaItem = { ...this.documentsByAgendaItem, [agendaItemId]: docs };
    });
  };

  changeDocItemPosition = async (
    docId: number,
    oldAgendaItemId: AgendaItemId | null,
    newAgendaItemId: AgendaItemId,
    position: number
  ) => {
    try {
      runInAction(() => {
        this.status = ReqStatus.InProcess;
        this.agendaStatusById[newAgendaItemId] = ReqStatus.InProcess;
        if (oldAgendaItemId && oldAgendaItemId !== newAgendaItemId) {
          this.agendaStatusById[oldAgendaItemId] = ReqStatus.InProcess;
        }
      });
      this.changeDocItemPositionLocal(docId, newAgendaItemId, position);
      await api.patch(`ebms:meetingdocs/${docId}`, { position, agendaitem: newAgendaItemId });
      await this.loadRelatedDocuments(newAgendaItemId);
      if (oldAgendaItemId && oldAgendaItemId !== newAgendaItemId) {
        await this.loadRelatedDocuments(oldAgendaItemId);
      }
      runInAction(() => {
        this.agendaStatusById[newAgendaItemId] = ReqStatus.Success;
        if (oldAgendaItemId && oldAgendaItemId !== newAgendaItemId) {
          this.agendaStatusById[oldAgendaItemId] = ReqStatus.Success;
        }
      });
      return true;
    } catch (e) {
      runInAction(() => {
        this.agendaStatusById[newAgendaItemId] = ReqStatus.Failed;
        if (oldAgendaItemId && oldAgendaItemId !== newAgendaItemId) {
          this.agendaStatusById[oldAgendaItemId] = ReqStatus.Failed;
        }
      });
      this.notifStore.error("Failed to add doc item");
      return null;
    }
  };

  removeDocItem = async (docId: number, agendaItemId: AgendaItemId, meetingId: MeetingId) => {
    try {
      runInAction(() => {
        this.statusById[docId] = ReqStatus.InProcess;
      });
      await api.patch(`ebms:meetingdocs/${docId}`, { agendaitem: null });
      await this.loadRelatedDocuments(agendaItemId);
      await this.loadDocuments(meetingId);
      runInAction(() => {
        this.statusById[docId] = ReqStatus.Success;
      });
      return true;
    } catch (e) {
      runInAction(() => {
        this.statusById[docId] = ReqStatus.Failed;
      });
      this.notifStore.error("Failed to remove doc item");
      return null;
    }
  };

  setDocItemFeatured = async (agendaItemId: AgendaItemId, docUUID: string, docId: number, featured: boolean) => {
    try {
      runInAction(() => {
        this.statusById[docId] = ReqStatus.InProcess;
      });
      await api.patch(`ebms:docitems/${docUUID}/set-featured`, { featured });
      await this.loadRelatedDocuments(agendaItemId);
      runInAction(() => {
        this.statusById[docId] = ReqStatus.Success;
      });
      return true;
    } catch (e) {
      runInAction(() => {
        this.statusById[docId] = ReqStatus.Success;
      });
      this.notifStore.error("Failed to set doc item featured");
      return null;
    }
  };
}

const agendaItemDocsStore = new AgendaItemDocsStore(notificationStore);

export default agendaItemDocsStore;
