import { makeAutoObservable, runInAction } from "mobx";
import * as api from "~/api";
import notifStore, { NotificationStore } from "~/stores/notifications";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import SearchQuery from "~/searchQuery";
import keyBy from "lodash/keyBy";
import { ReqStatus } from "~/api";

export type SavedSearch = string;
export type SavedSearchId = string;
export interface SavedSearchInfo {
  id: SavedSearchId;
  user: string;
  user_display: string;
  description: string;
  search_value: string;
  // json
  complex_search_value: Partial<SearchQuery>;
}

export class SavedSearchStore {
  notifStore: NotificationStore;
  f: PaginatedEndpoint<SavedSearchInfo>;
  createReqStatus: ReqStatus = ReqStatus.Initial;

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.notifStore = notifStore;
    this.f = new PaginatedEndpoint<SavedSearchInfo>("bookmarked_searches", notifStore, {
      byId: "id",
      loadOnlyOnce: true,
    });
  }

  get bySearchValue(): Record<string, SavedSearchInfo> {
    return keyBy(this.f.itemsList, "search_value");
  }

  isSaved(sq: SearchQuery): SavedSearchInfo | null {
    if (sq.title.trim().length === 0) return null;
    const item = this.bySearchValue[sq.title];
    return item || null;
  }

  async saveQueryAs(sq: SearchQuery, name: string) {
    try {
      runInAction(() => {
        this.createReqStatus = ReqStatus.InProcess;
      });
      await api.post("bookmarked_searches", {
        description: name,
        ...(sq.title ? { search_value: sq.title } : {}),
        complex_search_value: sq.toJSON(),
      });
      await this.f.load("", null);
      this.notifStore.success(`Search query saved as "${name}"`);
      this.createReqStatus = ReqStatus.Success;
    } catch (err) {
      this.createReqStatus = ReqStatus.Failed;
      throw err;
    }
  }
}

const savedSearchStore = new SavedSearchStore(notifStore);

export default savedSearchStore;
