import { observer } from "mobx-react";
import docSeriesStore from "~/stores/docSeries";
import { DocSeriesId } from "~/document";
import AsyncPaginatedSelect from "~/components/AsyncPaginatedSelect";

interface Props {
  value: DocSeriesId[];
  onChange: (newValue: DocSeriesId[]) => void;
  selectProps?: any;
}

const DocSeriesSelect = observer((props: Props) => {
  const { value, onChange } = props;
  const idToOpt = (id: DocSeriesId) => {
    const item = docSeriesStore.f.items[id];
    if (!item) return null;
    return {
      label: item.label,
      value: item.id,
    };
  };

  return (
    <AsyncPaginatedSelect
      fetcher={docSeriesStore.f}
      itemIdToOption={idToOpt as any}
      value={value}
      onChange={onChange}
      selectProps={{
        isMulti: true,
        ...(props.selectProps || {}),
      }}
    />
  );
});

export default DocSeriesSelect;
