import CopyButton from "./CopyButton";
import notificationStore from "~/stores/notifications";
import paths from "~/paths";
import { iconCopy } from "@wfp/icons";
import { Document, documentDownloadLink } from "~/document";
import { useCallback, useContext, useMemo } from "react";
import { ToastNotificationContext } from "~/components/ToastNotificationContext";

const ShareDocumentButton = (props: { doc: Document; className?: string }) => {
  const { showNotificationWithTimeout, setNotificationData } = useContext(ToastNotificationContext);

  const onCopy = useCallback(() => {
    setNotificationData({
      kind: "success",
      title: "Success",
      subtitle: "Link copied to clipboard",
    });
    notificationStore.success("Successfully copied document URL to clipboard");
    showNotificationWithTimeout();
  }, []);

  const url = useMemo(() => {
    try {
      return new URL(documentDownloadLink(props.doc)).toString();
    } catch (e) {
      return "";
    }
  }, [props.doc]);

  return (
    <CopyButton
      copyContent={url}
      onCopy={onCopy}
      onFail={() => notificationStore.error("Successfully copied document URL to clipboard")}
      buttonProps={{
        className: props.className,
        small: true,
        kind: "secondary",
        icon: iconCopy,
      }}
    >
      Copy link
    </CopyButton>
  );
};

export default ShareDocumentButton;
