import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import TabsPageView from "~/components/TabsPageView";
import {
  Blockquote,
  Button,
  FormLabel,
  InlineLoading,
  Loading,
  Modal,
  Text,
  TextInput,
  ToastNotificationProps,
} from "@wfp/ui";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { SingleDatePicker } from "~/components/PublicationDateSelect";
import { observer } from "mobx-react";
import venueStore from "~/stores/venues";
import meetingStore, {
  MeetingEventTypes,
  MeetingFormKey,
  MeetingLanguage,
  meetingLanguagesList,
  MeetingNode,
  MeetingStatus,
  meetingStatusMap,
  TimeScheduleItem,
} from "~/stores/meeting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import uuid from "react-uuid";
import TimeScheduleField from "~/components/TimeScheduleField/TimeScheduleField";
import { MeetingLanguageContext } from "~/pages/Meetings/MeetingsTab";
import ModalCodeRulesModal from "~/components/ModalCodeRulesModal/ModalCodeRulesModal";
import { fieldReadable, formatDateReadable, ReqStatus } from "~/util";
import governanceFrameworkStore from "~/stores/governanceFrameworks";
import { CommonTabProps } from "~/pages/PersonalArea/TabTable";
import { DATE_API_FORMAT } from "~/constants";
import InputErrorText from "~/components/InputErrorText";
import { useLocalizedFieldsGetter } from "~/util/hooks";
import { useNavigate } from "react-router-dom";
import paths from "~/paths";
import { useParams } from "react-router";
import AgendaItemsField from "~/components/AgendaItems/AgendaItemsField/AgendaItemsField";
import agendaItemStore from "~/stores/agendaItems";
import MeetingQuickLinks from "~/components/MeetingQuickLinks/MeetingQuickLinks";
import { ToastNotificationContext } from "~/components/ToastNotificationContext";
import moment from "moment";
import agendaItemDocsStore from "~/stores/agendaItemDocs";
import AvailableDocumentsField from "~/components/AvailableDocumentsField/AvailableDocumentsField";
import TextAreaWithLink from "~/components/TextAreaWithLink/TextAreaWithLink";
import meetingTypeStore from "~/stores/meetingTypes";
import cn from "classnames";

export type TextFieldChangeType = (
  key: MeetingFormKey
) => (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => void;
export type SelectFieldType = (key: MeetingFormKey) => (option: { value: string | MeetingEventTypes }) => void;

type UploadType = "default" | "clone" | "edit";

type InfoTabProps = {
  infoVisible?: boolean;
  meeting: Partial<MeetingNode>;
  onDefaultChange?: (evt: React.ChangeEvent<HTMLInputElement>, checked: boolean, customId: string) => void;
};

export const MeetingInfoTab = ({ infoVisible, meeting }: InfoTabProps) => {
  return (
    <div className={styles.meetingSelection}>
      {infoVisible && (
        <>
          <div className="mb-8">
            <div className={styles.meetingInfoItem}>
              <div className={styles.meetingInfoItemLabel}>Current status: </div>
              {meetingStatusMap[meeting.status]}
            </div>
            <div className={styles.meetingInfoItem}>
              <div className={styles.meetingInfoItemLabel}>Created: </div>
              {moment(meeting.creation_date).format("DD/MM/YYYY HH:mm")}
            </div>
            {!!meeting.publication_date && (
              <div className={styles.meetingInfoItem}>
                <div className={styles.meetingInfoItemLabel}>First time published:</div>
                {moment(meeting.publication_date).format("DD/MM/YYYY HH:mm")}
              </div>
            )}
            {!!meeting.update_date && (
              <div className={styles.meetingInfoItem}>
                <div className={styles.meetingInfoItemLabel}>Last updated: </div>
                {moment(meeting.update_date).format("DD/MM/YYYY HH:mm")}
              </div>
            )}
          </div>
          <div>
            {!!meeting.zoom_id && (
              <div className={styles.meetingInfoItem}>
                <div className={styles.meetingInfoItemLabel}>Zoom ID:</div>
                {meeting.zoom_id}
              </div>
            )}
            {!!meeting.ebmm_id && (
              <div className={styles.meetingInfoItem}>
                <div className={styles.meetingInfoItemLabel}>EBMM ID:</div>
                {meeting.ebmm_id}
              </div>
            )}
            {!!meeting.event_status && (
              <div className={styles.meetingInfoItem}>
                <div className={styles.meetingInfoItemLabel}>Event Status:</div>
                {meeting.event_status}
              </div>
            )}
            {!!meeting.event_format && (
              <div className={styles.meetingInfoItem}>
                <div className={styles.meetingInfoItemLabel}>Event Format:</div>
                {meeting.event_format}
              </div>
            )}
            {!!meeting.webcast_token && (
              <div className={styles.meetingInfoItem}>
                <div className={styles.meetingInfoItemLabel}>Webcast:</div>
                {meeting.webcast_token}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

type Props = {
  lang: MeetingLanguage;
  defaultValue?: Partial<MeetingNode>;
  uploadType?: UploadType;
  meetingForm: Partial<MeetingNode>;
  setMeetingForm: React.Dispatch<React.SetStateAction<Partial<MeetingNode>>>;
  timeScheduleState: Partial<TimeScheduleItem>[];
  setTimeScheduleState: React.Dispatch<React.SetStateAction<Partial<TimeScheduleItem>[]>>;
} & CommonTabProps;

export const MeetingsUploadTab = observer(
  ({
    lang,
    defaultValue,
    uploadType = "default",
    selected,
    meetingForm,
    setMeetingForm,
    timeScheduleState,
    setTimeScheduleState,
  }: Props) => {
    const navigate = useNavigate();
    const { loading } = meetingStore;
    const { meetingId } = useParams();
    const [isModalOpened, setModalOpened] = useState(false);
    const getLocalizedField = useLocalizedFieldsGetter(lang);
    const getLocalizedFieldValue = useCallback(
      (prefix) => meetingForm[getLocalizedField(prefix)] as string,
      [getLocalizedField, meetingForm]
    );

    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const showArchiveModal = () => setArchiveModalOpen(true);
    const hideArchiveModal = () => setArchiveModalOpen(false);

    const [validationErrors, setValidationErrors] = useState<Map<string, any>>(new Map());

    const isEBMM = !!meetingForm.ebmm_id;
    // const isEBMM = true;

    const meetingTypeOptions = useMemo(
      () =>
        (meetingTypeStore.meetingTypesList || []).slice().sort((a, b) => {
          const aLabel = a.label || "";
          const bLabel = b.label || "";
          return aLabel.localeCompare(bLabel);
        }),
      [meetingTypeStore.meetingTypesList]
    );

    const validateMeetingCode = async () => {
      if (meetingForm.code && meetingForm.meeting_type) {
        try {
          await meetingStore.validateMeetingCode(meetingForm.code, meetingForm.meeting_type);
          return true;
        } catch (e: any) {
          setValidationErrors(
            (prev) => new Map([...prev, ["code", [e.response?.data?.detail || "Meeting code could not be validated"]]])
          );
          return false;
        }
      }
      return false;
    };

    const validateTimeScheduleField = () => {
      let valid = true;
      timeScheduleState.forEach(({ start_time_1, start_time_2, end_time_1, end_time_2 }) => {
        let [timeStart, timeEnd] = [start_time_1, end_time_1];
        let [breakTimeStart, breakTimeEnd] = [start_time_2, end_time_2];
        if (!timeStart || !timeEnd) {
          valid = false;
          return;
        }

        const [timeStartHours, timeStartMinutes] = timeStart.split(":");
        const [timeEndHours, timeEndMinutes] = timeEnd.split(":");
        const timeEndCorrect =
          new Date(2022, 1, 3, +timeEndHours, +timeEndMinutes).getTime() >
          new Date(2022, 1, 3, +timeStartHours, +timeStartMinutes).getTime();

        if (!timeEndCorrect) {
          valid = false;
          return;
        }

        const breakExists = !!breakTimeStart || !!breakTimeEnd;
        if (breakExists) {
          const breakValid = !!breakTimeStart && !!breakTimeEnd;
          if (!breakValid) {
            valid = false;
            return;
          }

          // both break start and end should be later than general time end, also break end should be later than break start
          const [breakStartHours, breakStartMinutes] = breakTimeStart.split(":");
          const breakStartCorrect =
            new Date(2022, 1, 3, +breakStartHours, +breakStartMinutes).getTime() >
            new Date(2022, 1, 3, +timeEndHours, +timeEndMinutes).getTime();

          const [breakEndHours, breakEndMinutes] = breakTimeEnd.split(":");
          const breakEndCorrect =
            new Date(2022, 1, 3, +breakEndHours, +breakEndMinutes).getTime() >
            new Date(2022, 1, 3, +breakStartHours, +breakStartMinutes).getTime();

          if (!breakStartCorrect || !breakEndCorrect) {
            valid = false;
          }
        }
      });
      return valid;
    };

    const validateField = (key: keyof MeetingNode) => async () => {
      const customValidationFunctions = {
        time_schedule: validateTimeScheduleField,
        code: validateMeetingCode,
      };
      const validate = customValidationFunctions[key];
      let fieldValid: boolean;
      if (validate) {
        fieldValid = await validate();
      } else {
        fieldValid = !!meetingForm[key];
      }
      if (fieldValid) {
        setValidationErrors((prevState) => {
          const updatedState = new Map(prevState);
          updatedState.delete(key);
          return updatedState;
        });
      }
    };

    const { setNotificationData, showNotificationWithTimeout } = useContext(ToastNotificationContext);

    const onSetByDatePicked = (date: Date) => {
      const daysBetweenStartAndSelected = moment(date).diff(moment(meetingForm.start_date), "days");
      if (meetingForm.start_date) {
        setTimeScheduleState((prev) => [
          {
            ...(timeScheduleState[0] || {}),
            default: true,
            key: uuid(),
            day: meetingForm.start_date.toString(),
          },
          ...Array.from(Array(daysBetweenStartAndSelected)).map((_, i) => {
            const day = moment(meetingForm.start_date)
              .add(i + 1, "days")
              .toISOString();
            const existingItem = prev.find((item) => item.day === day) || {};
            return {
              ...existingItem,
              default: false,
              key: uuid(),
              day,
            };
          }),
        ]);
      }
    };

    const setTimeFieldByKey = (key: string, field: string, value?: string) => {
      const foundIndex = timeScheduleState.findIndex((item) => item.key === key);
      if (foundIndex !== -1) {
        setTimeScheduleState((prev) => {
          const copy = [...prev];
          copy[foundIndex] = { ...copy[foundIndex], [field]: value };
          return copy;
        });
      }
    };

    const onTimeFieldChange = (key: string, field: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setTimeFieldByKey(key, field, e.target.value);
    };

    const removeTimeField = (key: string) => () => {
      setTimeScheduleState((prev) => prev.filter((item) => item.key !== key));
    };

    const onTimeScheduleDefaultChange = (timeScheduleKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const foundTimeScheduleIndex = timeScheduleState.findIndex(({ key }) => key === timeScheduleKey);
      if (foundTimeScheduleIndex !== -1) {
        setTimeScheduleState(
          timeScheduleState.map((item, index) =>
            foundTimeScheduleIndex === index ? { ...item, default: e.target.checked } : { ...item, default: false }
          )
        );
      }
    };

    const onTextFieldChange =
      (key: MeetingFormKey) => (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>) => {
        setMeetingForm((prev) => ({ ...prev, [key]: e.target.value || "" }));
      };

    const onCheckboxFieldChange = (key: MeetingFormKey) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setMeetingForm((prev) => ({ ...prev, [key]: e.target.checked }));
    };

    const onDateFieldChange = (key: MeetingFormKey) => (e: Date | undefined) => {
      setMeetingForm((prev) => ({ ...prev, [key]: e }));
    };

    const onDropdownFieldChange = (key: MeetingFormKey) => (option: { value: string | MeetingEventTypes }) => {
      setMeetingForm((prev) => ({ ...prev, [key]: option.value }));
    };

    const timeSchedule = useMemo(() => {
      return [
        { ...(timeScheduleState?.[0] || []), day: meetingForm.start_date },
        ...(timeScheduleState?.slice(1) || []),
      ];
    }, [timeScheduleState, meetingForm.start_date]);

    const saveMeeting = useCallback(
      (status: MeetingStatus = 0) =>
        () => {
          hideArchiveModal();
          const meeting = { ...meetingForm };
          const formatDate = (date: Date) => formatDateReadable(date, DATE_API_FORMAT);
          const filteredTimeSchedule = timeSchedule.filter(({ start_time_1 }) => !!start_time_1);
          if (filteredTimeSchedule.length) {
            meeting.time_schedule = filteredTimeSchedule.map(
              ({ key, ...item }) => ({ ...item, day: formatDate(new Date(item.day)) } as TimeScheduleItem)
            );
          } else {
            meeting.time_schedule = [
              {
                day: formatDate(new Date(meeting.start_date)),
                default: true,
                end_time_1: "00:00:00",
                end_time_2: null,
                start_time_1: "00:00:00",
                start_time_2: null,
              } as TimeScheduleItem,
            ];
          }
          if (meeting.start_date) meeting.start_date = formatDate(new Date(meeting.start_date));
          if (meeting.end_date) meeting.end_date = formatDate(new Date(meeting.end_date));
          if (!meeting.time_schedule?.length) {
          }
          meeting.status = status;
          const uploadFunctionByType = {
            default: () => meetingStore.postMeeting(meeting as MeetingNode),
            edit: () => meetingStore.patchMeeting(meeting as MeetingNode, +meetingId),
            clone: () => meetingStore.cloneMeeting(meeting as MeetingNode, +meetingId),
          };
          const notificationSuccessMessageByType: { [key in UploadType]: ToastNotificationProps } = {
            default: {
              kind: "success",
              title: "Success",
              subtitle: "Meeting uploaded successfully",
            },
            clone: {
              kind: "success",
              title: "Success",
              subtitle: "Meeting cloned successfully",
            },
            edit: {
              kind: "success",
              title: "Success",
              subtitle: "Meeting edited successfully",
            },
          };
          let promise: Promise<MeetingNode> = uploadFunctionByType[uploadType]();
          promise
            .then((meeting) => {
              setNotificationData(notificationSuccessMessageByType[uploadType]);
              showNotificationWithTimeout();
              navigate(paths.construct.meetingPreview(meeting?.id));
            })
            .catch((err) => {
              if (err?.response?.data) {
                const fieldErrors = [];
                Object.keys(err.response.data).forEach((key) => {
                  fieldErrors.push([
                    key,
                    typeof err.response.data[key]?.[0] === "string"
                      ? err.response.data[key]
                      : [`${fieldReadable(key)} is invalid`],
                  ]);
                });
                +setValidationErrors(new Map(fieldErrors));
              }
              window.scrollTo(0, 0);
              setNotificationData({
                kind: "error",
                title: "Error",
                subtitle: "Meeting was not uploaded",
              });
              showNotificationWithTimeout();
              console.warn(`meeting upload ${uploadType} error ---`, err);
            });
        },
      [meetingForm, timeSchedule, uploadType, meetingId]
    );

    const venues = venueStore.venues;

    const venueOptions = useMemo(
      () =>
        venues?.map((venue) => ({
          label: venue.name,
          value: venue.id,
        })),
      [venues]
    );
    const activeVenueOption = useMemo(
      () => venueOptions.find(({ value }) => value === meetingForm.venue),
      [venueOptions, meetingForm]
    );

    const activeMeetingTypeOption = useMemo(
      () => meetingTypeOptions.find(({ value }) => value === meetingForm.meeting_type),
      [meetingForm]
    );

    const createVenue = async (venueName: string) => {
      const venue = await venueStore.postVenue(venueName);
      if (venue) {
        setMeetingForm((prevState) => ({ ...prevState, venue: venue.id }));
      }
    };

    useEffect(() => {
      if (defaultValue) {
        if (defaultValue.time_schedule) {
          setTimeScheduleState(defaultValue.time_schedule);
        }
        setMeetingForm(defaultValue);
      }
    }, [defaultValue]);

    useEffect(() => {
      if (meetingId && selected) {
        agendaItemDocsStore.loadDocuments(meetingId);
      }
    }, [meetingId]);

    useEffect(() => {
      const meetingAgendaItems = agendaItemStore.agendaItemsByMeeting?.[meetingId];
      if (meetingAgendaItems) {
        setMeetingForm((prev) => ({ ...prev, agenda_items: meetingAgendaItems }));
      }
    }, [agendaItemStore.agendaItemsByMeeting?.[meetingId]]);

    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
      if (selected) {
        venueStore.loadVenues();
        governanceFrameworkStore.loadGovernanceFrameworks();
        meetingTypeStore.loadMeetingTypes();
      }
    }, []);

    const meetingCodeHelper = useMemo(() => {
      if (!meetingForm.meeting_type || !meetingTypeStore.meetingTypesList) return "";

      const typesWithDefinedIndex = ["EBA", "EB1", "EB2"];
      const foundMeetingType = meetingTypeStore.meetingTypesList.find(
        (type) => type.value === meetingForm.meeting_type
      );
      if (!foundMeetingType) return "";

      if (typesWithDefinedIndex.includes(foundMeetingType.prefix)) {
        return `${foundMeetingType.prefix}/YYYY`;
      } else {
        return `${foundMeetingType.prefix}X/YYYY`;
      }
    }, [meetingTypeStore.meetingTypesList, meetingForm.meeting_type]);

    return (
      selected && (
        <MeetingLanguageContext.Provider value={{ lang }}>
          {loading && <Loading />}
          <div className={styles.meeting}>
            <MeetingInfoTab
              infoVisible={uploadType === "edit"}
              onDefaultChange={onCheckboxFieldChange("default")}
              meeting={meetingForm}
            />
            <div className={styles.meetingBody}>
              <div className={styles.meetingRow}>
                <div>
                  <TextInput
                    className={cn(styles.meetingRowItem)}
                    labelText="Meeting title*"
                    disabled={isEBMM}
                    onBlur={validateField(getLocalizedField("title"))}
                    invalid={!!validationErrors.get(getLocalizedField("title"))}
                    invalidText={validationErrors.get(getLocalizedField("title"))?.[0]}
                    value={getLocalizedFieldValue("title")}
                    onChange={onTextFieldChange(getLocalizedField("title"))}
                  />
                </div>
              </div>
              <div className={styles.meetingRow}>
                <div>
                  <div className={cn(styles.meetingRowItem, { "select--disabled__container": isEBMM })}>
                    <div className={styles.meetingRowItemLabel}>
                      <FormLabel className={cn({ "wfp--label--disabled": isEBMM })}>Meeting type*</FormLabel>
                      {meetingTypeStore.loading && <InlineLoading />}
                    </div>
                    <Select
                      isDisabled={isEBMM}
                      classNamePrefix="select"
                      className={isEBMM ? "select--disabled" : ""}
                      onBlur={validateField("meeting_type")}
                      onChange={onDropdownFieldChange("meeting_type")}
                      value={activeMeetingTypeOption}
                      options={meetingTypeOptions}
                      placeholder="Select meeting type"
                    />
                    <InputErrorText
                      visible={!!validationErrors.get("meeting_type")}
                      text={validationErrors.get("meeting_type")?.[0]}
                    />
                  </div>
                  <div className={styles.meetingRowItem}>
                    <FormLabel className={cn({ "wfp--label--disabled": isEBMM })}>
                      Meeting code*{" "}
                      <FontAwesomeIcon
                        icon={solid("info-circle")}
                        fontSize={16}
                        cursor="pointer"
                        onClick={() => setModalOpened(true)}
                      />
                    </FormLabel>
                    <TextInput
                      labelText=""
                      placeholder={meetingCodeHelper}
                      onChange={onTextFieldChange("code")}
                      onBlur={validateField("code")}
                      value={meetingForm.code}
                      invalid={!!meetingForm.meeting_type && !!validationErrors.get("code")}
                      invalidText={validationErrors.get("code")}
                      disabled={!meetingForm.meeting_type || isEBMM}
                    />
                    <ModalCodeRulesModal isOpened={isModalOpened} setOpened={setModalOpened} />
                    {!meetingForm.meeting_type && (
                      <Blockquote>Please select meeting type first to enable meeting code</Blockquote>
                    )}
                  </div>
                </div>
              </div>
              <div className={styles.meetingRow}>
                <div>
                  <div className={styles.meetingRowItem}>
                    <div className={styles.meetingRowItemLabel}>
                      <FormLabel className={cn("mb-0", { "wfp--label--disabled": isEBMM })}>Venue</FormLabel>
                      {venueStore.status === ReqStatus.InProcess && <InlineLoading />}
                    </div>
                    <CreatableSelect
                      onCreateOption={createVenue}
                      classNamePrefix="select"
                      isDisabled={isEBMM}
                      onChange={onDropdownFieldChange("venue")}
                      value={activeVenueOption}
                      options={venueOptions}
                      placeholder="Select venue"
                    />
                  </div>
                </div>
              </div>
              <div className="separator" />
              <div className={styles.meetingRow}>
                <Text kind="h2" className={cn(styles.meetingRowTitle, { "wfp--label--disabled": isEBMM })}>
                  Meeting dates
                </Text>
                <div className={cn({ "field--disabled": isEBMM })}>
                  <div className={styles.meetingRowItemSmall}>
                    <SingleDatePicker
                      allowPast={uploadType === "edit"}
                      helperText=""
                      disabled={isEBMM}
                      onBlur={validateField("start_date")}
                      labelText="Start date*"
                      onChange={onDateFieldChange("start_date")}
                      value={meetingForm.start_date ? new Date(meetingForm.start_date) : null}
                    />
                    <InputErrorText
                      visible={!!validationErrors.get("start_date")}
                      text={validationErrors.get("start_date")?.[0]}
                    />
                  </div>
                  <div className={styles.meetingRowItemSmall}>
                    <SingleDatePicker
                      allowPast={uploadType === "edit"}
                      minDate={moment(meetingForm.start_date)}
                      helperText=""
                      disabled={isEBMM}
                      labelText="End date"
                      onChange={onDateFieldChange("end_date")}
                      value={meetingForm.end_date ? new Date(meetingForm.end_date) : null}
                    />
                  </div>
                </div>
              </div>
              <div className={cn(styles.meetingRow, { "field--disabled": isEBMM })}>
                <Text kind="h5" className={cn(styles.meetingRowTitle, { "wfp--label--disabled": isEBMM })}>
                  Time schedule
                </Text>
                <div>
                  <div>
                    <TimeScheduleField
                      timeSchedule={timeSchedule}
                      onChange={onTimeFieldChange}
                      setTimeField={setTimeFieldByKey}
                      onBlur={validateField("time_schedule")}
                      disabled={!meetingForm.start_date || isEBMM}
                      remove={removeTimeField}
                      onDefaultChange={onTimeScheduleDefaultChange}
                    ></TimeScheduleField>
                  </div>
                  {!!meetingForm.start_date && !!meetingForm.end_date && (
                    <div className={styles.meetingRowItem}>
                      <SingleDatePicker
                        allowPast
                        helperText=""
                        minDate={moment(meetingForm.start_date).add(1, "day")}
                        maxDate={moment(meetingForm.end_date)}
                        disabled={!meetingForm.start_date || isEBMM}
                        labelText="Set time by date"
                        onChange={onSetByDatePicked}
                        value={null}
                      />
                    </div>
                  )}
                </div>
                <InputErrorText
                  visible={!!validationErrors.get("time_schedule")}
                  text={validationErrors.get("time_schedule")?.[0]}
                />
              </div>
              <div className="separator" />
              <div className={cn(styles.meetingRow, { "field--disabled": isEBMM })}>
                <FormLabel className="mb-16">Public meeting notes</FormLabel>
                <TextAreaWithLink
                  disabled={isEBMM}
                  defaultValue={getLocalizedFieldValue("notes")}
                  onChange={(htmlString) =>
                    setMeetingForm((prev) => ({ ...prev, [getLocalizedField("notes")]: htmlString }))
                  }
                />
              </div>
              <div className="separator" />
              <div className={styles.meetingRow}>
                <MeetingQuickLinks
                  disabled={uploadType !== "edit" || isEBMM}
                  withTag={!isEBMM}
                  links={meetingForm.links || []}
                  setMeetingForm={setMeetingForm}
                  lang={lang}
                  meetingId={meetingId}
                />
              </div>
              <div className="separator" />
              <div className={styles.meetingRow}>
                <div>
                  <div className={styles.meetingRowItem}>
                    <AgendaItemsField lang={lang} meetingId={meetingId} />
                  </div>
                </div>
              </div>
              {uploadType === "edit" && (
                <div className={styles.meetingRow}>
                  <div>
                    <div className={styles.meetingRowItem}>
                      <AvailableDocumentsField
                        agendaItems={uploadType !== "edit" ? null : meetingForm.agenda_items}
                        meetingId={meetingId}
                      />
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-32 df">
                {!isEBMM && (
                  <>
                    <Button kind="secondary" onClick={saveMeeting(0)}>
                      Save as draft
                    </Button>
                    <Button onClick={saveMeeting(100)}>Save and Publish</Button>
                  </>
                )}
                {uploadType === "edit" && (
                  <Button kind="danger" className={cn({ "ml-10": !isEBMM })} onClick={showArchiveModal}>
                    Archive
                  </Button>
                )}
              </div>
              {process.env.JEST === "1" && <div data-testid="upload-meeting" onClick={saveMeeting(0)} />}
              <Modal
                modalHeading="Confirm action"
                primaryButtonText="Archive"
                secondaryButtonText="Cancel"
                open={archiveModalOpen}
                onRequestClose={hideArchiveModal}
                onRequestSubmit={saveMeeting(500)}
                onSecondarySubmit={hideArchiveModal}
              >
                <Text>Are you sure you want to Archive this meeting?</Text>
                <Text>Warning: this action will make the meeting disappear on the public Executive Board website</Text>
              </Modal>
            </div>
          </div>
        </MeetingLanguageContext.Provider>
      )
    );
  }
);

const MeetingsUpload = () => {
  const [meetingForm, setMeetingForm] = useState<Partial<MeetingNode>>({});
  const [timeScheduleState, setTimeScheduleState] = useState<Partial<TimeScheduleItem>[]>([
    {
      key: uuid(),
      default: true,
    },
  ]);
  return (
    <TabsPageView
      breadcrumbsItems={[{ label: "Meetings", href: "/meetings" }, { label: "Add New Meeting" }]}
      tabs={meetingLanguagesList.map((lang) => ({
        label: lang.title,
        id: lang.key,
        render: (selected) => (
          <MeetingsUploadTab
            meetingForm={meetingForm}
            setMeetingForm={setMeetingForm}
            key={`meeting-${lang.key}-${lang.title}`}
            timeScheduleState={timeScheduleState}
            setTimeScheduleState={setTimeScheduleState}
            lang={lang.key}
            selected={selected}
          />
        ),
      }))}
      pageTitle="Add new meeting"
      withTabContentBackground
      withTabContentPadding={false}
    />
  );
};

export default MeetingsUpload;
