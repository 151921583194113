// Moment.js format strings
export const PUB_DATE_FORMAT = "DD-MM-YYYY";
export const EXP_DATE_FORMAT = "DD-MM-YYYY";
export const DATE_API_FORMAT = "YYYY-MM-DD";

// Debounce interval for AsyncPaginatedSelect and selects derived from it
export const ASYNC_SELECT_DEBOUNCE_INTERVAL_MS = 1000;

// Cursor value returned by API that represents that there is no next page available
export const END_PAGINATION_CURSOR = null;
