import { makeAutoObservable } from "mobx";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import notifStore from "~/stores/notifications";
import { Document, DocPayload } from "~/document";
import * as api from "~/api";
import topicStore, { TopicStore } from "./topics";
import countryStore, { CountryStore } from "./country";
import tagStore, { TagStore } from "./tags";
import officeStore, { OfficeStore } from "./offices";
import docSeriesStore, { DocSeriesStore } from "./docSeries";
import docTypeStore, { DocTypeStore } from "./documentTypes";
import projectStore, { ProjectStore } from "./projects";
import divisionStore from "./divisions";
import ownerStore from "./owners";

export type TemplateId = string;
export interface TemplateInfo {
  id: TemplateId;
  description: string;
  user_display: string;
  json_template: Partial<Document>;
}

export class TemplateStore {
  f = new PaginatedEndpoint<TemplateInfo>("bookmarked_templates", notifStore, {
    itemTransformer: (resp: any) => {
      const fields = JSON.parse(resp.json_template) as Partial<Document>;
      return {
        ...resp,
        json_template: fields,
      } as TemplateInfo;
    },
  });

  constructor() {
    makeAutoObservable(this);
  }

  addTemplate = async (templateName: string, doc: DocPayload) => {
    const resp = await api.post("bookmarked_templates", {
      description: templateName,
      json_template: JSON.stringify(doc),
    });
    if (resp === null) return null;
    const template = resp.data;
    this.f.items[template.id] = template;
    return template;
  };

  editTemplate = async (templateId: TemplateId, templateName: string, doc: DocPayload) => {
    const resp = await api.put(`bookmarked_templates/${templateId}`, {
      description: templateName,
      json_template: JSON.stringify(doc),
    });
    if (resp === null) return null;
    const template = resp.data;
    this.f.items[template.id] = template;
    return template;
  };

  requestAllMissingDataForTemplate = async (template: TemplateInfo) => {
    const f = template.json_template;
    const promises: Promise<any>[] = [];
    if (f.countries) {
      promises.push(countryStore.f.loadIds(f.countries));
    }
    if (f.tags) {
      promises.push(tagStore.f.loadIds(f.tags));
    }
    const types = [];
    if (f.type) types.push(f.type);
    if (f.secondary_type) types.push(f.secondary_type);
    if (types.length !== 0) {
      promises.push(docTypeStore.loadDocTypes(types));
    }
    if (f.topics) {
      promises.push(topicStore.loadTopics(f.topics));
    }
    if (f.projects) {
      promises.push(projectStore.f.loadIds(f.projects));
    }
    if (f.office !== undefined) {
      promises.push(officeStore.f.loadItem(f.office));
    }
    if (f.serie !== undefined) {
      promises.push(docSeriesStore.f.loadItem(f.serie));
    }
    if (f.owner !== undefined) {
      promises.push(ownerStore.f.loadItem(f.owner));
    }
    if (f.division !== undefined) {
      promises.push(divisionStore.f.loadItem(f.division));
    }
    return await Promise.all(promises);
  };

  getTemplate = async (templateId: TemplateId): Promise<TemplateInfo | null> => {
    if (this.f.items[templateId] !== undefined) return this.f.items[templateId];
    const item = await this.f.loadItem(templateId);
    if (item === null) return null;
    await this.requestAllMissingDataForTemplate(item);
    return item;
  };
}

const templateStore = new TemplateStore();

export default templateStore;
