import { observer } from "mobx-react";
import agendaItemDocsStore, { AgendaItemDocument } from "~/stores/agendaItemDocs";
import { AgendaItemId, AgendaItemUnboundDocument } from "~/stores/agendaItems";
import { useDrag } from "react-dnd";
import cn from "classnames";
import styles from "./styles.module.scss";
import React, { ChangeEvent, useCallback, useContext, useState } from "react";
import { useParams } from "react-router";
import { NavLink } from "react-router-dom";
import paths from "~/paths";
import { Button, Checkbox, InlineLoading, Link, Text } from "@wfp/ui";
import AgendaItemDocSupplementaryInfo from "./AgendaItemDocSupplementaryInfo";
import { ToastNotificationContext } from "~/components/ToastNotificationContext";
import { AGENDA_ITEM_DOC_DND } from "~/components/AvailableDocumentsField/AvailableDocumentsField";

const AgendaItemDocBody = observer(({ doc, agendaItemId }: { doc: AgendaItemDocument; agendaItemId: AgendaItemId }) => {
  const { meetingId } = useParams();
  const [loading, setLoading] = useState(false);

  const onFeaturedCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) =>
      agendaItemDocsStore.setDocItemFeatured(agendaItemId, doc.docitem_id, doc.id, e.target.checked),
    [agendaItemId, doc.docitem_id]
  );

  const removeDoc = useCallback(async () => {
    setLoading(true);
    await agendaItemDocsStore.removeDocItem(doc.id, agendaItemId, meetingId);
    setLoading(false);
  }, [agendaItemId]);

  return (
    <div className={cn(styles.agendaDoc)}>
      <div className={styles.agendaDocBody}>
        <div>
          <NavLink
            to={paths.construct.docDetail(doc.protocol)}
            target="_blank"
            rel="noopener noreferrer"
            className={`wfp--link ${styles.agendaDocTitle}`}
          >
            {doc.title}
          </NavLink>
        </div>
        <div className={styles.agendaDocActions}>
          {loading && <InlineLoading />}
          <Checkbox
            disabled={loading}
            labelText="Featured"
            id={`${doc.id}-featured`}
            checked={doc.featured}
            onChange={onFeaturedCheckboxChange}
            wrapperClassName="mb-0"
          ></Checkbox>
          <Button onClick={removeDoc} disabled={loading} small kind="secondary">
            Remove
          </Button>
        </div>
      </div>
      {!!doc.symbol && <Text className={styles.agendaDocSymbol}>{doc.symbol}</Text>}
      <AgendaItemDocSupplementaryInfo doc={doc} />
    </div>
  );
});

const AgendaItemDoc = observer(
  ({ doc, agendaItemId }: { doc: AgendaItemDocument; agendaItemId: AgendaItemId; isLast?: boolean }) => {
    const { showNotificationWithTimeout, setNotificationData } = useContext(ToastNotificationContext);
    const [{ isDragging }, drag] = useDrag({
      type: AGENDA_ITEM_DOC_DND,
      item: doc,
      end: async (item, monitor) => {
        const dropResult = monitor.getDropResult<{
          id: number;
          position?: number;
          oldAgendaItemId: AgendaItemId | null;
          agendaItemId: AgendaItemId;
        }>();
        if (dropResult) {
          const res = await agendaItemDocsStore.changeDocItemPosition(
            doc.id,
            dropResult.oldAgendaItemId,
            dropResult.agendaItemId,
            dropResult.position
          );
          if (!res) {
            setNotificationData({
              title:
                "Your action could not be completed due to an API error. Please try again later or contact the it.servicedesk@wfp.org for support.",
              kind: "error",
            });
            showNotificationWithTimeout();
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    });
    return (
      <div className={cn(styles.agendaDocContainer)} ref={drag}>
        {!isDragging && (
          <div style={{ position: "relative" }}>
            <AgendaItemDocBody doc={doc} agendaItemId={agendaItemId} />
          </div>
        )}
      </div>
    );
  }
);

export default AgendaItemDoc;
