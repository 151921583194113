import projectStore, { ProjectId } from "~/stores/projects";
import { observer } from "mobx-react";
import AsyncPaginatedSelect from "~/components/AsyncPaginatedSelect";
import { useEffect } from "react";

interface Props {
  value: ProjectId[];
  onChange: (newValue: ProjectId[]) => void;
}

const ProjectSelect = observer(({ value, onChange }: Props) => {
  const isLoading = projectStore.f.loading;
  const fetcher = projectStore.f;

  const idToOpt = (id: ProjectId) => {
    const item = fetcher.items[id];
    if (!item || item.code === "") return null;
    return {
      label: item.code,
      value: item.id,
    };
  };

  useEffect(() => {
    projectStore.f.load("");
  }, []);

  return (
    <AsyncPaginatedSelect
      fetcher={fetcher}
      itemIdToOption={idToOpt}
      value={value}
      onChange={onChange}
      selectProps={{
        isMulti: true,
      }}
    />
  );
});

export default ProjectSelect;
