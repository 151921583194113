import React from "react";
import styles from "./styles.module.scss";
import { observer } from "mobx-react";
import { Button, Text } from "@wfp/ui";
import { useNavigate } from "react-router";
import paths from "~/paths";
import HomeMeetingsTable from "~/pages/HomePage/HomeMeetingsTable";
import { useDataFetcher } from "~/pages/PersonalArea/common";
import meetingStore from "~/stores/meeting";
import documentStore from "~/stores/document";
import HomeDocumentsTable from "~/pages/HomePage/HomeDocumentsTable";

const Home = observer(() => {
  const navigate = useNavigate();
  const meetingsFetcher = useDataFetcher(true, meetingStore.loadMeetings, {
    nextKey: "next_page",
    prevKey: "previous_page",
    pagesCountKey: "pages_count",
    itemsCountKey: "total_items_count",
    currentPageKey: "page_num",
    type: "infinite",
  });

  const docsFetcher = useDataFetcher(
    true,
    (_, cursor) => {
      return documentStore.loadLatestDocuments("", cursor, "-dss_data__publication_date");
    },
    {
      type: "infinite",
      nextKey: "next_page",
      prevKey: "previous_page",
      pagesCountKey: "pages_count",
      itemsCountKey: "total_items_count",
      currentPageKey: "page_num",
    }
  );

  return (
    <div className={styles.container}>
      <div className={styles.section}>
        <div className={styles.sectionTitleRow}>
          <Text kind="h3" className="mt-0">
            Next EB Meetings and Events
          </Text>
          <Button kind="secondary" onClick={() => navigate(paths.construct.uploadMeeting())}>
            Add new
          </Button>
        </div>
        <HomeMeetingsTable fetcher={meetingsFetcher} items={meetingsFetcher.items} />
      </div>
      <div className={styles.section}>
        <div className={styles.sectionTitleRow}>
          <Text kind="h3" className="mt-0">
            Latest Documents
          </Text>
          <Button kind="secondary" onClick={() => navigate(paths.construct.uploadDoc())}>
            Add new
          </Button>
        </div>
        <HomeDocumentsTable fetcher={docsFetcher} items={docsFetcher.items} />
      </div>
    </div>
  );
});

export default Home;
