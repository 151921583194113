import React, { useEffect, useMemo } from "react";
import docTypeStore, { DocTypeNode, DocTypeId } from "~/stores/documentTypes";
import { observer } from "mobx-react";
import TreeSelect, { Props as TreeSelectProps, TreeOption } from "~/components/TreeSelect";

type SelectedDocTypes = Set<DocTypeId>;

interface OptionNode extends DocTypeNode {
  children: OptionNode[];
}

export interface Props {
  value: SelectedDocTypes;
  onChange: (value: Set<DocTypeId>) => void;
  maxOptionsToSelect?: number;
  selectProps?: Partial<TreeSelectProps<DocTypeId, OptionNode>>;
  invalid?: boolean;
}

type O = TreeOption<DocTypeNode["id"]>;

const docTypesArrayToOptions = (docTypes: DocTypeNode[]): O[] => {
  return docTypes.map((item: DocTypeNode) => {
    const res: O = {
      label: item.label,
      id: item.id,
      children: item.members ? docTypesArrayToOptions(item.members) : [],
    };
    return res;
  });
};

const getDocTypeParent = (id: DocTypeId, docTypes: DocTypeNode[]) => {
  return docTypes.find((parent) => parent.members.map((child) => child.id).includes(id));
};

const DocTypeSelect = observer((props: Props) => {
  const onSetValue = (newValue: Set<DocTypeId>) => {
    props.onChange(newValue);
  };
  const options = useMemo(() => {
    return docTypesArrayToOptions(docTypeStore.loadedTypes);
  }, [docTypeStore.loadedTypes]);

  const defaultCollapsedTypes = useMemo(() => {
    const defaultCollapsedTypesSet = new Set();
    Array.from(props.value).forEach((id) => {
      const parentId = getDocTypeParent(id, docTypeStore.loadedTypes)?.id;
      if (parentId) {
        defaultCollapsedTypesSet.add(parentId);
      }
    });
    return defaultCollapsedTypesSet;
  }, [props.value, docTypeStore.loadedTypes]);

  useEffect(() => {
    docTypeStore.loadDocTypes();
  }, []);

  return (
    <TreeSelect
      loading={docTypeStore.loading}
      options={options}
      selected={props.value}
      onChange={onSetValue}
      infoBoxText="Please select at least one document type (you may also select multiple document types)."
      maxOptionsToSelect={props.maxOptionsToSelect}
      byId={docTypeStore.docTypesById}
      highlevelSelect={false}
      invalid={props.invalid}
      defaultCollapsed={defaultCollapsedTypes}
      {...(props.selectProps || ({} as any))}
    />
  );
});

export default DocTypeSelect;
