import { useCallback, useMemo, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { AgendaItem, AgendaItemUnboundDocument } from "~/stores/agendaItems";
import { AGENDA_ITEM_DOC_DND } from "~/components/AvailableDocumentsField/AvailableDocumentsField";

const useAgendaItemDocDND = (agendaItem: AgendaItem) => {
  const [docDragging, setDocDragging] = useState<AgendaItemUnboundDocument>();
  const [docDraggingPosition, setDocDraggingPosition] = useState<number>();
  const containerRef = useRef<HTMLDivElement>(null);

  const onDocumentDrop = useCallback(
    (item) => {
      const doc = item as AgendaItemUnboundDocument;
      return {
        id: doc.id,
        oldAgendaItemId: doc.agendaitem || null,
        agendaItemId: agendaItem.id,
        position: docDraggingPosition,
      };
    },
    [agendaItem?.id, docDraggingPosition]
  );

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: AGENDA_ITEM_DOC_DND,
      drop: onDocumentDrop,
      hover: (item, monitor) => {
        const draggingY = monitor.getClientOffset().y;
        const containerY = containerRef.current.getClientRects()[0].y;
        const diff = draggingY - containerY;
        const docHeight = 110;
        if (diff < docHeight / 2) {
          setDocDraggingPosition(1);
          return;
        }
        let position = Math.floor(diff / docHeight) + 1;
        if (diff % docHeight >= docHeight / 2) {
          position += 1;
        }
        setDocDraggingPosition(position);
      },
      collect: (monitor) => {
        if (monitor.isOver()) {
          const item = monitor.getItem() as AgendaItemUnboundDocument;
          if (item.id !== docDragging?.id) {
            setDocDragging(item);
          }
        }
        return { isOver: monitor.isOver(), canDrop: monitor.canDrop() };
      },
    }),
    [agendaItem?.id, docDraggingPosition, containerRef]
  );

  const isDragAndDropActive = useMemo(() => canDrop && isOver, [canDrop, isOver]);

  return {
    docDragging,
    docDraggingPosition,
    isDragAndDropActive,
    drop,
    containerRef,
  };
};

export default useAgendaItemDocDND;
