import { observer } from "mobx-react";
import Select, { Props as SelectProps } from "react-select";
import { docInstanceLabel, DocInstance, DocInstanceId } from "~/document";

interface Props {
  value: DocInstanceId;
  onChange: (newValue: DocInstanceId) => void;
  selectProps?: SelectProps;
  className?: string;
}

const options = Object.entries(docInstanceLabel).map(([value, label]) => ({
  label,
  value,
}));

const DocInstanceSelect = observer((props: Props) => {
  return (
    <Select
      options={options}
      onChange={(newValue) => {
        const changed = newValue as { label: string; value: number };
        props.onChange(changed.value as number);
      }}
      value={Number.isNaN(+props.value) ? null : { value: props.value, label: docInstanceLabel[props.value] }}
      className={props.className}
      placeholder="Nothing selected"
      menuPosition={"fixed"}
      {...(props.selectProps || {})}
    />
  );
});

export default DocInstanceSelect;
