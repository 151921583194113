import { observer } from "mobx-react";
import { useDataFetcher } from "./common";
import DocsTable from "./DocsTable";
import { CommonTabProps } from "./TabTable";
import docStore from "~/stores/document";

const LastUpdatedDocsTab = observer((props: CommonTabProps) => {
  const fetcher = useDataFetcher(props.selected, docStore.loadMyLatestUpdatedDocs);
  return <DocsTable fetcher={fetcher} docsResult={fetcher.items} />;
});

export default LastUpdatedDocsTab;
