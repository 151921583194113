import Page from "~/components/Page";
import React, { useState, useEffect, useMemo } from "react";
import docStore from "~/stores/document";
import { docInstanceLabel, DocRelation, Document, DocVersion } from "~/document";
import { Empty, Icon, Modal, Loading, Button, Text, Tag } from "@wfp/ui";
import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import { Link, useParams, useNavigate, Navigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from "react-grid-system";
import paths from "~/paths";
import cn from "classnames";
import DocumentPreview from "~/components/DocumentPreview";
import ShareDocumentButton from "~/components/DocumentShareButton";
import DocumentDownloadButton from "~/components/DocumentDownloadButton";
import { NotFound, ReqStatus } from "~/api";
import { iconCheckmark } from "@wfp/icons";
import BackToSearchResultsButton from "~/components/BackToSearchResultsButton";
import DocBookmarkButton from "~/components/DocBookmarkButton";
import moment from "moment";
import { PUB_DATE_FORMAT } from "~/constants";
import { formatBytes, getFullDocTypes } from "~/util";
import docSeriesStore from "~/stores/docRelationTypes";
import languageStore from "~/stores/language";
import { splitMeetingCodes } from "~/pages/UploadPage";

interface IInfoItem {
  text: string;
  content?: React.ReactNode;
  innerHTML?: string;
}

interface DocumentInfoProps {
  document: Document;
}

const InfoItem = ({ item }: { item: IInfoItem }) => {
  return (
    <div className={styles.documentInfoItem}>
      <Text className={styles.documentInfoItemLabel}>
        <strong>{item.text}</strong>
      </Text>
      {!!item.content && <Text className="mt-12">{item.content}</Text>}
      {!!item.innerHTML && <div className="mt-12" dangerouslySetInnerHTML={{ __html: item.innerHTML }} />}
    </div>
  );
};

export const DocumentDetailPageRedirect = () => {
  const { id } = useParams();
  return <Navigate to={paths.construct.docDetail(id)} />;
};

const DocumentDeletionModal = observer((props: { document: Document; open: boolean; close: () => void }) => {
  const onDelete = async () => {
    await docStore.deleteDocument(props.document.protocol);
  };

  const content = (() => {
    switch (docStore.documentDeletionStatus) {
      case ReqStatus.Initial: {
        return (
          <>
            <Text kind="p">
              <strong>You are about to delete the document entry in the system, metadata and files.</strong>
            </Text>
            <Text kind="p">The action is not reversible. Are you sure you want to continue?</Text>
          </>
        );
      }
      case ReqStatus.InProcess: {
        return (
          <div className="df h-100 w-100 f-100 fac">
            <Loading className="loader loader_sm" withOverlay={false}></Loading>
            <Text className="fs-14 no-margin" kind="p">
              Deleting document...
            </Text>
          </div>
        );
      }
      case ReqStatus.Success: {
        return (
          <div className="df h-100 w-100 f-100 fac">
            <Icon icon={iconCheckmark} className="fill-success mr-8" width={24} height={24} description="Ok" />
            <Text className="fs-14 no-margin" kind="p">
              Successfully deleted the document
            </Text>
          </div>
        );
      }
      case ReqStatus.Failed: {
        return (
          <div className="df h-100 w-100 f-100 fac">
            <Text className="fs-14 no-margin" kind="p">
              Failed to delete the document
            </Text>
          </div>
        );
      }
      default: {
        return null;
      }
    }
  })();

  const deletingDocument = docStore.documentDeletionStatus === ReqStatus.InProcess;

  const footer = () => {
    switch (docStore.documentDeletionStatus) {
      case ReqStatus.Initial:
      case ReqStatus.InProcess: {
        return (
          <>
            <Button kind="secondary" onClick={props.close} disabled={deletingDocument}>
              Cancel
            </Button>
            <Button kind="primary" onClick={onDelete} disabled={deletingDocument}>
              I am sure
            </Button>
          </>
        );
      }
      case ReqStatus.Success: {
        return (
          <Link to="/">
            <Button kind="primary" onClick={props.close}>
              Done
            </Button>
          </Link>
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <Modal
      open={props.open}
      modalHeading="Delete document"
      modalLabel="Document management"
      onRequestClose={props.close}
      onRequestSubmit={onDelete}
      modalFooter={footer}
    >
      <div className={styles.deletionModal}>{content}</div>
    </Modal>
  );
});

const DocumentInfo = ({ document }: DocumentInfoProps) => {
  const documentLanguage = docStore.languageStore?.loadedLanguages[document.language]?.text;
  const [versions, setVersions] = useState<DocVersion[]>([]);
  useEffect(() => {
    (async () => {
      const res = await docStore.loadDocumentVersions(document.id);
      setVersions(res);
    })();
  }, []);

  const pdfOptionMapping: any = {
    "0": "PDF Not Allowed",
    "1": "PDF Allowed",
    "3": "PDF Preferred download type",
    "5": "Only allow pdf format",
  };
  const leftCol: IInfoItem[] = [
    {
      text: "Document language",
      content: documentLanguage || null,
    },
    { text: "Topics", content: document.topics_display ? document.topics_display : null },
    { text: "Document types", content: getFullDocTypes(document) },
    { text: "Geographical coverage", content: document.countries_display },
    { text: "Document owner", content: document.owner_display ? document.owner_display : null },
    { text: "Access", content: document.access_display ? document.access_display : null },
    { text: "Document symbol", content: document.number },
    {
      text: "Publication Date",
      content: document.publication_date ? moment(document.publication_date).format("YYYY-MM-DD") : null,
    },
    { text: "Release Date", content: document.release_date ? document.release_date : null },
    {
      text: "Expiration Date",
      content: document.expiration_date ? document.expiration_date : document.expiration_date === null ? "Never" : null,
    },
    { text: "Author", content: document.author ? document.author : null },
  ].filter((item) => !!item.content);
  const rightCol: IInfoItem[] = [
    { text: "Tags", content: document.tags_display ? document.tags_display.join(", ") : null },
    {
      text: "Document history",
      content:
        versions && versions.length !== 0 ? (
          <ul style={{ listStyle: "outside" }} className="ml-16">
            {versions.map((a, idx) => {
              const label = idx === 0 ? "Latest" : `Version ${idx}`;
              const timestamp = moment(a.last_modified_date).format(PUB_DATE_FORMAT);
              return (
                <li className="mb-8">
                  <a href={a.file}>{label}</a> <span>({timestamp})</span>
                </li>
              );
            })}
          </ul>
        ) : null,
    },
    {
      text: "Last Modified By",
      content: document.last_modified_user ? document.last_modified_user : null,
    },
    {
      text: "Meeting codes",
      content: document.metadata?.ebmeetingdocs?.meeting_codes
        ? splitMeetingCodes(document.metadata.ebmeetingdocs.meeting_codes).join(", ")
        : null,
    },
    {
      text: "Instance",
      content: document.metadata?.ebmeetingdocs?.document_instance?.label
        ? docInstanceLabel[document.metadata.ebmeetingdocs.document_instance.value]
        : null,
    },
    {
      text: "File Size",
      content: document.file_info?.size ? formatBytes(parseInt(document.file_info.size)) : null,
    },
    {
      text: "File Type",
      content: document.file_info ? document.file_info.ext : null,
    },
    {
      text: "PDF Option",
      content: document.pdf_option || document.pdf_option === 0 ? pdfOptionMapping[document.pdf_option] : null,
    },
    { text: "Document ID", content: document.protocol },
    { text: "Note", content: document.comment ? document.comment : null },
    { text: "Summary", innerHTML: document.metadata?.ebmeetingdocs?.summary || null },
    { text: "Decisions and recommendations", innerHTML: document.metadata?.ebmeetingdocs?.decision || null },
    { text: "Disclaimer", innerHTML: document.metadata?.ebmeetingdocs?.disclaimer || null },
    { text: "Comment", innerHTML: document.metadata?.ebmeetingdocs?.comment || null },
  ].filter((item) => !!item.content || !!item.innerHTML);
  return (
    <div className={styles.documentInfo}>
      {!!document.trans_group.length && (
        <div className={styles.documentTranslation}>
          <div className={styles.documentTranslationLabel}>Document translations</div>
          {document.trans_group.map((langVersion) => (
            <div className="mb-4">
              <Tag type="info" className="mr-12">
                {languageStore.idToText(langVersion.language)}
              </Tag>
              <Link to={paths.construct.docDetail(langVersion.protocol)} key={`lang-version-${langVersion.uuid}`}>
                {langVersion.title}
              </Link>
            </div>
          ))}
        </div>
      )}
      <Container className={styles.search} style={{ paddingLeft: 0, paddingRight: 0 }} fluid>
        <Row>
          <Col xs={7}>
            {leftCol.map((item) => (
              <InfoItem item={item} />
            ))}
          </Col>
          <Col xs={5}>
            {rightCol.map((item) => (
              <InfoItem item={item} />
            ))}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

const DocumentRelations = observer(({ related }: { related: DocRelation[] }) => {
  useEffect(() => {
    docSeriesStore.f.load("", null);
  }, []);
  return (
    !!docSeriesStore.f.itemsList.length &&
    !!related.length && (
      <div className={styles.documentRelatedWrapper}>
        {related.map((relatedDoc) => {
          const relation = docSeriesStore.f.items[relatedDoc.type];
          const relationType = relatedDoc.is_owner ? relation.left_to_right : relation.right_to_left;
          return (
            <div className={styles.documentRelatedItem}>
              <Tag type="warning">{relationType}</Tag>
              <Link
                className={styles.documentRelatedItemLink}
                to={paths.construct.docDetail(relatedDoc.other.protocol)}
              >
                {relatedDoc.other.title}
              </Link>
            </div>
          );
        })}
      </div>
    )
  );
});

const DocumentDetailPage = observer(() => {
  const params = useParams();
  const navigate = useNavigate();
  const title = useMemo(() => {
    if (docStore.currentDocument === null) return "Document detail";
    return docStore.currentDocument.title;
  }, [docStore.currentDocument]);

  const [showDocumentDeletionDialog, setShowDocumentDeletionDialog] = useState<boolean>(false);
  const startDeletion = () => setShowDocumentDeletionDialog(true);
  const [notFound, setNotFound] = useState(false);
  const proto = params.id!;

  useEffect(() => {
    (async () => {
      try {
        await docStore.selectDocument(proto);
      } catch (err) {
        if (err instanceof NotFound) {
          setNotFound(true);
        }
      }
    })();
  }, [proto]);

  if (notFound) {
    return (
      <Page title="Document not found">
        <Empty className={styles.noResultsMsg} title="Not found">
          Document "{proto}" doesn't exist. <Link to={paths.construct.browse()}>Go to the Home page</Link>
        </Empty>
      </Page>
    );
  }

  if (docStore.currentDocument === null || docStore.loadingDocument) {
    return (
      <Page title="Loading..." className={styles.page}>
        <div className="df h-100 w-100 pv-16 fc">
          <Loading className="loader loader_sm" withOverlay={false}>
            Text
          </Loading>
        </div>
      </Page>
    );
  }

  const document = docStore.currentDocument;

  const expired = docStore.isExpired(docStore.currentDocument);
  const canEdit = docStore.canEdit(docStore.currentDocument);
  const canDownload = docStore.canDownload(docStore.currentDocument);
  const canEditRelations = docStore.canEditRelations();
  const canEditLanguages = docStore.canEditLanguages();
  const canDeleteDocument = docStore.canDeleteDocument();

  return (
    <Page title={title} className={styles.page}>
      {expired && <div className="document-expired-banner">Expired</div>}
      <DocumentDeletionModal
        document={document}
        open={showDocumentDeletionDialog}
        close={() => setShowDocumentDeletionDialog(false)}
      />
      <div className={styles.container}>
        <div className={styles.docInfoWrapper}>
          <div className="mb-24">
            <BackToSearchResultsButton />
          </div>
          <div className={styles.content}>
            <div className={cn(["pb-16", styles.documentViewTitleWrapper])}>
              <div className={cn(["tal", styles.documentViewTitle])}>
                <div className="df fac mb-8">
                  <Text kind="h3" className="dib mt-0">
                    {document.title}
                  </Text>
                </div>
                <div>{document.abstract}</div>
              </div>
            </div>

            <div className="mb-12">
              <ShareDocumentButton doc={document} className={styles.docAction} />
              {canDownload && (
                <>
                  <DocumentDownloadButton doc={document} className={styles.docAction} />
                  <DocBookmarkButton doc={document} className={styles.docAction} />
                </>
              )}
            </div>

            <div style={{ maxWidth: 800 }}>
              <div>
                <DocumentInfo document={docStore.currentDocument} />
              </div>
              <DocumentRelations related={document.related} />
            </div>
            <div className="fspace" />
            <div className={styles.actions}>
              <Button
                className={styles.docAction}
                disabled={!canEdit}
                kind="primary"
                onClick={() => navigate(paths.construct.editDocument(document.protocol))}
              >
                Edit
              </Button>
              <Link
                to={paths.construct.uploadSimilar(document.protocol)}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.docAction}
              >
                <Button kind="secondary">Upload similar</Button>
              </Link>
              <Link
                to={paths.construct.editDocumentRelations(document.protocol)}
                target="_blank"
                className={cn(styles.docAction, ["disabled-link", canEditRelations])}
                rel="noopener noreferrer"
              >
                <Button kind="secondary" disabled={!canEditRelations}>
                  Relations
                </Button>
              </Link>
              <Link
                to={paths.construct.editDocumentLangVersions(document.protocol)}
                target="_blank"
                className={cn(styles.docAction, ["disabled-link", canEditLanguages])}
                rel="noopener noreferrer"
              >
                <Button kind="secondary" disabled={!canEditLanguages}>
                  Languages
                </Button>
              </Link>
              <Button
                className={styles.docAction}
                kind="danger--primary"
                onClick={startDeletion}
                disabled={!canDeleteDocument}
              >
                Delete document
              </Button>
            </div>
          </div>
        </div>
        <div className={styles.docPreviewWrapper}>
          <DocumentPreview doc={document} className={styles.documentPreview} />
        </div>
      </div>
    </Page>
  );
});

export default DocumentDetailPage;
