import notificationStore, { NotificationStore } from "~/stores/notifications";
import { ReqStatus } from "~/util";
import { makeAutoObservable, runInAction } from "mobx";
import { MeetingId, MeetingLanguage } from "~/stores/meeting";
import * as api from "~/api";
import { AgendaItemDocument } from "~/stores/agendaItemDocs";
import uuid from "react-uuid";

export type AgendaItemId = number;

export const AgendaItemStatus = {
  draft: "draft",
  public: "published",
  archived: "archived",
};

const agendaItemStatusKeys = Object.keys(AgendaItemStatus);

export type AgendaItemHistory = {
  action: string;
  actor: string;
  changes: string;
  timestamp: string;
};

export type AgendaItemUnboundDocument = {
  api_url: string;
  docitem: string;
  id: number;
  language: MeetingLanguage;
  language_display: string;
  protocol: string;
  release_date: string;
  symbol: string;
  title: string;
  agendaitem?: number;
};

export type AgendaItemGroup = "top" | "numbered" | "bottom";

export type AgendaItem = {
  id: number;
  api_url: string;
  group: AgendaItemGroup;
  position: number;
  title_en: string;
  title_fr?: string;
  title_es?: string;
  title_ar?: string;
  notes_en?: string;
  notes_fr?: string;
  notes_es?: string;
  notes_ar?: string;
  status: (typeof agendaItemStatusKeys)[number];
  history: AgendaItemHistory[];
  meeting: MeetingId;
  meetingdocs: AgendaItemDocument[];
};

export class AgendaItemStore {
  notifStore: NotificationStore;
  status: ReqStatus = ReqStatus.Initial;
  agendaItemsByMeeting: { [key: MeetingId]: AgendaItem[] } = {};

  get loading(): boolean {
    return this.status === ReqStatus.InProcess;
  }

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.agendaItemsByMeeting = {};
    this.notifStore = notifStore;
  }

  loadAgendaItems = async (meetingId: MeetingId) => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const resp = await api.get(`ebms:agendaitems`, { meeting: meetingId });
      const docs = resp!.data;
      runInAction(() => {
        this.status = ReqStatus.Success;
        this.agendaItemsByMeeting[meetingId] = docs || [];
      });
      return docs;
    } catch (err) {
      this.notifStore.error("Failed to load agenda items");
      this.status = ReqStatus.Failed;
      return [];
    }
  };

  patchAgendaItem = async (agendaItem: Partial<AgendaItem>) => {
    try {
      runInAction(() => {
        this.status = ReqStatus.InProcess;
      });
      const { id, ...payload } = agendaItem;
      const resp = await api.patch(`ebms:agendaitems/${id}`, payload);
      const updatedAgendaItem = resp!.data;
      const updatedAgendaItemsForMeeting = this.agendaItemsByMeeting[updatedAgendaItem.meeting].map((item) =>
        item.id === updatedAgendaItem.id ? updatedAgendaItem : item
      );
      runInAction(() => {
        this.status = ReqStatus.Success;
        // this.agendaItemsByMeeting[updatedAgendaItem.meeting] = updatedAgendaItemsForMeeting;
      });
      return updatedAgendaItem;
    } catch (err) {
      this.notifStore.error("Failed to patch agenda item");
      this.status = ReqStatus.Failed;
      return null;
    }
  };

  deleteAgendaItem = async (agendaItemId: number) => {
    try {
      runInAction(() => {
        this.status = ReqStatus.InProcess;
      });
      const resp = await api.del(`ebms:agendaitems/${agendaItemId}`);
      runInAction(() => {
        this.status = ReqStatus.Success;
      });
    } catch (err) {
      this.notifStore.error("Failed to patch agenda item");
      this.status = ReqStatus.Failed;
      return null;
    }
  };

  postAgendaItem = async (agendaItem: Partial<AgendaItem>) => {
    try {
      runInAction(() => {
        this.status = ReqStatus.InProcess;
      });
      const resp = await api.post(`ebms:agendaitems`, agendaItem);
      const item = resp!.data;
      runInAction(() => {
        this.status = ReqStatus.Success;
      });
      return item;
    } catch (err) {
      this.notifStore.error("Failed to post agenda item");
      this.status = ReqStatus.Failed;
      return null;
    }
  };
}

const agendaItemStore = new AgendaItemStore(notificationStore);

export default agendaItemStore;
