import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import agendaItemStore, { AgendaItem, AgendaItemGroup, AgendaItemStatus } from "~/stores/agendaItems";
import { MeetingId, MeetingLanguage } from "~/stores/meeting";
import { useLocalizedFieldsGetter } from "~/util/hooks";
import styles from "./styles.module.scss";
import { Button, InlineLoading, Link, Modal, Tag } from "@wfp/ui";
import agendaItemDocsStore from "~/stores/agendaItemDocs";
import AgendaItemModal from "~/components/AgendaItems/AgendaItemModal/AgendaItemModal";
import cn from "classnames";
import { ReqStatus } from "~/util";
import AgendaItemDoc from "~/components/AgendaItems/AgendaItem/AgendaItemDoc";
import useAgendaItemDND, {
  CountAgendaItemPosition,
  MoveAgendaItem,
} from "~/components/AgendaItems/AgendaItem/useAgendaItemDND";
import { ToastNotificationContext } from "~/components/ToastNotificationContext";
import useAgendaItemDocDND from "~/components/AgendaItems/AgendaItem/useAgendaItemDocDND";
import { AgendaItemsDNDContext } from "~/components/AgendaItems/AgendaItem/AgendaItemsDNDWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
  lang: MeetingLanguage;
  meetingId: MeetingId;
  agendaItem: AgendaItem;
  moveAgendaItem: MoveAgendaItem;
  countAgendaItemPosition: CountAgendaItemPosition;
  availableDocsIds: number[];
  index: number;
};

const AgendaItemComponent = observer(
  ({ lang, meetingId, moveAgendaItem, countAgendaItemPosition, agendaItem, availableDocsIds, index }: Props) => {
    const getLocalizedField = useLocalizedFieldsGetter(lang);
    const [editingModalOpened, setEditingModalOpened] = useState(false);
    const [addDocumentModalOpened, setAddDocumentModalOpened] = useState(false);
    const [shouldCollapseRelatedDocs, setShouldCollapseRelatedDocs] = useState(false);

    const { showNotificationWithTimeout, setNotificationData } = useContext(ToastNotificationContext);

    const toggleCollapseRelatedDocs = () => {
      setShouldCollapseRelatedDocs((prev) => !prev);
    };
    const showEditingModal = () => setEditingModalOpened(true);
    const hideEditingModal = () => setEditingModalOpened(false);

    const docs = agendaItemDocsStore.documentsByMeeting[meetingId] || [];
    const availableDocs = docs.filter(({ id }) => availableDocsIds.includes(id));

    const hideAddDocumentModal = () => {
      setAddDocumentModalOpened(false);
    };
    const showAddDocumentModal = () => {
      setAddDocumentModalOpened(true);
    };

    const addDocToAgendaItem = useCallback(
      (docId) => {
        agendaItemDocsStore.addDocItem(docId, agendaItem.id, meetingId).finally(hideAddDocumentModal);
      },
      [agendaItem, meetingId]
    );

    const reloadAgendaItems = () => agendaItemStore.loadAgendaItems(meetingId);

    const editingCallback = () => {
      reloadAgendaItems().finally(hideEditingModal);
    };

    const deleteAgendaItem = () => {
      agendaItemStore.deleteAgendaItem(agendaItem.id).then(reloadAgendaItems);
    };

    useEffect(() => {
      agendaItemDocsStore.loadRelatedDocuments(agendaItem.id);
    }, [agendaItem.id]);

    const { setDNDActive, shouldCollapseAllDocs } = useContext(AgendaItemsDNDContext);
    const agendaItemDrag = useAgendaItemDND(
      agendaItem,
      index,
      async (position: number, group: AgendaItemGroup) => {
        const res = await agendaItemStore.patchAgendaItem({ id: agendaItem.id, position, group });
        if (!res) {
          setNotificationData({
            title:
              "Your action could not be completed due to an API error. Please try again later or contact the it.servicedesk@wfp.org for support.",
            kind: "error",
          });
          showNotificationWithTimeout();
        }
        await agendaItemStore.loadAgendaItems(meetingId);
      },
      moveAgendaItem,
      countAgendaItemPosition
    );

    useEffect(() => {
      setDNDActive(agendaItemDrag.isDragging);
    }, [agendaItemDrag.isDragging]);

    useEffect(() => {
      setShouldCollapseRelatedDocs(shouldCollapseAllDocs);
    }, [shouldCollapseAllDocs]);

    const agendaItemDocDrag = useAgendaItemDocDND(agendaItem);

    const sortedRelatedDocs = useMemo(() => {
      const docs = agendaItemDocsStore.documentsByAgendaItem[agendaItem.id];
      if (!docs) return [];
      return [...docs]
        .sort((a, b) => (a.position > b.position ? 1 : -1))
        .filter((doc) => {
          return !(doc.id === agendaItemDocDrag.docDragging?.id && agendaItemDocDrag.isDragAndDropActive);
        });
    }, [
      agendaItemDocsStore.documentsByAgendaItem,
      agendaItemDocDrag.docDragging,
      agendaItemDocDrag.isDragAndDropActive,
    ]);

    const shouldShowPlaceholder = agendaItemDocDrag.isDragAndDropActive && !!agendaItemDocDrag.docDragging;

    return (
      <div
        ref={agendaItemDrag.ref}
        style={{ opacity: agendaItemDrag.isDragging ? 0 : 1 }}
        data-handler-id={agendaItemDrag.handlerId}
      >
        <div className={styles.agenda}>
          <div className={styles.agendaHeader}>
            <div>
              {shouldCollapseRelatedDocs ? (
                <FontAwesomeIcon className="caret-icon" icon={solid("caret-up")} onClick={toggleCollapseRelatedDocs} />
              ) : (
                <FontAwesomeIcon
                  className="caret-icon"
                  icon={solid("caret-down")}
                  onClick={toggleCollapseRelatedDocs}
                />
              )}
              <span className={styles.agendaHeaderTitle} data-testid={`agenda-item-${agendaItem.title_en}`}>
                {agendaItem.group === "numbered" && agendaItem.position ? `${agendaItem.position}. ` : ""}
                {agendaItem[getLocalizedField("title")]}
              </span>
            </div>
            <div className={styles.agendaHeaderActions}>
              {(agendaItemDocsStore.agendaStatusById[agendaItem.id] === ReqStatus.InProcess ||
                agendaItemDocsStore.documentsStatus === ReqStatus.InProcess) && <InlineLoading />}
              {agendaItem.status === "public" && <Tag type="info">{AgendaItemStatus.public}</Tag>}
              {agendaItem.status === "draft" && <Tag type="warning">{AgendaItemStatus.draft}</Tag>}
              <Button small kind="secondary" onClick={showEditingModal}>
                Edit
              </Button>
              <Button small kind="secondary" onClick={deleteAgendaItem}>
                Delete
              </Button>
            </div>
          </div>
          <div ref={agendaItemDocDrag.containerRef} style={{ position: "relative" }}>
            <div
              className={cn(styles.agendaBody, { [styles.agendaBodyActive]: agendaItemDocDrag.isDragAndDropActive })}
              ref={agendaItemDocDrag.drop}
            >
              {!shouldCollapseRelatedDocs &&
                !!sortedRelatedDocs.length &&
                sortedRelatedDocs.map((doc, index) => (
                  <div key={`${doc.id}-${agendaItem.id}-doc`}>
                    {shouldShowPlaceholder && index + 1 === agendaItemDocDrag.docDraggingPosition && (
                      <div style={{ background: "#eff2f5", height: 110 }}></div>
                    )}
                    <AgendaItemDoc
                      isLast={sortedRelatedDocs.length === index + 1}
                      doc={doc}
                      agendaItemId={agendaItem.id}
                    />
                  </div>
                ))}
              {!shouldCollapseRelatedDocs && !sortedRelatedDocs.length && (
                <div className={styles.agendaBodyEmpty}>Drag documents in this area to move and shuffle them</div>
              )}
              {shouldCollapseRelatedDocs && (
                <div className={styles.agendaBodyEmpty}>
                  {sortedRelatedDocs.length} {sortedRelatedDocs.length !== 1 ? "documents" : "document"} collapsed
                </div>
              )}
              {shouldShowPlaceholder && sortedRelatedDocs.length + 1 === agendaItemDocDrag.docDraggingPosition && (
                <div style={{ background: "#eff2f5", height: 110 }}></div>
              )}
            </div>
          </div>
          <div className={styles.agendaFooter}>
            <Link onClick={showAddDocumentModal}>Add document</Link>
          </div>
          <Modal
            open={addDocumentModalOpened}
            onRequestClose={hideAddDocumentModal}
            onRequestSubmit={hideAddDocumentModal}
            modalHeading="Select a document to add"
            primaryButtonText="Close"
            primaryButtonDisabled={agendaItemDocsStore.loading}
          >
            <div>
              {(!Array.isArray(docs) || agendaItemDocsStore.loading) && <InlineLoading size={40} />}
              {Array.isArray(availableDocs) &&
                !!availableDocs.length &&
                availableDocs.map((doc) => (
                  <div className={styles.document} key={`doc-${doc.id}`}>
                    <div>{doc.title}</div>
                    <div>{doc.release_date}</div>
                    <div>{doc.language_display}</div>
                    <Link onClick={() => addDocToAgendaItem(doc.id)}>Add</Link>
                  </div>
                ))}
            </div>
          </Modal>
          <AgendaItemModal
            defaultAgendaItem={{ ...agendaItem, meeting: meetingId }}
            open={editingModalOpened}
            meetingId={meetingId}
            onRequestClose={hideEditingModal}
            onRequestSubmit={editingCallback}
            isEditing
          />
        </div>
      </div>
    );
  }
);

export default AgendaItemComponent;
