import { Document } from "~/document";
import { Checkbox, Link, Text, Item, Tag } from "@wfp/ui";
import { observer } from "mobx-react";
import { documentDownloadLink, docFileSize, FileExtension, Access } from "~/document";
import omit from "lodash/omit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import styles from "./styles.module.scss";
import cn from "classnames";
import bulkDownloader from "~/stores/bulkDownloader";
import React, { useMemo } from "react";
import moment from "moment";
import { DOC_PUB_DATE_FORMAT } from "~/document";
import mime from "mime-types";
import { getFullDocTypes } from "~/util";
import documentStore from "~/stores/document";

type ItemProps = any;

const fileAccessLevelToIconMapping: Record<Access, string | null> = {
  [Access.Public]: null,
  [Access.LoginRequired]: null,
  [Access.Restricted]: null,
};

const accessLevelToIcon = (accessLevel: Access) => {
  return fileAccessLevelToIconMapping[accessLevel] ?? null;
};

const fileTypeToIconMapping: Record<FileExtension, string> = {
  ".doc": regular("file-word"),
  ".docx": regular("file-word"),
  ".xls": regular("file-excel"),
  ".xlsx": regular("file-excel"),
  ".csv": solid("file-csv"),
  ".odt": regular("file-powerpoint"),
  ".pdf": regular("file-pdf"),
  ".jpg": regular("file-image"),
  ".jpeg": regular("file-image"),
  ".png": regular("file-image"),
  ".ppt": regular("file-powerpoint"),
  ".pptx": regular("file-powerpoint"),
  ".txt": regular("file-lines"),
  ".md": regular("file-lines"),
};

const fileTypeToIcon = (ext: FileExtension) => {
  return fileTypeToIconMapping[ext] ?? solid("file");
};

const iconDim = { width: "28", height: "38" };

const UnknownFileTypeIcon = () => {
  return <FontAwesomeIcon {...iconDim} icon={regular("file")} />;
};

const getFileExt = (doc: Document): string | null => {
  if (doc.file_info.ext && doc.file_info.ext.length !== 0) {
    return doc.file_info.ext;
  }
  if (doc.file_info.mimetype) {
    const e = mime.extension(doc.file_info.mimetype);
    if (e) return e;
  }
  return null;
};

const DocIcon = (props: { doc: Document }) => {
  const { doc } = props;
  const renderedIcon = useMemo(() => {
    const ext = getFileExt(doc);
    if (ext && fileTypeToIconMapping[ext]) {
      const mappedIconName = fileTypeToIcon(ext);
      return <FontAwesomeIcon {...iconDim} icon={mappedIconName as any} />;
    }
    return <UnknownFileTypeIcon />;
  }, [doc.file_info.ext]);
  const fsz = docFileSize(doc);
  const languagesRendered = useMemo(() => {
    let res: string[] = [doc.language.toUpperCase()];
    if (doc.translations) {
      for (let lang of doc.translations) {
        res.push(lang[0].toUpperCase());
      }
    }
    return res.length >= 4 ? res.slice(0, 4).join("/") + "..." : res.join("/");
  }, [doc.language_display, doc.translations]);
  return (
    <div className={styles.itemFileInfoCol}>
      <div className={styles.itemFileInfo}>
        <div className={styles.itemIcon}>{renderedIcon}</div>
        {fsz && <strong className="mt-12">{fsz}</strong>}
      </div>
      <div className={cn([styles.itemLang, "mt-12"])}>{languagesRendered}</div>
    </div>
  );
};

const infoItems = Object.entries({
  type_display: "Document types",
  countries_display: "Geographical coverage",
  protocol: "Document ID",
  number: "Document Symbol",
});

const accessLevelTitle = {
  // [Access.Restricted]: "Restricted",
  [Access.LoginRequired]: "Login required",
  [Access.Public]: "Public",
};

const DocumentAccessTag = (props: { doc: Document }) => {
  const { doc } = props;
  const accessLevelIconName = accessLevelToIcon(doc.access);
  const accessLevelIcon =
    accessLevelIconName !== null ? (
      <FontAwesomeIcon width={12} height={12} title={doc.access_display} icon={accessLevelIconName as any} />
    ) : null;
  if (accessLevelIcon) {
    return (
      <Tag type="warning" kind="wfp" className="fs-14">
        {accessLevelIcon}
        <Text className="ml-4 fs-12">{doc.access_display ?? accessLevelTitle[doc.access]}</Text>
      </Tag>
    );
  }
  return null;
};

type Props = { doc: Document; onDocumentClick: () => void } & ItemProps;

const DocItem = observer((props: Props) => {
  const doc = props.doc as Document;

  const subContentItems = infoItems.filter(([key, title]) => !!doc[key]);
  const bulkDownloadSelected = bulkDownloader.isBulkDownloadSelected(doc.protocol);

  const expired = documentStore.isExpired(doc);
  const canDownload = documentStore.canDownload(doc);

  const subContent = (
    <div className={styles.subContent}>
      <div>
        {doc.abstract?.length !== 0 && <div className="mb-8">{doc.abstract}</div>}
        <div className="mb-12">
          {subContentItems.map(([key, title]) => (
            <div className={styles.infoItem} key={key}>
              <span style={{ fontWeight: 600 }}>{title}: </span>
              <span>{key == "type_display" ? getFullDocTypes(doc) : doc[key]}</span>
            </div>
          ))}
        </div>
      </div>
      {canDownload && (
        <div className={cn([styles.bulkDownloadCheckbox, "no-select"])}>
          <Checkbox
            id={`doc-item-bd-${doc.protocol}`}
            title="Bulk download"
            labelText="Add to bulk download"
            disabled={!canDownload}
            checked={bulkDownloadSelected}
            onChange={(_, checked) => {
              bulkDownloader.setBulkDownloadSelected(doc.protocol, checked);
            }}
          />
        </div>
      )}
    </div>
  );

  const itemPropsRemaining = omit(props, "onDocumentClick", "doc");

  return (
    <div className={styles.item}>
      {expired && <div className={styles.itemExpiredBanner}>Expired</div>}
      <div className="df">
        <Item
          image={<DocIcon doc={doc} />}
          key={doc.id}
          kind="horizontal"
          style={{ padding: 0 }}
          wrapper="sidebar"
          className={cn({ [props.className]: true, [styles.itemInner]: true, docItem: true })}
          additional={
            <div className="df fdr fvc tac">
              <DocumentAccessTag doc={doc} />
            </div>
          }
          hint={
            <>
              {doc.publication_date && (
                <div className="df fdrr fs-12">
                  <div className="df fdc tar">
                    <div className="mb-4 text-02">published on</div>
                    <div>{moment(new Date(doc.publication_date)).format(DOC_PUB_DATE_FORMAT)}</div>
                  </div>
                </div>
              )}
            </>
          }
          subContent={subContent}
          title={
            <div className={styles.title}>
              <Link
                title="Open document details"
                className="mr-8 black-link"
                target="_blank"
                onClick={props.onDocumentClick}
              >
                {doc.title}
              </Link>
              {canDownload && (
                <Link
                  className="icon-link fs-12 bold vam"
                  href={documentDownloadLink(doc)}
                  target="blank_"
                  title="Download document"
                >
                  <FontAwesomeIcon icon={solid("download")} />
                </Link>
              )}
            </div>
          }
          {...itemPropsRemaining}
        >
          <div className="mb-6">{doc.topics_display}</div>
        </Item>
      </div>
    </div>
  );
});

export default DocItem;
