import React, { useContext, useEffect, useMemo } from "react";
import docStore from "~/stores/document";
import { Document } from "~/document";
import { Pagination, Text, Loading, Empty } from "@wfp/ui";
import styles from "./styles.module.scss";
import cn from "classnames";
import DocItem from "~/components/DocItem";
import { observer } from "mobx-react";
import Select from "react-select";
import DocumentsContext from "./DocumentsContext";
import { PaginationInfo } from "~/types";

type SortOption = { label: string; value: keyof Document };

const sortOptions: SortOption[] = [
  { label: "Sort by time", value: "publication_date" },
  { label: "Sort by expiration", value: "expiration_date" },
];

const sortOptionsMap: Record<keyof Document, SortOption> = sortOptions.reduce(
  (acc, item: SortOption) => ({ ...acc, [item.value]: item }),
  {} as any
);

const SearchResultsList = observer(() => {
  const { onDocumentClick, searchQuery, onSearch } = useContext(DocumentsContext);

  useEffect(() => {
    return () => {
      // reset search list on unmount
      docStore.clearSearch();
    };
  }, []);

  const items: Document[] = docStore.searchResult.results;
  const renderedItems = useMemo(() => {
    if (docStore.searching) {
      return (
        <div className="df h-100 w-100 fc">
          <Loading className="loader loader_sm" withOverlay={false}>
            Text
          </Loading>
        </div>
      );
    }
    if (!items || items.length === 0) {
      return (
        <Empty className={styles.noResultsMsg} title="Nothing here">
          Search returned no results
        </Empty>
      );
    }

    const currItemId = docStore.currentDocument !== null ? docStore.currentDocument.protocol : null;

    return (
      <div className={styles.searchResultContainer}>
        {items.map((item) => {
          const isSelected = currItemId !== null && item.protocol === currItemId;
          return (
            <DocItem
              kind="horizontal"
              wrapper="sidebar"
              doc={item}
              onDocumentClick={() => onDocumentClick(item)}
              className={cn({
                [styles.sidebarItem]: true,
                [styles.sidebarItem_active]: isSelected,
              })}
            />
          );
        })}
      </div>
    );
  }, [items, docStore.searching, docStore.currentDocument]);

  const resultsFrom = (docStore.searchResult.current_page - 1) * docStore.searchResult.page_size;
  const resultsTo = docStore.searchResult.current_page * docStore.searchResult.page_size;
  const resultsTotal = docStore.searchResult.count;

  const onPaginationChange = (pag: PaginationInfo) => {
    onSearch(searchQuery.set("page", pag.page).set("pageSize", pag.pageSize));
  };

  return (
    <div className={styles.leftColContent}>
      <div className={styles.searchResultsHeader}>
        <Select
          className={styles.sorter}
          options={sortOptions as any}
          value={(sortOptionsMap as any)[searchQuery!.sortByField]}
          menuPortalTarget={document.body}
          menuPosition={"fixed"}
          onChange={(option) => onSearch(searchQuery.set("sortByField", option!.value))}
        />
        {!!docStore.searchResult.current_page && (
          <i className="text-02 fs-12 text-italic">
            Showing {resultsFrom}-{resultsTo} of {resultsTotal} results
          </i>
        )}
      </div>
      <div className="f-100">{renderedItems}</div>
      <Pagination
        onChange={onPaginationChange}
        pageSizes={[25]}
        totalItems={resultsTotal}
        page={searchQuery.page}
        pageSize={searchQuery.pageSize}
      />
    </div>
  );
});

export default SearchResultsList;
