import { makeAutoObservable } from "mobx";
import axios from "axios";
import * as api from "~/api";
import notifStore, { NotificationStore } from "~/stores/notifications";
import { LangId } from "~/document";
import { ReqStatus } from "~/util";

export type { LangId };

export type Language = string;
export interface LanguageInfo {
  id: LangId;
  text: string;
}

export class LanguageStore {
  loadedLanguages: Record<LangId, LanguageInfo> = {};
  notifStore: NotificationStore;
  reqStatus: ReqStatus = ReqStatus.Initial;

  get loading(): boolean {
    return this.reqStatus === ReqStatus.InProcess;
  }

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.notifStore = notifStore;
  }

  idToText = (id: LangId): string => {
    if (id in this.loadedLanguages) {
      return this.loadedLanguages[id].text;
    }
    return "N/A";
  };

  async loadLanguages() {
    // only load once
    if (this.reqStatus !== ReqStatus.Initial) return;
    this.reqStatus = ReqStatus.InProcess;
    try {
      const resp = await api.get("languages");
      if (resp === null) return;
      const languages: LanguageInfo[] = resp.data.results;
      this.loadedLanguages = languages.reduce((acc, lang) => ({ ...acc, [lang.id]: lang }), {});
      this.reqStatus = ReqStatus.Success;
    } catch (err) {
      this.notifStore.error("Failed to load available languages");
      this.reqStatus = ReqStatus.Failed;
    }
  }
}

const languageStore = new LanguageStore(notifStore);

export default languageStore;
