import React from 'react';
import { observer } from "mobx-react";
import { SelectOption } from "~/types";
import languageStore, { LangId, LanguageInfo } from "~/stores/language";
import Select, { Props as SelectProps } from "react-select";
import cn from "classnames";
import { useEffect } from "react";

interface Props {
  value: LangId[];
  onChange: (newValue: LangId[]) => void;
  selectProps?: SelectProps;
  className?: string;
  filter?: (item: LanguageInfo) => boolean;
}

const LanguageSelect = observer((props: Props) => {
  const filter = props.filter ?? ((item) => true);
  const options: SelectOption[] = Object.values(languageStore.loadedLanguages)
    .filter(filter)
    .map((lang) => ({
      value: lang.id,
      label: lang.text,
    }));
  const selectedOptions = props.value.map((a) => {
    if (!languageStore.loadedLanguages[a]) return null;
    return {
      value: a,
      label: languageStore.loadedLanguages[a].text,
    };
  });
  useEffect(() => {
    languageStore.loadLanguages();
  }, []);
  return (
    <Select
      options={options}
      isMulti
      onChange={(options) => {
        if (options instanceof Array) {
          props.onChange(options.map((a) => a.value));
        } else {
          props.onChange([(options as any).value]);
        }
      }}
      value={selectedOptions}
      className={props.className}
      {...(props.selectProps || {})}
    />
  );
});

export default LanguageSelect;
