import React from "react";
import TabsPageView from "~/components/TabsPageView";
import MeetingsTab from "~/pages/Meetings/MeetingsTab";
import { meetingLanguagesList } from "~/stores/meeting";

const MeetingsPage = () => {
  return (
    <TabsPageView
      breadcrumbsItems={[{ label: "Meetings" }]}
      tabs={meetingLanguagesList.map((lang) => ({
        label: lang.title,
        id: lang.key,
        render: (selected) => (selected ? <MeetingsTab key={`meetings-${lang.key}`} lang={lang.key} selected /> : null),
      }))}
      pageTitle="Meetings"
      withTabContentBackground={false}
    />
  );
};

export default MeetingsPage;
