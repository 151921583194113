import { makeAutoObservable } from "mobx";

export enum NotifType {
  Error = 0,
  Success = 1,
  Info = 2,
}

export interface Notification {
  id: number;
  type: NotifType;
  message: string;
}

export class NotificationStore {
  notifications: Notification[] = [];
  __lastNotifId: number = 0;

  constructor() {
    makeAutoObservable(this);
  }

  get visibleNotifications(): Notification[] {
    return this.notifications;
  }

  addNotif = (notif: Omit<Notification, "id">) => {
    this.notifications.push({ ...notif, id: this.__lastNotifId });
    ++this.__lastNotifId;
  };

  error = (message: string) => {
    this.addNotif({ type: NotifType.Error, message });
  };

  success = (message: string) => {
    this.addNotif({ type: NotifType.Success, message });
  };
}

const notificationStore = new NotificationStore();

export default notificationStore;
