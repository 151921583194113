import { useEffect, useRef, useState } from "react";
import { ReqStatus } from "~/api";
import { PaginatedResult } from "~/util/PaginatedEndpoint";
import { useSearchParams } from "react-router-dom";
import { moveUrlToEBMSApiGateway } from "~/util";

export const TITLE_MAX_CHARS = 80;

export interface FetcherState<T> {
  loading: boolean;
  items: PaginatedResult<T>;
  itemsCombined: T[];
  page: number;
  totalPages: number;
  totalItems: number;
  onPageChange: (page: number) => void;
  onPageSizeChange: (page: number) => void;
  fetchNextPage: () => void;
  reload: () => Promise<void>;
  nextPageExists: boolean;
  onSortingChange: (sort: { id: string; desc?: boolean }[]) => void;
  orderingKey: string;
}

export function useDataFetcher<T extends { id: number | string }>(
  selected: boolean,
  fetch: (search: string, cursor: string | null, sortBy?: string) => Promise<PaginatedResult<T> | null>,
  options?: {
    clientOnlyPagination?: boolean;
    nextKey?: string;
    prevKey?: string;
    currentPageKey?: string;
    pagesCountKey?: string;
    itemsCountKey?: string;
    pageSizeKey?: string;
    initialFetchDependencyList?: any[];
    type?: "pagination" | "infinite";
    orderingKey?: string;
  }
): FetcherState<T> {
  const clientOnlyPagination = options?.clientOnlyPagination ?? false;
  const {
    nextKey = "next",
    prevKey = "prev",
    currentPageKey = "current_page",
    pagesCountKey = "total_pages",
    itemsCountKey = "count",
    initialFetchDependencyList = [selected],
    type = "pagination",
    orderingKey = "ordering",
  } = options || {};
  let [searchParams, setSearchParams] = useSearchParams();
  const [itemsCombined, setItemsCombined] = useState<T[]>([]);
  const [res, setRes] = useState<PaginatedResult<T>>({ results: [] } as any);
  const [reqStatus, setReqStatus] = useState<ReqStatus>(ReqStatus.Initial);
  const prevCursor = useRef<string | null>(null);
  const fetchWithCursor = async (cursor: string | null) => {
    prevCursor.current = cursor;
    setReqStatus(ReqStatus.InProcess);
    const d = await fetch("", cursor, searchParams.get(orderingKey) || "");
    if (!d) return;
    if (type === "infinite") {
      const itemsIds = itemsCombined.map((item) => item.id);
      setItemsCombined([...itemsCombined, ...d.results.filter((item) => !itemsIds.includes(item.id))]);
    }
    const next = d[nextKey] ? moveUrlToEBMSApiGateway(d[nextKey]) : null;
    setRes({ ...d, [nextKey]: next });
    setReqStatus(ReqStatus.Success);
  };
  useEffect(() => {
    if (selected) {
      setReqStatus(ReqStatus.InProcess);
      fetchWithCursor(null)
        .then(() => setReqStatus(ReqStatus.Success))
        .catch(() => setReqStatus(ReqStatus.Failed));
    }
  }, initialFetchDependencyList);
  const loading = reqStatus === ReqStatus.InProcess || reqStatus === ReqStatus.Initial;
  const page = res?.[currentPageKey] ?? 1;
  const totalPages = res?.[pagesCountKey] ?? 1;
  const totalItems = res?.[itemsCountKey] ?? 0;

  const fetchNextPage = () => {
    fetchWithCursor(res[nextKey]);
  };

  const onPageChange = async (newPage: number) => {
    if (clientOnlyPagination) {
      setRes({ ...res, current_page: newPage });
    } else {
      if (newPage === page + 1) {
        await fetchWithCursor(res[nextKey]);
      } else if (newPage === page - 1) {
        await fetchWithCursor(res[prevKey]);
      } else {
        let cursor: string = res[nextKey] || res[prevKey];
        if (cursor) {
          const url = new URL(cursor);
          url.searchParams.set("page", newPage.toString());
          await fetchWithCursor(url.toString());
        }
      }
    }
  };
  const onPageSizeChange = (pageSize: number) => {};
  const reload = async () => {
    await fetchWithCursor(prevCursor.current);
  };

  const onSortingChange = (sort) => {
    let ordering = "";
    if (sort[0]) {
      let { id, desc } = sort[0];
      if (id.includes("title_")) {
        // ordering query parameter only accepts 'title' value not 'title_en'/'title_es' etc.
        id = "title";
      }
      ordering = desc ? `-${id}` : id;
    }
    const params = new URLSearchParams(searchParams);
    if (ordering) {
      params.set(orderingKey, ordering);
    } else {
      params.delete(orderingKey);
    }
    setSearchParams(params);
  };

  return {
    loading,
    items: res,
    itemsCombined,
    page,
    totalPages,
    totalItems,
    onPageChange,
    onPageSizeChange,
    fetchNextPage,
    reload,
    nextPageExists: !!res[nextKey],
    onSortingChange,
    orderingKey,
  };
}
