import { observer } from "mobx-react";
import { TITLE_MAX_CHARS, useDataFetcher } from "./common";
import TabTable, { CommonTabProps } from "./TabTable";
import paths from "~/paths";
import { useEffect, useMemo, useState } from "react";
import bookmarkStore, { BookmarkInfo } from "~/stores/bookmarks";
import { Column, usePagination, useTable } from "react-table";
import { Link } from "react-router-dom";
import TabContent from "./TabContent";
import documentStore, { DocumentId } from "~/stores/document";
import keyBy from "lodash/keyBy";
import moment from "moment";
import { DOC_PUB_DATE_FORMAT } from "~/document";

const BookmarksTab = observer((props: CommonTabProps) => {
  const fetcher = useDataFetcher(props.selected, bookmarkStore.f.loadBare);
  const [docs, setDocs] = useState<Record<DocumentId, Document>>({});
  const [docsDataLoading, setDocsDataLoading] = useState(false);

  useEffect(() => {
    (async () => {
      if (fetcher.items.results.length && props.selected) {
        setDocsDataLoading(true);
        const ids = fetcher.items.results.map((a) => a.document);
        const docs = await documentStore.loadIds(ids);
        setDocs(keyBy(Object.values(docs), "id") as any);
        setDocsDataLoading(false);
      }
    })();
  }, [fetcher.items.results, props.selected]);

  const bookmarks = useMemo(
    () =>
      fetcher.items.results
        .map((bookmark) => {
          let docId = bookmark.document;
          const doc = docs[docId];
          if (doc) {
            return { ...bookmark, document_data: doc };
          }
          return null;
        })
        .filter((bookmark) => !!bookmark)
        .sort((a, b) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return moment(a?.document_data?.publication_date).isBefore(b?.document_data?.publication_date) ? 1 : -1;
        }),
    [fetcher.items.results, docs]
  );

  const data = useMemo(() => bookmarks as any, [bookmarks, docs]);
  const columns: ReadonlyArray<Column<BookmarkInfo>> = useMemo(
    () => [
      {
        Header: "Document",
        accessor: "title",
        Cell: (props) => {
          const doc = props.row.original;
          let title = doc.title;
          title = title.length > TITLE_MAX_CHARS ? `${title.substr(0, TITLE_MAX_CHARS)}...` : title;
          return (
            <div>
              {doc.document_data?.protocol ? (
                <Link to={paths.construct.docDetail(doc.document_data?.protocol)}>{title}</Link>
              ) : (
                <>{title}</>
              )}
            </div>
          );
        },
      },
      {
        Header: "Publication date",
        accessor: "document_data.publication_date" as any,
        Cell: (props: any) => (props.value ? moment(props.value).format(DOC_PUB_DATE_FORMAT) : "N/A"),
      },
      {
        Header: "Release date",
        accessor: "document_data.release_date" as any,
        Cell: (props: any) => (props.value ? moment(props.value).format(DOC_PUB_DATE_FORMAT) : "N/A"),
      },
      {
        Header: "Expiration date",
        accessor: "document_data.expiration_date" as any,
        Cell: (props: any) => (props.value ? moment(props.value).format(DOC_PUB_DATE_FORMAT) : "N/A"),
      },
      {
        Header: "DocumentsContext manager",
        accessor: "document_data.owner_display" as any,
        Cell: (props: any) => props.value || "N/A",
      },
      {
        Header: "Last modified by" as any,
        accessor: "document_data.last_modified_user" as any,
      },
      {
        Header: "Office division",
        accessor: "document_data.office_display" as any,
        Cell: (props: any) => props.value || "N/A",
      },
      {
        Header: "Document ID",
        accessor: "document_data.protocol" as any,
      },
    ],
    [bookmarks, docs]
  );

  const tableInstance = useTable({ columns, data }, usePagination);
  return (
    <TabContent>
      <TabTable
        fetcher={fetcher}
        loading={fetcher.loading || docsDataLoading}
        tableInstance={tableInstance}
        emptyTitle="No bookmarks"
        emptyMessage="You don't have any documents bookmarked"
      />
    </TabContent>
  );
});

export default BookmarksTab;
