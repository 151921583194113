import { makeAutoObservable } from "mobx";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import notifStore from "./notifications";
import { Owner, OwnerInfo, OwnerId } from "~/document";

export type { Owner, OwnerId, OwnerInfo };

export class OwnerStore {
  f = new PaginatedEndpoint<OwnerInfo>("owners", notifStore);

  constructor() {
    makeAutoObservable(this);
  }
}

const ownerStore = new OwnerStore();

export default ownerStore;
