import notificationStore, { NotificationStore } from "~/stores/notifications";
import { ReqStatus } from "~/util";
import { makeAutoObservable, runInAction } from "mobx";
import * as api from "~/api";

export type VenueId = string;

export interface VenueNode {
  id: VenueId;
  api_url: string;
  name: string;
}

export class VenueStore {
  notifStore: NotificationStore;
  venues: VenueNode[];
  status: ReqStatus = ReqStatus.Initial;

  get loading(): boolean {
    return this.status === ReqStatus.InProcess;
  }

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.venues = [];
    this.notifStore = notifStore;
  }

  postVenue = async (name: string): Promise<VenueNode | null> => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const resp = await api.post("ebms:venues", { name });
      const venue = resp!.data;
      if (!venue) return null;
      runInAction(() => {
        this.venues = [...this.venues, venue];
        this.status = ReqStatus.Success;
      });
      return venue;
    } catch (err) {
      this.notifStore.error("Failed to create venue");
      runInAction(() => {
        this.status = ReqStatus.Failed;
      });
      return null;
    }
  };

  loadVenues = async (): Promise<VenueNode[]> => {
    if (this.status === ReqStatus.Success) return this.venues;
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const resp = await api.get("ebms:venues");
      const venues = resp!.data;
      runInAction(() => {
        this.venues = venues;
        this.status = ReqStatus.Success;
      });
      return venues;
    } catch (err) {
      this.notifStore.error("Failed to load venues");
      runInAction(() => {
        this.status = ReqStatus.Failed;
      });
      return [];
    }
  };
}

const venueStore = new VenueStore(notificationStore);

export default venueStore;
