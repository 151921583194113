import { Icon, Button } from "@wfp/ui";
import styles from "./styles.module.scss";
import cn from "classnames";
import React, { HTMLProps, ComponentProps } from "react";

type IconProps = ComponentProps<typeof Icon>;
type Props = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  icon: IconProps["icon"];
  description: IconProps["description"];
};

const IconButton = (props: Props) => {
  return (
    <button {...props} className={cn([styles.iconButton, props.className])}>
      <Icon icon={props["icon"]} description={props["description"]} className="mr-6" />
      {props.children}
    </button>
  );
};

export default IconButton;
