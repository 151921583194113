import { AgendaItemDocument } from "~/stores/agendaItemDocs";
import styles from "~/components/AgendaItems/AgendaItem/styles.module.scss";
import { Tag } from "@wfp/ui";
import cn from "classnames";
import { NavLink } from "react-router-dom";
import paths from "~/paths";
import React from "react";

const AgendaItemDocSupplementaryInfo = ({ doc }: { doc: AgendaItemDocument }) => {
  return (
    <div className={styles.agendaDocFooter}>
      {!!doc.document_translations.length && (
        <div className={styles.agendaDocFooterItem}>
          <h6>Documents</h6>
          <div>
            {doc.document_translations.map((translation) => (
              <Tag
                type="info"
                key={`translation-${translation.protocol}`}
                className={cn(styles.roundTag, styles.roundTagLink, {
                  [styles.roundTagMetadata]: !translation.has_file,
                })}
              >
                <NavLink to={paths.construct.docDetail(translation.protocol)} target="_blank" rel="noopener noreferrer">
                  {translation.initial}
                </NavLink>
              </Tag>
            ))}
          </div>
        </div>
      )}
      {["Comment", "Decision", "Disclaimer", "Related", "Summary"].map((key) => {
        const info = doc.supplementary_information?.[key] || {};
        const infoItems = Object.values(info) as { initial: string; display: string }[];
        if (!Object.values(info).length) return null;
        return (
          <div key={`document-${key}`} className={styles.agendaDocFooterItem}>
            <h6>{key}</h6>
            <div>
              {infoItems.map((infoItem) => (
                <Tag type="info" title={infoItem.display} className={cn(styles.roundTag)}>
                  {infoItem.initial}
                </Tag>
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AgendaItemDocSupplementaryInfo;
