import { runInAction, makeAutoObservable } from "mobx";
import axios from "axios";
import * as api from "~/api";
import notifStore, { NotificationStore } from "~/stores/notifications";
import { ReqStatus, treeItemsById } from "~/util";
import { TopicId } from "~/document";
import { QueryParams } from "~/api";

export type { TopicId };

export interface TopicNode {
  id: TopicId;
  tid: TopicId;
  label: string;
  parent?: string | undefined;
  children: TopicNode[];
}

type TopicsById = Map<TopicId, TopicNode>;

export class TopicStore {
  topicsById: TopicsById;
  notifStore: NotificationStore;
  topics: TopicNode[];
  status: ReqStatus = ReqStatus.Initial;

  get loading(): boolean {
    return this.status === ReqStatus.InProcess;
  }

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.topicsById = new Map();
    this.topics = [];
    this.notifStore = notifStore;
  }

  loadTopics = async (ids?: TopicNode["id"][]): Promise<TopicNode[]> => {
    if (this.status === ReqStatus.Success) return this.topics;
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const params: QueryParams = { serializer: "tree", level: 0 };
      if (ids) params["id__inlist"] = ids;
      const resp = await api.get("structures/topics", params);
      const topics = resp!.data.results;
      runInAction(() => {
        this.topics = topics;
        this.topicsById = treeItemsById<"children", TopicNode, "tid">(this.topics, "children", true, "tid");
        this.status = ReqStatus.Success;
      });
      return topics;
    } catch (err) {
      this.notifStore.error("Failed to load topics");
      this.status = ReqStatus.Failed;
      return [];
    }
  };
}

const topicStore = new TopicStore(notifStore);

export default topicStore;
