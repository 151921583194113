import React, { useEffect } from "react";

type Props = {
  children: React.ReactNode;
};

const WithAutoScrollingWrapper = ({ children }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <>{children}</>;
};

export default WithAutoScrollingWrapper;
