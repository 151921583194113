import { observer } from "mobx-react";
import { Access, accessToText } from "~/document";
import { RadioButton, InputGroup } from "@wfp/ui";

interface Props {
  value: Access[];
  onChange: (newValue: Access[]) => void;
  selectProps: any;
}

function numEnumValues<T>(enumType: T): number[] {
  const res: any[] = [];
  // eslint-disable-next-line @typescript-eslint/ban-types
  for (let value of Object.values(enumType as {}) as any) {
    if (!isNaN(Number(value))) {
      res.push(value);
    }
  }
  return res;
}

const idToOption = (id: number) => {
  return {
    value: id,
    label: accessToText(id),
  };
};

const AccessLevelSelect = observer((props: Props) => {
  const value = props.value.map(idToOption);
  const options = numEnumValues(Access).map(idToOption);
  return (
    <InputGroup name="input-group" vertical>
      {options.map((opt) => {
        const vs = String(opt.value);
        const checked = value.length !== 0 && String(value[0].value) === vs;
        return (
          <RadioButton
            key={vs}
            id={vs}
            labelText={opt.label}
            name={vs}
            onChange={(a: any) => props.onChange([a.target.value])}
            checked={checked}
            value={vs}
            disabled={props.selectProps.disabled}
          />
        );
      })}
    </InputGroup>
  );
});

export default AccessLevelSelect;
