import { Document } from "~/document";
import { Text } from "@wfp/ui";
import { observer } from "mobx-react";
import styles from "./styles.module.scss";
import { Link } from "react-router-dom";
import paths from "~/paths";
import languageStore from "~/stores/language";
import { TranslationInfo } from "~/document";
import cn from "classnames";
import React, { useMemo } from "react";

interface Props {
  document: Document;
  translations: TranslationInfo[];
}

const LanguageDisplay = observer((props: Props) => {
  const { document } = props;
  const translations = useMemo(() => [...props.translations, document], [props.translations, document]);
  const langIdToLangInfo = languageStore.loadedLanguages;
  if (!document.language && translations.length === 0) {
    return <Text>No languages available</Text>;
  }
  if (languageStore.loading) {
    return null;
  }

  const docLanguageDisplay = langIdToLangInfo[document.language]?.text ?? "";
  const areThereAnyLanguagesAvailable = docLanguageDisplay || translations.length !== 0;
  if (!areThereAnyLanguagesAvailable) {
    return <div>No languages available</div>;
  }
  return (
    <div>
      {translations.map((tr) => (
        <Link
          key={tr.language}
          className={cn({
            [styles.langItem]: true,
          })}
          to={paths.construct.docDetail(tr.protocol)}
        >
          {langIdToLangInfo[tr.language]?.text ?? tr.language}
        </Link>
      ))}
    </div>
  );
});

export default LanguageDisplay;
