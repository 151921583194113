import { makeAutoObservable } from "mobx";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import notifStore from "./notifications";
import { Office, OfficeInfo, OfficeId } from "~/document";

export type { Office, OfficeId, OfficeInfo };

export class OfficeStore {
  f = new PaginatedEndpoint<OfficeInfo>("offices", notifStore);

  constructor() {
    makeAutoObservable(this);
  }
}

const officeStore = new OfficeStore();

export default officeStore;
