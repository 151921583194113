import { useDrag, useDrop } from "react-dnd";
import { AgendaItem, AgendaItemGroup } from "~/stores/agendaItems";
import { useRef } from "react";
import { Identifier } from "dnd-core";
import { countDragIndex } from "~/util";

export const AGENDA_ITEM_DND = "agenda-item-dnd";
export interface AgendaItemDragType extends AgendaItem {
  index: number;
}

export type MoveAgendaItem = (dragIndex: number, hoverIndex: number) => void;
export type CountAgendaItemPosition = (index: number, agendaItem: AgendaItem, group: AgendaItemGroup) => number;

export const useDropAgendaItem = (group: AgendaItemGroup, index: number, moveAgendaItem: MoveAgendaItem) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<AgendaItemDragType, void, { handlerId: Identifier | null }>({
    accept: AGENDA_ITEM_DND,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: AgendaItemDragType, monitor) {
      countDragIndex(ref, item, index, monitor, () => moveAgendaItem(item.index, index), group);
    },
  });
  return { ref, handlerId, drop };
};

const useAgendaItemDND = (
  agendaItem: AgendaItem,
  index: number,
  onDrop: (position: number, group: AgendaItemGroup) => any,
  moveAgendaItem: MoveAgendaItem,
  countAgendaItemPosition: CountAgendaItemPosition
) => {
  const [{ isDragging }, drag] = useDrag({
    type: AGENDA_ITEM_DND,
    item: { ...agendaItem, index },
    end: async (item, monitor) => {
      onDrop(countAgendaItemPosition(item.index, agendaItem, item.group), item.group);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  });

  const { drop, ref, handlerId } = useDropAgendaItem(agendaItem.group, index, moveAgendaItem);

  drop(drag(ref));

  return {
    isDragging,
    drag,
    handlerId,
    drop,
    ref,
  };
};

export default useAgendaItemDND;
