import HighlightedText from "~/components/HighlightedText";
import styles from "./styles.module.scss";
import { Tag, Blockquote, Checkbox, Loading, Search, Text } from "@wfp/ui";
import cn from "classnames";
import { useEffect, useCallback, useState, useMemo, ChangeEvent, Dispatch, SetStateAction } from "react";
import debounce from "lodash/debounce";
import { usePopupFocusManager } from "~/util/hooks";
import { animated, config, Transition } from "react-spring";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { loopHooks } from "react-table";

export type TreeOption<K> = {
  id: K;
  tid?: K;
  label: string;
  children?: TreeOption<K>[];
  parent?: K;
};

type SelectedOptions<K> = Set<K>;

export interface Props<K, O extends TreeOption<K>> {
  loading: boolean;
  options: O[];
  selected: SelectedOptions<K>;
  onChange: (value: SelectedOptions<K>) => void;
  // Load items (needed for lazy-load)
  load?: () => Promise<any>;
  infoBoxText: string;
  maxOptionsToSelect?: number;
  // items by id
  byId: Map<K, O>;
  highlevelSelect: boolean;
  defaultCollapsed?: Set<K>;
  invalid?: boolean;
}

function subtreeIds<K, O extends TreeOption<K>>(subtree: O, maxOptionsToSelect: number | null = null) {
  let ids: SelectedOptions<K> = new Set([]);
  const considerTree = (t: TreeOption<K>) => {
    ids.add(t.id);
    if (
      !t.children ||
      t.children.length === 0 ||
      (maxOptionsToSelect !== null && ids.size + t.children.length >= maxOptionsToSelect)
    ) {
      return;
    }
    for (const child of t.children) {
      considerTree(child);
    }
  };
  considerTree(subtree);
  return ids;
}

// Render a subtree of options
function renderOptionArray<K, T extends TreeOption<K>>(
  options: T[],
  level = 0,
  selectedOptions: SelectedOptions<K>,
  setselected: (topicIds: SelectedOptions<K>) => void,
  searchFor: string | null = null,
  maxOptionsToSelect: number | null = null,
  byId: Map<K, T>,
  collapsed: Set<K>,
  setCollapsed: Dispatch<SetStateAction<Set<K>>>,
  setExpanded: Dispatch<SetStateAction<boolean>>,
  setTextSearchValue: Dispatch<SetStateAction<string>>,
  highlevelSelect: boolean
) {
  const selectedMax = maxOptionsToSelect !== null && selectedOptions.size >= maxOptionsToSelect;
  const rootLevel = level === 0;
  const result = options
    .map((option: TreeOption<K>) => {
      let id_value = option.id;
      if (option.tid) id_value = option.tid;
      const selected = selectedOptions.has(+id_value as K) || selectedOptions.has(id_value.toString() as K);
      const searchIn = option.label ? option.label.toLowerCase() : "";
      const searchForLower = searchFor !== null ? searchFor.toLowerCase() : null;
      const searchStartIdx = searchForLower !== null ? searchIn.indexOf(searchForLower) : null;
      const searchRange =
        searchFor !== null && searchStartIdx !== null && searchStartIdx !== -1
          ? ([searchStartIdx, searchStartIdx + searchFor.length] as [number, number])
          : null;
      const thisLabelMatchesSearch = searchStartIdx !== -1;
      const someChildrenMatchSearch = () => {
        let result = false;
        function iter(a) {
          if (!!a.label && a.label.toLowerCase().indexOf(searchForLower) > -1) {
            result = true;
            return;
          }
          return Array.isArray(a.children) && a.children.some(iter);
        }

        option.children.some(iter);
        return result;
      };
      const includesFilteredEntry = thisLabelMatchesSearch || someChildrenMatchSearch();

      const isCollapsed = collapsed.has(id_value);
      let renderedSubtree = null;

      if ((isCollapsed || searchFor !== null) && option.children !== undefined && option.children.length !== 0) {
        const renderedTree = renderOptionArray(
          option.children,
          level + 1,
          selectedOptions,
          setselected,
          searchFor,
          maxOptionsToSelect,
          byId,
          collapsed,
          setCollapsed,
          setExpanded,
          setTextSearchValue,
          highlevelSelect
        );
        if (renderedTree !== null) {
          renderedSubtree = <div className={`${styles.topicSubtree}`}>{renderedTree}</div>;
        }
      }

      const shouldDisplay = thisLabelMatchesSearch || renderedSubtree !== null;

      // if (!shouldDisplay || !option.label) return null;

      const renderedTextLabel = (
        <Text>{searchRange !== null ? <HighlightedText text={option.label} range={searchRange} /> : option.label}</Text>
      );

      const disabled = !selected && selectedMax;
      const showCheckBox = () => {
        return highlevelSelect ? true : _.isEmpty(option.children);
      };

      const onRootClick = (evt) => {
        setCollapsed((prevState: Set<K>) => {
          let newState: Set<K> = new Set<K>([...prevState]);
          if (newState.has(id_value)) {
            newState.delete(id_value);
          } else {
            newState.add(id_value);
          }
          return newState;
        });
      };

      if (!!searchFor && !includesFilteredEntry) {
        return null;
      }

      return (
        <div
          key={id_value as any as string}
          className={cn({
            [styles.topicSubtreeRoot]: true,
            [styles.rootTree]: rootLevel,
          })}
          style={{ marginLeft: level * 16 }}
        >
          <div className={styles.topicSubtreeRootCheckbox}>
            <div
              className={styles.topicSubtreeRootArrow}
              style={{ width: level * 16, marginLeft: -(level * 16) - 1 }}
            />
            <div className={styles.treeSelectCheckBoxWrapper}>
              {showCheckBox() ? (
                <Checkbox
                  id={`topic-select-${id_value}`}
                  checked={selected}
                  labelText={""}
                  wrapperClassName="flex-0"
                  disabled={disabled}
                  title={disabled ? `Cannot select more than ${maxOptionsToSelect} options` : undefined}
                  onChange={(evt, checked) => {
                    let node = option;
                    let id_value = node.id;
                    if (node.tid) id_value = node.tid;
                    if (checked) {
                      selectedOptions.add(id_value);
                      setExpanded(false);
                      setTextSearchValue("");
                    } else {
                      selectedOptions.delete(id_value);
                    }
                    setselected(selectedOptions);
                  }}
                />
              ) : null}
              <div onClick={onRootClick}>{renderedTextLabel}</div>
            </div>
            {!!option.children?.length && (
              <FontAwesomeIcon
                onClick={onRootClick}
                color="#77a0b6"
                className={styles.rootTreeAngleIcon}
                icon={solid("angle-down")}
              />
            )}
          </div>
          {renderedSubtree}
        </div>
      );
    })
    .filter((a) => a !== null);
  // if (result.length === 0) return null;
  return result;
}

function TreeSelect<K, O extends TreeOption<K>>(props: Props<K, O>) {
  const { loading, options, selected, onChange, byId, highlevelSelect, defaultCollapsed, invalid } = props;
  const [textSearchValue, setTextSearchValue] = useState<string>("");

  const [collapsed, setCollapsed] = useState(new Set<K>());
  useEffect(() => {
    if (defaultCollapsed) {
      setCollapsed(defaultCollapsed);
    }
  }, [defaultCollapsed]);

  const focusManager = usePopupFocusManager();
  const expanded = focusManager.focused;
  const setExpanded = focusManager.setFocused;

  const renderedTree = useMemo(() => {
    if (loading) {
      return (
        <div className="df h-100 w-100 pv-16 fc">
          <Loading className="loader loader_sm" withOverlay={false}>
            Text
          </Loading>
        </div>
      );
    }
    const renderedRootLevel = renderOptionArray(
      options,
      0,
      selected,
      onChange,
      textSearchValue.trim() === "" ? null : textSearchValue,
      props.maxOptionsToSelect,
      byId,
      collapsed,
      setCollapsed,
      setExpanded,
      setTextSearchValue,
      highlevelSelect
    );
    if (renderedRootLevel === null || renderedRootLevel.length === 0) {
      return <div className="fs-14 w-100 h-100 df fc">No matches</div>;
    }
    return <div>{renderedRootLevel}</div>;
  }, [options, selected.size, textSearchValue, loading, props.maxOptionsToSelect, collapsed]);

  const onSearchChange = useCallback(
    debounce((v: string) => {
      setTextSearchValue(v);
    }, 500),
    []
  );
  const [loadedInitial, setLoadedInitial] = useState(false);
  // Load when first focused
  useEffect(() => {
    if (!loadedInitial && expanded && !!props.load) {
      props.load();
      setLoadedInitial(true);
    }
  }, [expanded]);

  // Expand automatically if user starts typing
  useEffect(() => {
    if (textSearchValue === "") return;
    setExpanded(true);
  }, [textSearchValue]);

  const selectedOptionsDisplay = useMemo(() => {
    return Array.from(selected).map((id) => {
      const option = props.byId.get(id);
      if (option === undefined) return null;
      const optionDisplay = option.label;
      return (
        <Tag key={id as any} type="info" kind="wfp" className="fs-14">
          <Text className="mr-4 fs-12">{optionDisplay}</Text>
          <FontAwesomeIcon
            icon={solid("xmark")}
            cursor="pointer"
            onClick={() => {
              const selectedCopy = new Set(selected);
              selectedCopy.delete(option.tid || option.id);
              onChange(selectedCopy);
            }}
          />
        </Tag>
      );
    });
  }, [selected.size, props.byId, selected, onChange]);
  return (
    <div ref={focusManager.ref} className={invalid ? "tree-select--invalid" : ""}>
      <Search
        id="topics-search"
        value={textSearchValue}
        name="search-query"
        closeButtonLabelText="Clear search"
        placeholder="Search..."
        onChange={onSearchChange}
        onFocus={() => setExpanded(true)}
        autoComplete="off"
      />
      <div className={styles.wrapper}>
        <div className="mt-8" style={{ zIndex: 0 }}>
          {selectedOptionsDisplay}
        </div>
        <div className={styles.options}>
          <Transition
            items={expanded}
            from={{ opacity: 1 }}
            enter={{
              opacity: 1,
              boxShadow: "0px 0px 2px 0px #00000017, 0px 0px 8px 0px #00000012",
            }}
            leave={{ opacity: 1 }}
            config={config.wobbly}
          >
            {(props, show) =>
              show && (
                <animated.div style={{ ...props }} className={styles.documentViewWrapper}>
                  <div className={styles.resultsContainer}>{renderedTree}</div>
                </animated.div>
              )
            }
          </Transition>
        </div>
      </div>
      <Blockquote>
        <strong className="mb-4 fs-14">TIPS & INFO</strong>
        <Text className="fs-14">{props.infoBoxText}</Text>
      </Blockquote>
    </div>
  );
}

export default TreeSelect;
