import documentRelationStore, { DocumentRelationType, DocumentRelationTypeId } from "~/stores/docRelationTypes";
import { observer } from "mobx-react";
import AsyncPaginatedSelect, { AsyncPaginatedSelectProps } from "~/components/AsyncPaginatedSelect";

interface Props extends Partial<Omit<AsyncPaginatedSelectProps<DocumentRelationType>, "onChange" | "value">> {
  value: DocumentRelationType[];
  onChange: (newValue: DocumentRelationType[]) => void;
}

const DocumentRelationSelect = observer((props: Props) => {
  const fetcher = documentRelationStore.f;
  const idToOpt = (id: DocumentRelationTypeId) => {
    const item = fetcher.items[id];
    return {
      label: item.left_to_right,
      value: item.id,
    };
  };
  return (
    <AsyncPaginatedSelect
      {...props}
      fetcher={documentRelationStore.f}
      itemIdToOption={idToOpt}
      value={props.value.map((doc) => doc.id)}
      onChange={(items) => {
        if (items instanceof Array) {
          props.onChange(items.map((id) => fetcher.items[id]));
        } else {
          props.onChange([fetcher.items[items]]);
        }
      }}
    />
  );
});

export default DocumentRelationSelect;
