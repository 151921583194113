import { makeAutoObservable, runInAction } from "mobx";
import notificationStore, { NotificationStore } from "./notifications";
import { ReqStatus } from "~/util";
import * as api from "~/api";

type MeetingType = {
  label: string;
  value: string;
  prefix: string;
};

export class MeetingTypeStore {
  meetingTypesList: MeetingType[] = [];
  notifStore: NotificationStore;
  status: ReqStatus = ReqStatus.Initial;

  constructor(notifStore) {
    makeAutoObservable(this);
    this.meetingTypesList = [];
    this.notifStore = notifStore;
  }

  get loading(): boolean {
    return this.status === ReqStatus.InProcess;
  }

  get meetingTypes(): { [key: string]: string } {
    const meetingTypesMap: { [key: string]: string } = {};
    this.meetingTypesList.forEach(({ label, value }) => (meetingTypesMap[value] = label));
    return meetingTypesMap;
  }

  loadMeetingTypes = async () => {
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const res = await api.get("ebms:meetings/types");
      if (!res.data) {
        return null;
      }
      runInAction(() => {
        this.meetingTypesList = res.data;
        this.status = ReqStatus.Success;
      });
      return res.data;
    } catch (e) {
      runInAction(() => {
        this.status = ReqStatus.Failed;
      });
      return null;
    }
  };
}

const meetingTypeStore = new MeetingTypeStore(notificationStore);

export default meetingTypeStore;
