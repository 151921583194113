import React from "react";
import styles from "./styles.module.scss";
import { Button, Checkbox, FormLabel, Link } from "@wfp/ui";
import { TimeScheduleItem } from "~/stores/meeting";
import moment from "moment";
import InputMask from "react-input-mask";

type Props = {
  timeSchedule: Partial<TimeScheduleItem>[];
  onChange: (key?: string, field?: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  setTimeField: (key: string, field: string, value?: string) => void;
  remove: (key: string) => () => void;
  onBlur: () => any;
  onDefaultChange: (timeScheduleKey: string) => (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  children?: React.ReactNode;
};

const TimeInput = ({
  onChange,
  onBlur,
  disabled,
  value,
  start,
}: {
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: () => any;
  value: string;
  disabled: boolean;
  start: boolean;
}) => (
  <div className="wfp--form-item">
    <span className="wfp--label">{start ? "Start" : "End"}</span>
    <div className="wfp--input-wrapper">
      <InputMask
        mask={[/[0-2]/, value?.[0] === "2" ? /[0-3]/ : /[0-9]/, ":", /[0-5]/, /[0-9]/]}
        className="wfp--input wfp--text-input fs-16"
        value={value}
        onBlur={onBlur}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  </div>
);

const TimeScheduleField = ({
  timeSchedule,
  disabled,
  onChange,
  setTimeField,
  onDefaultChange,
  remove,
  children,
  onBlur,
}: Props) => {
  const isTimeScheduleByDate = timeSchedule.length > 1;

  return (
    <>
      {timeSchedule.map((timeScheduleItem, i) => {
        const breakExists = !!timeScheduleItem.start_time_2 || timeScheduleItem.start_time_2 === null;
        const toggleBreak = () => {
          if (breakExists) {
            setTimeField(timeScheduleItem.key, "start_time_2", undefined);
            setTimeField(timeScheduleItem.key, "end_time_2", undefined);
          } else {
            setTimeField(timeScheduleItem.key, "start_time_2", null);
            setTimeField(timeScheduleItem.key, "end_time_2", null);
          }
        };

        return (
          <div>
            {isTimeScheduleByDate && (
              <FormLabel style={{ marginBottom: 12, marginTop: 15 }}>
                Meeting time for {moment(timeScheduleItem.day).format("MM/DD/YYYY")}{" "}
                {timeScheduleItem.default ? "(set as default)" : ""}
              </FormLabel>
            )}
            <div
              className={styles.field}
              key={timeScheduleItem.key}
              style={i === timeSchedule.length - 1 ? { marginBottom: 0 } : {}}
            >
              <div className={styles.fieldBody}>
                {isTimeScheduleByDate && (
                  <div>
                    <FormLabel style={{ fontSize: 12 }}>Default</FormLabel>
                    <Checkbox
                      id={`${timeScheduleItem.key}-default`}
                      style={{ flex: 0 }}
                      checked={!!timeScheduleItem.default}
                      onChange={onDefaultChange(timeScheduleItem.key)}
                    />
                  </div>
                )}
                <TimeInput
                  start
                  value={timeScheduleItem.start_time_1}
                  onChange={onChange(timeScheduleItem.key, "start_time_1")}
                  onBlur={onBlur}
                  disabled={disabled}
                />
                <TimeInput
                  start={false}
                  value={timeScheduleItem.end_time_1}
                  onBlur={onBlur}
                  onChange={onChange(timeScheduleItem.key, "end_time_1")}
                  disabled={disabled}
                />
                <Button
                  style={{ alignSelf: "flex-end", marginLeft: 20, marginRight: 20 }}
                  kind="secondary"
                  onClick={toggleBreak}
                  disabled={disabled}
                >
                  {breakExists ? "Remove break" : "Add break"}
                </Button>
                {breakExists && (
                  <>
                    <TimeInput
                      start
                      value={timeScheduleItem.start_time_2}
                      onBlur={onBlur}
                      onChange={onChange(timeScheduleItem.key, "start_time_2")}
                      disabled={disabled}
                    />
                    <TimeInput
                      start={false}
                      value={timeScheduleItem.end_time_2}
                      onBlur={onBlur}
                      onChange={onChange(timeScheduleItem.key, "end_time_2")}
                      disabled={disabled}
                    />
                  </>
                )}
              </div>
              {i !== 0 && (
                <Link className={styles.removeLink} onClick={remove(timeScheduleItem.key)}>
                  Remove
                </Link>
              )}
            </div>
            {children}
          </div>
        );
      })}
    </>
  );
};

export default TimeScheduleField;
