import axios, { AxiosResponse } from "axios";
import authStore from "~/stores/auth";
import qs from "qs";
import { ReqStatus } from "~/util";

class NetworkError extends Error {
  toString() {
    return "NetworkError";
  }
}

class UnknownNetworkError extends Error {
  message: string;
  response: AxiosResponse;

  constructor(message: string, response: AxiosResponse) {
    super();
    this.message = message;
    this.response = response;
  }

  toString() {
    let msg = "";
    if (this.response.data instanceof Array && this.response.data.length !== 0) {
      msg = this.response.data.join(" ");
    } else if (!(this.response.data instanceof Array) && !!this.response.data) {
      msg = this.response.data;
    } else {
      msg = this.message;
    }
    return `Unknown network error (${msg})`;
  }
}

export class NotFound extends Error {
  constructor() {
    super();
  }

  toString() {
    return "Not found";
  }
}

export class APIError extends Error {
  message: string;

  constructor(message: string, response: AxiosResponse) {
    super();
    interface EP {
      code: number;
      error: string;
    }
    this.message = response.data.map((a: EP) => a.error).join(", ");
  }

  toString() {
    return this.message;
  }
}

export const GODOCS_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export const GODOCS_API_ROOT = `${process.env.REACT_APP_API_ENDPOINT}/api`;
export const EBMS_API_ROOT = `${process.env.REACT_APP_EBMS_API_ENDPOINT}/api`;
export const APPLICATION_TOKEN = process.env.REACT_APP_APPLICATION_TOKEN;

export type QueryParams = Record<string, string | number | number[] | string[] | (number | string)[]>;

export const constructHeaders = async () => {
  const token = await authStore.getAuthToken();
  const refreshToken = await authStore.getRefreshToken();
  if (token === null) return null;
  const headers = {
    "X-Requested-With": "XMLHttpRequest",
    Authorization: `Bearer ${token}`,
    "Cache-Control": "no-cache",
  };
  return headers;
};

const performApiReq = async (cb: (headers: any) => any, isEBMS: boolean): Promise<AxiosResponse<any> | null> => {
  try {
    const headers = await constructHeaders();
    if (headers === null) return null;
    const resp = await cb(isEBMS ? headers : { ...headers, "x-application-token": APPLICATION_TOKEN });
    return resp;
  } catch (err: any) {
    if (!err.response) {
      return null;
    }
    // Auth error
    if (err.response.status === 401) {
      authStore.logOut();
      return null;
    }
    // not found
    if (err.response.status === 404) {
      throw new NotFound();
    }
    if (err.response.data instanceof Array && err.response.data.length !== 0 && "error" in err.response.data[0]) {
      // Specific error
      throw new APIError(err.message, err.response);
    }
    if (err.response.status === 403) {
      return null;
    }

    throw new UnknownNetworkError(err.message, err.response);
  }
};

const getPath = (path: string) => {
  const isEBMS = path.includes("ebms:");
  return `${EBMS_API_ROOT}${isEBMS ? "" : "/gateway-proxy"}/${path.replace("ebms:", "")}/`;
};

// TODO: Add generic parameter to specify the data return type

export const del = async (path: string, data?: any) => {
  return await performApiReq(
    (headers) =>
      axios.delete(getPath(path), {
        headers: headers,
        ...(data ? { data } : {}),
      }),
    path.includes("ebms:")
  );
};

export async function getBare<T>(path: string, queryParams: QueryParams & { isEBMS?: boolean } = {}) {
  return await performApiReq((headers) => {
    return axios.get<T>(path, {
      headers: headers,
      params: queryParams,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: "comma" });
      },
    });
  }, path.includes(EBMS_API_ROOT));
}

export const get: typeof getBare = async (path: string, ...args) => {
  return await getBare(getPath(path), ...args);
};

export const post = async (
  path: string,
  body: Record<string, any> = {},
  additionalOptions: Record<string, any> = {}
) => {
  return await performApiReq(
    (headers) =>
      axios.post(getPath(path), body, {
        headers,
        ...additionalOptions,
      }),
    path.includes("ebms:")
  );
};

export const put = async (
  path: string,
  body: Record<string, any> = {},
  additionalOptions: Record<string, any> = {}
) => {
  return await performApiReq(
    (headers) =>
      axios.put(getPath(path), body, {
        headers,
        ...additionalOptions,
      }),
    path.includes("ebms:")
  );
};

export const patch = async (
  path: string,
  body: Record<string, any> = {},
  additionalOptions: Record<string, any> = {}
) => {
  return await performApiReq(
    (headers) =>
      axios.patch(getPath(path), body, {
        headers,
        ...additionalOptions,
      }),
    path.includes("ebms:")
  );
};

export const postMultipart = async (path: string, body: FormData) => {
  return await performApiReq(
    (headers) =>
      axios.post(getPath(path), body, {
        headers: { ...headers, "content-type": "multipart/form-data" },
      }),
    path.includes("ebms:")
  );
};

export { ReqStatus };
