import styles from "./styles.module.scss";
import bulkDownloader from "~/stores/bulkDownloader";
import { Button, Text, List, ListItem, Card } from "@wfp/ui";
import { observer } from "mobx-react";
import cn from "classnames";
import { iconClose } from "@wfp/icons";
import { Container, Row, Col } from "react-grid-system";
import { ReqStatus } from "~/api";

const BulkDownloadModal = observer(() => {
  if (!bulkDownloader.selectedSomething) return null;
  const downloading = bulkDownloader.status === ReqStatus.InProcess;
  return (
    <Card
      cardWidth="400px"
      className={styles.modal}
      isExternal
      kind="simple-card"
      metadata={`Bulk download (${bulkDownloader.selectedDocumentsList.length} items)`}
    >
      <div className={styles.content}>
        <List className={styles.docList} kind="details">
          {bulkDownloader.selectedDocumentsList.map((item) => {
            const deselect = () => bulkDownloader.setBulkDownloadSelected(item.protocol, false);
            return (
              <div className={cn(["df fdr fsb", styles.listItem])}>
                <ListItem
                  title={
                    <Text kind="h6" className="fs-14 mr-8">
                      {item.title}
                    </Text>
                  }
                >
                  <Text kind="p" className="fs-12">
                    5 MB
                  </Text>
                  <Text kind="p" className="fs-12">
                    {item.protocol}
                  </Text>
                </ListItem>
                <Button kind="tertiary" icon={iconClose} title="Deselect" onClick={deselect} />
              </div>
            );
          })}
        </List>
        <div className="fspace" />
        <Container className={styles.actions} style={{ margin: 0 }}>
          <Row gutterWidth={8}>
            <Col xs={12} md={6}>
              <Button
                kind="secondary"
                onClick={async () => {
                  await bulkDownloader.download();
                  bulkDownloader.clearSelection();
                }}
                disabled={downloading}
              >
                Download ZIP
              </Button>
            </Col>
            <Col xs={12} md={6}>
              <Button kind="danger" onClick={bulkDownloader.clearSelection} disabled={downloading}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    </Card>
  );
});

export default BulkDownloadModal;
