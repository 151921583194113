import { useEffect } from "react";
import topicStore, { TopicId } from "~/stores/topics";
import { observer } from "mobx-react";
import TreeSelect from "~/components/TreeSelect";
import { useDefaultCollapsedTopics } from "~/util/hooks";

type SelectedTopics = Set<TopicId>;

export interface Props {
  value: SelectedTopics;
  onChange: (value: Set<TopicId>) => void;
  className?: string;
  invalid?: boolean;
}

const TopicSelect = observer((props: Props) => {
  const selectedTopics = props.value;
  const onSetValue = (newValue: Set<TopicId>) => {
    props.onChange(newValue);
  };

  const defaultCollapsed = useDefaultCollapsedTopics(selectedTopics, topicStore.topics);

  useEffect(() => {
    topicStore.loadTopics();
  }, []);

  return (
    <TreeSelect
      load={topicStore.loadTopics}
      loading={topicStore.loading}
      defaultCollapsed={defaultCollapsed}
      options={topicStore.topics}
      selected={selectedTopics}
      onChange={onSetValue}
      infoBoxText="Please select at least one topic related to the document you're uploading (you may also select multiple topics)."
      byId={topicStore.topicsById}
      invalid={props.invalid}
      highlevelSelect={true}
    />
  );
});

export default TopicSelect;
