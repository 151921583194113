import React, { useMemo, useEffect, useState, useContext } from "react";
import {
  TextArea,
  TextInput,
  Button,
  List,
  ListItem,
  FormGroup,
  Text,
  Modal,
  ToastNotification,
  InlineLoading,
} from "@wfp/ui";
import styles from "./styles.module.scss";
import { Document, DocumentProto, DocumentSearch } from "~/document";
import DocumentSelect from "~/components/DocumentSelect";
import { observer } from "mobx-react";
import { LangId } from "~/document";
import langVersionStore, { DocLangVersion } from "~/stores/langVersionStore";
import cn from "classnames";
import paths from "~/paths";
import FileDropArea from "./FileUploadArea";
import LanguageSelect from "~/components/LanguageSelect";
import languageStore from "~/stores/language";
import { NavLink } from "react-router-dom";
import { ReqStatus } from "~/util";
import { isEditing } from "~/pages/UploadPage/index";
import { ToastNotificationContext } from "~/components/ToastNotificationContext";

interface AddVersionModalProps {
  doc: Document;
  open: boolean;
  close: () => void;
  presentLanguages: Set<LangId>;
  addVersionCallback: () => Promise<void>;
}

const AddVersionModal = observer((props: AddVersionModalProps) => {
  const { open, close, addVersionCallback } = props;
  const { showNotificationWithTimeout, setNotificationData } = useContext(ToastNotificationContext);
  const [editing] = isEditing();
  const [title, setTitle] = useState<string>("");
  const [language, setLanguage] = useState<LangId | null>(null);
  const [abstract, setAbstract] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const canSubmit = title.trim().length !== 0 && !!title && language !== null;
  const submit = async () => {
    const res = await langVersionStore.addFor(props.doc.protocol, file!, { language: language!, title: title! });
    if (editing) {
      if (res) {
        await addVersionCallback();
        setNotificationData({
          kind: "success",
          subtitle: "Language version added successfully",
          title: "Success",
          caption: "",
          className: "toast-notification",
        });
        showNotificationWithTimeout();
      } else {
        setNotificationData({
          kind: "error",
          subtitle: "Language version couldn't be added",
          title: "Error",
          caption: "",
          className: "toast-notification",
        });
        showNotificationWithTimeout();
      }
    }
  };
  const submitting = langVersionStore.status === ReqStatus.InProcess;

  return (
    <Modal
      open={open}
      modalLabel="Document versions"
      modalHeading="Add version"
      onRequestClose={close}
      onRequestSubmit={() => {
        (async () => {
          await submit();
          close();
        })();
      }}
      primaryButtonDisabled={!canSubmit || submitting}
      // primaryButtonDisabled={submitting}
      primaryButtonText={submitting ? "Adding..." : "Add language version"}
      secondaryButtonText="Cancel"
      className="modal_wider"
    >
      <div className={styles.modal}>
        <FormGroup legendText="Select document">
          <FileDropArea
            className={styles.uploadAreaModal}
            value={file ? [file] : []}
            onAttachFile={(files) => setFile(files[0])}
          />
        </FormGroup>
        <FormGroup legendText="Title">
          <TextInput value={title} onChange={(e: any) => setTitle(e.target.value)} labelText="" />
        </FormGroup>
        <FormGroup legendText="Language">
          {/*
            NOTE: So these styles need to be applied in order for the
            language select to not overflow the modal container and
            cause a scroll to appear. The problem with adding these
            styles is that, if the select is mounted on the document
            root, when the user selects something inside of this
            select, the modal is going to think that the user clicked outside
            of this modal, and hence will close, which is not the behavior
            that we want. Need to make a PR to wfp/designsystem with changes
            to how the modal detects clicks outside of it's region to make this work.

            menuPortalTarget: document.body,
            menuPosition: "fixed",
            styles: { menuPortal: (base) => ({ ...base, zIndex: 9999 }) },

            */}
          <LanguageSelect
            value={language ? [language] : []}
            onChange={(langs) => setLanguage(langs[0])}
            filter={(item) => !props.presentLanguages.has(item.id)}
            selectProps={{
              isMulti: false,
            }}
          />
        </FormGroup>
        <FormGroup legendText="Abstract">
          <TextArea value={abstract} onChange={(e: any) => setAbstract(e.target.value)} labelText="" />
        </FormGroup>
      </div>
    </Modal>
  );
});

interface LinkVersionModalProps {
  presentLanguages: Set<LangId>;
  doc: Document;
  open: boolean;
  close: () => void;
  linkVersionCallback: () => Promise<void>;
}

const LinkVersionModal = observer((props: LinkVersionModalProps) => {
  const { open, close } = props;
  const [selectedDoc, setSelectedDoc] = useState<DocumentSearch | null>(null);
  const { showNotificationWithTimeout, setNotificationData } = useContext(ToastNotificationContext);
  const canSubmit = selectedDoc !== null;
  const submit = async () => {
    const res = await langVersionStore.linkLangVersion(props.doc.protocol, selectedDoc!);
    if (res) {
      await props.linkVersionCallback();
      setNotificationData({
        kind: "success",
        subtitle: "Document linked added successfully",
        title: "Success",
        caption: "",
        className: "toast-notification",
      });
      showNotificationWithTimeout();
    } else {
      setNotificationData({
        kind: "error",
        subtitle: "Document couldn't be linked",
        title: "Error",
        caption: "",
        className: "toast-notification",
      });
      showNotificationWithTimeout();
    }
  };
  const submitting = langVersionStore.status === ReqStatus.InProcess;
  return (
    <Modal
      open={open}
      modalLabel="Document versions"
      modalHeading="Link an existing document"
      onRequestClose={close}
      onRequestSubmit={() => {
        (async () => {
          await submit();
          close();
        })();
      }}
      primaryButtonDisabled={!canSubmit || submitting}
      className={styles.langVersionModal}
      primaryButtonText={submitting ? "Linking..." : "Link"}
      secondaryButtonText="Cancel"
    >
      <div className={styles.modal}>
        <FormGroup legendText="Select document">
          <DocumentSelect
            idKey="dbid"
            additionalSearchOptions={{
              language__exclude: [...props.presentLanguages].join("__"),
            }}
            onChange={(d: DocumentSearch[]) => setSelectedDoc(d[0])}
            selectProps={{
              isMulti: false,
              filterOption: (option: any, input: string) => {
                const label = option?.label ? option.label.toLowerCase().trim() : "";
                const protocol = option?.data?.protocol ? option.data.protocol.toLowerCase().trim() : "";
                return label.includes(input.toLowerCase()) || protocol.includes(input.toLowerCase());
              },
            }}
            value={selectedDoc ? [selectedDoc.dbid] : []}
          />
        </FormGroup>
      </div>
    </Modal>
  );
});

interface Props {
  doc: Document;
  showModal: boolean;
  setShowModal: (v: boolean) => void;
  updateExactDocFields: (proto: string, keysToEdit: (keyof Document)[]) => Promise<void>;
}

export const DocumentLangVersion = observer(
  ({ item, onRemove, protocol }: { item: DocLangVersion; onRemove: () => Promise<any>; protocol: DocumentProto }) => {
    const [loading, setLoading] = useState(false);
    const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
    const showConfirmationModal = () => setConfirmationModalVisible(true);
    const hideConfirmationModal = () => setConfirmationModalVisible(false);
    const remove = async () => {
      setLoading(true);
      hideConfirmationModal();
      try {
        await langVersionStore.deleteLangVersion(protocol, item.protocol);
        await onRemove();
      } catch (e) {
        console.log("remove lang version error", e);
      } finally {
        setLoading(false);
      }
    };
    return (
      <div className={cn(["df fdr fsb w-100", styles.listItem])}>
        <ListItem className="w-100">
          <div className="w-100">
            <NavLink className="wfp--link fs-14 f-100" to={paths.construct.docDetail(item.protocol)}>
              {`${item.title} (${languageStore.idToText(item.language)})`}
            </NavLink>
            <Text className={styles.listItemProtocol + " fs-12 text-02"}>{item.protocol}</Text>
          </div>
        </ListItem>
        <div className="df fdr fvs">
          {loading && <InlineLoading />}
          <Button
            disabled={loading}
            onClick={showConfirmationModal}
            small
            kind="danger"
            className="fs-12"
            title="Remove Language Relation"
          >
            Remove
          </Button>
        </div>
        <Modal
          open={confirmationModalVisible}
          modalHeading="Are you sure?"
          onRequestSubmit={remove}
          onRequestClose={hideConfirmationModal}
          onSecondarySubmit={hideConfirmationModal}
          secondaryButtonText="Cancel"
          primaryButtonText="I am sure"
        >
          <p className="wfp--text">
            You are about to delink this language version from others. This action will not delete the document, but
            only remove its language relations.
          </p>
          <p className="wfp--text wfp--text__bold">Do you want to continue?</p>
        </Modal>
      </div>
    );
  }
);

const DocumentLangVersionSelect = observer((props: Props) => {
  const { doc, updateExactDocFields } = props;
  const showModal = props.showModal;
  const setShowModal = props.setShowModal;
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const versions = langVersionStore.loaded.get(doc.protocol) ?? [];
  const [editingId] = isEditing();

  useEffect(() => {
    if (doc.protocol) {
      langVersionStore.versionsFor(doc.protocol);
    }
  }, [doc.protocol]);

  const renderedVersions = useMemo(() => {
    if (versions.length === 0) {
      return <div className="text-02 fs-14">No language versions linked</div>;
    }
    return versions.map((item) => {
      return (
        <DocumentLangVersion
          key={`lang-version-${item.uuid}`}
          protocol={doc.protocol}
          item={item}
          onRemove={() => updateExactDocFields(editingId, ["translations", "trans_group", "trans_group_id"])}
        />
      );
    });
  }, [versions, doc.protocol]);

  const presentLanguages = useMemo(() => new Set([doc.language, ...versions.map((a) => a.language)]), [versions]);

  return (
    <div>
      <div className="df jcsb fdr fac">
        <Text className="fs-14">
          <strong>Language versions</strong>
        </Text>
        <div>
          <Button kind="primary" onClick={() => setShowModal(true)} small className="mr-8 fs-12">
            Add language version
          </Button>
          <Button kind="secondary" onClick={() => setShowLinkModal(true)} small className="fs-12">
            Link
          </Button>
        </div>
      </div>
      <List className={styles.relDocsList} kind="details">
        {renderedVersions}
      </List>
      <AddVersionModal
        addVersionCallback={() => updateExactDocFields(editingId, ["translations", "trans_group", "trans_group_id"])}
        presentLanguages={presentLanguages}
        open={showModal}
        close={() => setShowModal(false)}
        doc={props.doc}
      />
      <LinkVersionModal
        linkVersionCallback={() => updateExactDocFields(editingId, ["translations", "trans_group", "trans_group_id"])}
        presentLanguages={presentLanguages}
        open={showLinkModal}
        close={() => setShowLinkModal(false)}
        doc={props.doc}
      />
    </div>
  );
});

export default DocumentLangVersionSelect;
