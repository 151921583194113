import React, { useState } from "react";
import Page, { PageBreadcrumbs } from "~/components/Page";
import styles from "./styles.module.scss";
import { Breadcrumb, BreadcrumbHome, BreadcrumbItem, Tab, Tabs, Text } from "@wfp/ui";
import { Link } from "react-router-dom";
import paths from "~/paths";
import TabContent from "~/pages/PersonalArea/TabContent";

type Props = {
  breadcrumbsItems: {
    href?: string;
    label: string;
  }[];
  tabs: { id: string; label: string; render: (selected: boolean) => React.ReactNode }[];
  onTabChange?: (tabId: string) => void;
  defaultSelectedTab?: number;
  pageTitle: string;
  withTabContentBackground?: boolean;
  withTabContentPadding?: boolean;
};

const TabsPageView = (props: Props) => {
  const {
    breadcrumbsItems,
    tabs,
    defaultSelectedTab,
    pageTitle,
    withTabContentBackground = true,
    withTabContentPadding = true,
  } = props;
  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedTab || 0);

  const selectedId = tabs[selectedIndex].id;

  return (
    <Page title={pageTitle} className={styles.page}>
      {tabs.length && (
        <PageBreadcrumbs>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to={paths.construct.index()}>
                <BreadcrumbHome />
              </Link>
            </BreadcrumbItem>
            {breadcrumbsItems.map((item) => (
              <BreadcrumbItem key={`breadcrumb-${item.label}`} disableLink={!item.href}>
                <Link to={item.href} style={item.href ? {} : { color: "initial", textDecoration: "none" }}>
                  {item.label}
                </Link>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
        </PageBreadcrumbs>
      )}

      <div className={styles.header}>
        <Text kind="h1">{pageTitle}</Text>
      </div>

      <div className={styles.tabs} data-testid="tab-view">
        <Tabs
          selected={selectedIndex}
          onSelectionChange={(i: number) => {
            const tab = tabs[i];
            setSelectedIndex(i);
          }}
        >
          {tabs.map((tab, idx) => (
            <Tab
              key={`tab-${tab.label}-${tab.id}`}
              label={tab.label}
              className={styles.tab}
              tabIndex={idx}
              selected={selectedId === tab.id}
            >
              <TabContent withBackground={withTabContentBackground} withPadding={withTabContentPadding}>
                {tab.render(selectedId === tab.id)}
              </TabContent>
            </Tab>
          ))}
        </Tabs>
      </div>
    </Page>
  );
};

export default TabsPageView;
