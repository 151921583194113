import { Document } from "~/document";
import styles from "./styles.module.scss";
import cn from "classnames";
import { iconHeaderDocs } from "@wfp/icons";
import { Icon } from "@wfp/ui";

interface Props {
  doc: Document;
  className?: string;
}

const NoPreviewAvailable = () => (
  <>
    <div className={styles.missing}>
      <div className="mr-2">No preview available</div>
    </div>
  </>
);

const DocumentPreview = (props: Props) => {
  const docPreviewUrl = props.doc.file_preview;
  return (
    <div className={cn([styles.documentPreview, props.className])}>
      {docPreviewUrl ? (
        <div className={styles.documentPreviewInner}>
          {" "}
          <img src={`data:image/png;base64, ${docPreviewUrl}`} />
        </div>
      ) : (
        <NoPreviewAvailable />
      )}
    </div>
  );
};

export default DocumentPreview;
