import React, { useContext, useEffect, useMemo, useState } from "react";
import { ListItem, FormGroup, Modal, List, Text, Button, InlineLoading } from "@wfp/ui";
import styles from "./styles.module.scss";
import {
  docRelationTypeString,
  DocumentRelationType,
  DocRelation,
  Document,
  DocumentRelationTypeId,
  DocumentSearch,
} from "~/document";
import cn from "classnames";
import DocumentSelect from "~/components/DocumentSelect";
import DocumentRelationSelect from "~/components/DocumentRelationSelect";
import paths from "~/paths";
import { NavLink } from "react-router-dom";
import { observer } from "mobx-react";
import { ToastNotificationContext } from "~/components/ToastNotificationContext";
import { useNavigate } from "react-router";
import documentStore from "~/stores/document";

type OnAdd = (doc: Document, relationType: DocumentRelationTypeId, related: DocumentSearch) => Promise<Document | null>;

interface LinkDocModalProps {
  submitting: boolean;
  doc: Document;
  close: () => void;
  open: boolean;
  onAdd: OnAdd;
  presentRelations: Set<DocumentRelationTypeId>;
}

const LinkDocModal = (props: LinkDocModalProps) => {
  const [selectedDoc, setSelectedDoc] = useState<DocumentSearch | null>(null);
  const [type, setType] = useState<DocumentRelationType | null>(null);
  const navigate = useNavigate();
  const { setNotificationData, showNotificationWithTimeout } = useContext(ToastNotificationContext);
  const canSubmit = type !== null && selectedDoc !== null;

  const submit = async () => {
    const res = await props.onAdd(props.doc, type!.id, selectedDoc!);
    props.close();
    if (res) {
      setNotificationData({ kind: "success", title: "Success", subtitle: "Document relation added successfully" });
      showNotificationWithTimeout();
      navigate(paths.construct.docDetail(props.doc.protocol));
    } else {
      setNotificationData({ kind: "error", title: "Error", subtitle: "Document relation couldn't be added" });
      showNotificationWithTimeout();
    }
  };
  useEffect(() => {
    if (props.open) {
      setSelectedDoc(null);
      setType(null);
    }
  }, [props.open]);

  const submitting = props.submitting;
  return (
    <Modal
      open={props.open}
      className="overflow-visible"
      modalHeading="Add relation"
      modalLabel="Document relations"
      onRequestClose={props.close}
      onRequestSubmit={submit}
      primaryButtonDisabled={!canSubmit || submitting}
      primaryButtonText={submitting ? "Adding..." : "Confirm"}
      secondaryButtonText="Cancel"
    >
      <div className={styles.linkRelDocModal}>
        <FormGroup legendText="Select document">
          <DocumentSelect
            onChange={(d: DocumentSearch[]) => setSelectedDoc(d[0])}
            idKey="dbid"
            selectProps={{
              isMulti: false,
              filterOption: (option: any, input: string) => {
                const label = option?.label ? option.label.toLowerCase().trim() : "";
                const protocol = option?.data?.protocol ? option.data.protocol.toLowerCase().trim() : "";
                return label.includes(input.toLowerCase()) || protocol.includes(input.toLowerCase());
              },
            }}
            value={selectedDoc ? [selectedDoc.dbid] : []}
          />
        </FormGroup>
        <FormGroup legendText="Relation type">
          <DocumentRelationSelect
            filter={(item) => {
              return !props.presentRelations.has(item.id);
            }}
            onChange={(d) => setType(d[0])}
            selectProps={{ isMulti: false }}
            value={type ? [type] : []}
          />
        </FormGroup>
      </div>
    </Modal>
  );
};

interface RelatedDocumentsSelectProps {
  doc: Document;
  docs: DocRelation[];
  onAdd: OnAdd;
  showModal: boolean;
  setShowModal: (v: boolean) => void;
  submitting: boolean;
}

const RelatedDocument = observer(({ item, doc }: { item: DocRelation; doc: Document }) => {
  const [loading, setLoading] = useState(false);
  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const showConfirmationModal = () => setConfirmationModalVisible(true);
  const hideConfirmationModal = () => setConfirmationModalVisible(false);
  const remove = () => {
    setLoading(true);
    hideConfirmationModal();
    documentStore.removeRelatedDoc(doc.protocol, doc.id, item.other.id, item.type).finally(() => setLoading(false));
  };
  return (
    <div className={cn(["df fdr fsb w-100", styles.listItem])}>
      <ListItem className="w-100">
        <div>
          <NavLink className="wfp--link fs-16 f-100" to={paths.construct.docDetail(item.other.protocol)}>
            {item.other.title}
          </NavLink>
          <Text className="wfp--text fs-12 text-02">{docRelationTypeString(item)}</Text>
        </div>
      </ListItem>
      <div className="df fdr fvs">
        {loading && <InlineLoading />}
        <Button
          onClick={showConfirmationModal}
          disabled={loading}
          small
          kind="danger"
          className="fs-12"
          title="Remove Language Relation"
        >
          Remove
        </Button>
      </div>
      <Modal
        open={confirmationModalVisible}
        modalHeading="Are you sure?"
        onRequestSubmit={remove}
        onRequestClose={hideConfirmationModal}
        onSecondarySubmit={hideConfirmationModal}
        secondaryButtonText="Cancel"
        primaryButtonText="I am sure"
      >
        <p className="wfp--text">You are about to remove a relation.</p>
        <p className="wfp--text wfp--text__bold">Do you want to continue?</p>
      </Modal>
    </div>
  );
});

const RelatedDocumentsSelect = observer((props: RelatedDocumentsSelectProps) => {
  const { docs, onAdd } = props;
  const showLinkDocModal = props.showModal;
  const setShowLinkDocModal = props.setShowModal;
  const renderedVersions = useMemo(() => {
    if (docs.length === 0) {
      return <div className="text-02 fs-14">No related documents</div>;
    }
    return docs.map((item) => {
      return <RelatedDocument item={item} key={`related-doc-${item.id}`} doc={props.doc} />;
    });
  }, [docs]);

  const presentRelations = useMemo(() => new Set([...docs.map((a) => a.id!)]), [docs]);

  return (
    <div>
      <div className="df jcsb fdr fac">
        <Text className="fs-14">
          <strong>Related documents</strong>
        </Text>
        <Button kind="primary" onClick={() => setShowLinkDocModal(true)} small className="fs-12">
          Link related document
        </Button>
      </div>
      <List className={styles.relDocsList} kind="details">
        {renderedVersions}
      </List>
      <LinkDocModal
        submitting={props.submitting}
        open={showLinkDocModal}
        onAdd={onAdd}
        doc={props.doc}
        presentRelations={presentRelations}
        close={() => setShowLinkDocModal(false)}
      />
    </div>
  );
});

export default RelatedDocumentsSelect;
