import { makeAutoObservable } from "mobx";
import { DocSeriesInfo } from "~/document";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import notifStore, { NotificationStore } from "./notifications";

export class DocSeriesStore {
  f = new PaginatedEndpoint<DocSeriesInfo>("series", notifStore);

  get loaded() {
    return this.f.items;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const docSeriesStore = new DocSeriesStore();

export default docSeriesStore;
