import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { Button, TextInput } from "@wfp/ui";
import Select from "react-select";
import "react-dates/initialize";
import meetingStore, { MeetingNode, meetingStatusMap } from "~/stores/meeting";
import DynamicSuggestionInput from "~/components/DynamicSuggestionInput/DynamicSuggestionInput";
import { observer } from "mobx-react";
import { runInAction } from "mobx";
import { formatDateReadable, paramsToObject } from "~/util";
import { DATE_API_FORMAT } from "~/constants";
import { SingleDatePicker } from "~/components/PublicationDateSelect";
import { useSearchParams } from "react-router-dom";
import meetingTypeStore from "~/stores/meetingTypes";
import { SortingRule } from "react-table";

type MeetingsFilter = {
  title?: string;
  meeting_type?: string;
  status?: string | number;
  codes?: string[];
  event_type?: string | number;
  start_date_from?: string;
  start_date_to?: string;
  start_date_year?: string | number;
};

type Props = { setSortBy: (sortBy: SortingRule<MeetingNode>[]) => void };

const MeetingsSearch = observer(({ setSortBy }: Props) => {
  const [filterState, setFilterState] = useState<MeetingsFilter>({});
  let [searchParams, setSearchParams] = useSearchParams();

  const onTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target as unknown as { value: string };
    setFilterState((prev) => ({
      ...prev,
      title: value,
    }));
  };

  const onDateChange = (key: keyof MeetingsFilter) => (date: Date) => {
    setFilterState((prev) => ({ ...prev, [key]: formatDateReadable(date, DATE_API_FORMAT) }));
  };

  const [selectedMeetingCodes, setSelectedMeetingCodes] = useState<string[]>([]);
  const addMeetingCode = (code: string) => setSelectedMeetingCodes((prev) => [...prev, code]);
  const removeMeetingCode = (code: string) => setSelectedMeetingCodes((prev) => prev.filter((item) => item !== code));
  const meetingYearOptions = useMemo(
    () => Array.from(Array(new Date().getFullYear() - 1995)).map((_, i) => new Date().getFullYear() - i),
    []
  );

  const onSelectChange =
    (key: keyof MeetingsFilter) =>
    ({ value }: { label: string | number; value: string | number }) => {
      setFilterState((prev) => ({ ...prev, [key]: value }));
    };

  const getOptions = useCallback(
    (options: (string | number)[], values?: (string | number)[]) =>
      options.map((item, i) => ({ label: item.toString(), value: values?.[i] || item.toString() })),
    []
  );
  const getOptionValue = useCallback((value: string | number) => (value ? { label: value, value } : null), []);

  const applyFilters = useCallback(() => {
    const filters = { ...filterState, codes: selectedMeetingCodes.length ? selectedMeetingCodes.join() : undefined };
    const search = Object.entries(filters)
      .filter(([label, val]) => !!val)
      .map(([label, value]) => `${label}=${value}&`)
      .join("");
    setSearchParams(search);
    setSortBy([{ id: "start_date", desc: true }]);
  }, [filterState, selectedMeetingCodes]);

  const clearFilters = useCallback(() => {
    setSelectedMeetingCodes([]);
    setSearchParams("");
    setSortBy([{ id: "start_date", desc: false }]);
  }, []);

  const meetingTypeOptions = useMemo(() => meetingTypeStore.meetingTypesList, [meetingTypeStore.meetingTypesList]);
  const meetingTypes = useMemo(() => meetingTypeStore.meetingTypes, [meetingTypeStore.meetingTypes]);

  useEffect(() => {
    const paramsObj = paramsToObject(searchParams.entries()) as MeetingsFilter & { codes?: string };
    runInAction(() => {
      meetingStore.meetingsQueryParams = paramsObj;
    });

    setFilterState({
      ...paramsObj,
      codes: [],
    });
    setSelectedMeetingCodes(paramsObj.codes ? paramsObj.codes.split(",") : []);
  }, [searchParams]);

  useEffect(() => {
    meetingTypeStore.loadMeetingTypes();
  }, []);

  return (
    <div className={styles.search}>
      <div className={styles.searchRow}>
        <div className={styles.searchRowItem}>
          <TextInput onChange={onTitleChange} value={filterState.title || ""} placeholder="Search title..." />
        </div>
        <Select
          onChange={onSelectChange("meeting_type")}
          placeholder="Select meeting type"
          classNamePrefix="select"
          className={styles.searchRowItem}
          options={meetingTypeOptions}
          value={
            filterState.meeting_type
              ? { label: meetingTypes[filterState.meeting_type], value: filterState.meeting_type }
              : null
          }
        />
        <div className={styles.searchRowItem}>
          <DynamicSuggestionInput
            load={meetingStore.searchMeetingCodes}
            loading={meetingStore.loadingCodes}
            results={meetingStore.meetingCodesSearch.results}
            currentSearchValue={meetingStore.meetingCodesSearch.search}
            shouldBeCleared={meetingStore.codesSearchEmpty}
            addItem={addMeetingCode}
            removeItem={removeMeetingCode}
            selected={selectedMeetingCodes}
            inputProps={{
              placeholder: "Meeting code",
            }}
          />
        </div>
      </div>
      <div className={styles.searchRow}>
        <Select
          placeholder="Status"
          classNamePrefix="select"
          className={styles.searchRowItem}
          getOptionLabel={(option) => meetingStatusMap[option.value]}
          onChange={onSelectChange("status")}
          options={getOptions(Object.values(meetingStatusMap), Object.keys(meetingStatusMap))}
          value={getOptionValue(filterState.status)}
        />
        <Select
          placeholder="Year"
          classNamePrefix="select"
          className={styles.searchRowItem}
          onChange={onSelectChange("start_date_year")}
          options={getOptions(meetingYearOptions)}
          value={getOptionValue(filterState.start_date_year)}
        />
        <div>
          <SingleDatePicker
            onChange={onDateChange("start_date_from")}
            allowPast
            placeholder="From date"
            helperText=""
            value={filterState.start_date_from ? new Date(filterState.start_date_from) : null}
          />
        </div>
        <div>
          <SingleDatePicker
            onChange={onDateChange("start_date_to")}
            allowPast
            placeholder="To date"
            helperText=""
            value={filterState.start_date_to ? new Date(filterState.start_date_to) : null}
          />
        </div>
      </div>
      <div className={styles.searchActions}>
        <Button onClick={clearFilters} kind="ghost">
          Clear
        </Button>
        <Button onClick={applyFilters}>Apply filters</Button>
      </div>
    </div>
  );
});

export default MeetingsSearch;
