import { useState, useEffect, ComponentProps } from "react";
import { TextInput } from "@wfp/ui";
import { useDebounce } from "~/util/hooks";

type TextInputProps = ComponentProps<typeof TextInput> & { value: string; onChange: (value: string) => void };
const BouncedTextInput = (props: TextInputProps) => {
  const [value, setValue] = useState(props.value ? props.value : "");
  useEffect(() => {
    if (!props.value) {
      setValue("");
    } else if (value !== props.value) {
      setValue(props.value);
    }
  }, [props.value]);
  const onChange = useDebounce(() => {
    props.onChange(value);
  }, 1000);
  return (
    <TextInput
      {...props}
      value={value}
      onChange={(e: any) => {
        setValue(e.target.value);
        onChange();
      }}
    />
  );
};

export default BouncedTextInput;
