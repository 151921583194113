import React, { useEffect, useState } from "react";
import Page from "~/components/Page";
import AdvancedSearchMenu from "~/components/AdvancedSearchMenu";
import SearchBarContext from "~/SearchBarContext";
import SearchQuery from "~/searchQuery";
import BackToSearchResultsButton from "~/components/BackToSearchResultsButton";
import { Container, Row, Col } from "react-grid-system";
import { useQueryURLSearchParams } from "~/util/hooks";

const HomePage = () => {
  const [searchQuery, setSearchQuery] = useState(new SearchQuery());

  // Get query from the url
  const pageQuery = useQueryURLSearchParams();
  useEffect(() => {
    const newQuery = SearchQuery.fromQueryString(pageQuery);
    if (newQuery.isValid()) {
      setSearchQuery(newQuery);
    }
  }, [pageQuery.toString()]);

  return (
    <SearchBarContext.Provider value={{ searchQuery, setSearchQuery }}>
      <Page title="Advanced search">
        <div className="p-16">
          <div className="mb-24">
            <BackToSearchResultsButton />
          </div>
          <Container className="pv-16" style={{ paddingLeft: 0, paddingRight: 0, margin: 0 }} fluid>
            <Row>
              <Col xs={12} md={12} lg={9}>
                <AdvancedSearchMenu />
              </Col>
            </Row>
          </Container>
        </div>
      </Page>
    </SearchBarContext.Provider>
  );
};

export default HomePage;
