import { Footer as WFPFooter } from "@wfp/ui";
import styles from "./styles.module.scss";

const Footer = () => {
  return (
    <WFPFooter
      pageWidth="full"
      external={false}
      className={styles.footer}
      metaContent={<div className="w-100 df fdrr">2022 &copy; World Food Programme</div>}
    ></WFPFooter>
  );
};

export default Footer;
