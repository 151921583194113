import { Document } from "~/document";
import paths from "~/paths";
import { useMemo } from "react";
import { Column, usePagination, useTable } from "react-table";
import { PaginatedResult } from "~/util/PaginatedEndpoint";
import moment from "moment";
import { DOC_PUB_DATE_FORMAT } from "~/document";
import { Link } from "react-router-dom";
import { FetcherState, TITLE_MAX_CHARS } from "./common";
import TabContent from "./TabContent";
import TabTable from "./TabTable";
import styles from "./styles.module.scss";
import { UserInfo } from "~/user";

const DocsTable = (props: {
  fetcher: FetcherState<Document>;
  docsResult: PaginatedResult<Document>;
  user?: UserInfo;
}) => {
  const docs = props.docsResult;
  const data = useMemo(() => docs.results as any, [docs]);
  const columns: Column<Document>[] = useMemo(
    () =>
      [
        {
          Header: "Title",
          accessor: "title",
          Cell: (props) => {
            const doc = props.row.original;
            let title = doc.title;
            title = title.length > TITLE_MAX_CHARS ? `${title.substr(0, TITLE_MAX_CHARS)}...` : title;
            return (
              <div className={styles.longCell}>
                <Link to={paths.construct.docDetail(doc.protocol)}>{title}</Link>
              </div>
            );
          },
        },
        {
          Header: "Language",
          accessor: "language_display",
        },
        {
          Header: "Publication date",
          accessor: "publication_date",
          Cell: (props) => (props.value ? moment(props.value).format(DOC_PUB_DATE_FORMAT) : "N/A"),
        },
        {
          Header: "Release date",
          accessor: "release_date",
          Cell: (props) => (props.value ? moment(props.value).format(DOC_PUB_DATE_FORMAT) : "N/A"),
        },
        {
          Header: "Expiration date",
          accessor: "expiration_date",
          Cell: (props) => (props.value ? moment(props.value).format(DOC_PUB_DATE_FORMAT) : "N/A"),
        },
        {
          Header: "Documents manager",
          accessor: "owner_display",
          Cell: ({ value, cell }) => {
            const owner = cell.row.original.owner;
            if (+owner === props.user?.user) {
              return props.user?.username || props.user?.email || "N/A";
            }
            return value || "N/A";
          },
        },
        {
          Header: "Last modified by",
          accessor: "last_modified_user",
        },
        {
          Header: "Office division",
          accessor: "office_display",
          Cell: (props) => props.value || "N/A",
        },
        {
          Header: "Document ID",
          accessor: "protocol",
        },
      ] as Column<Document>[],
    [props.user]
  );
  const tableInstance = useTable({ columns, data }, usePagination);
  return (
    <TabTable
      fetcher={props.fetcher}
      tableInstance={tableInstance}
      emptyTitle="No documents"
      emptyMessage="You haven't uploaded any documents"
    />
  );
};

export default DocsTable;
