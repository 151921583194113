import countryStore, { CountryId } from "~/stores/country";
import { observer } from "mobx-react";
import AsyncPaginatedSelect from "~/components/AsyncPaginatedSelect";

interface Props {
  value: CountryId[];
  onChange: (newValue: CountryId[]) => void;
}

const CountrySelect = observer(({ value, onChange }: Props) => {
  const fetcher = countryStore.f;
  const idToOpt = (id: CountryId) => {
    const item = fetcher.items[id];
    if (!item) return null;
    return {
      label: item.name,
      value: item.id,
    };
  };
  return (
    <AsyncPaginatedSelect
      fetcher={fetcher}
      itemIdToOption={idToOpt}
      value={value}
      onChange={onChange}
      selectProps={{
        isMulti: true,
      }}
    />
  );
});

export default CountrySelect;
