import { InputGroup, RadioButton } from "@wfp/ui";

interface Props {
  value:  number | null | undefined;
  onChange: (a: string | number | null) => void;
  disabled?: boolean;
}


const PDFOptionSelect = (props: Props) => {
  const { value, onChange } = props;
  const disabled = props.disabled ?? false;
  const pdfOptions = [
    { value: "PDF Not Allowed", label: "Do not convert to PDF" },
    { value: "PDF Allowed", label: "Convert to PDF" },
    // { value: "PDF Preferred download type", label: "PDF Preferred download type" },
    // { value: "Only allow pdf format", label: "Only allow pdf format" },
  ];

  return (
    <InputGroup name="input-group" vertical>
      {pdfOptions.map((opt) => {
        // const vs = String(pdfOptionsMapping(opt.value));
        const vs = String(opt.value);
        const checked = String(value) === vs;
        return (
          <RadioButton
            key={vs}
            id={vs}
            labelText={opt.label}
            name={vs}
            onChange={(a) => onChange(String(a.target.value) || null)}
            checked={checked}
            value={vs}
            disabled={disabled}
          />
        );
      })}
    </InputGroup>
  );
};

export default PDFOptionSelect;
