import React, { useEffect, useMemo, useState } from "react";
import TabsPageView from "~/components/TabsPageView";
import styles from "~/pages/Meetings/styles.module.scss";
import { Button, FormLabel, Link, Loading, Tag } from "@wfp/ui";
import { Link as NavLink } from "react-router-dom";
import meetingStore, { MeetingLanguage, meetingLanguagesList, MeetingNode, meetingStatusMap } from "~/stores/meeting";
import { useParams } from "react-router";
import { formatDateReadable, ReqStatus } from "~/util";
import { useLocalizedFieldsGetter } from "~/util/hooks";
import { CommonTabProps } from "~/pages/PersonalArea/TabTable";
import { observer } from "mobx-react";
import paths from "~/paths";
import { MeetingInfoTab } from "~/pages/Meetings/MeetingsUpload";
import { AgendaItem } from "~/stores/agendaItems";
import agendaItemDocsStore from "~/stores/agendaItemDocs";
import cn from "classnames";
import moment from "moment";
import AgendaItemDocSupplementaryInfo from "~/components/AgendaItems/AgendaItem/AgendaItemDocSupplementaryInfo";
import meetingTypeStore from "~/stores/meetingTypes";

type Props = {
  lang: MeetingLanguage;
  meeting?: MeetingNode;
} & CommonTabProps;

const AgendaItemComponent = observer(
  ({ agendaItem, localizedTitle }: { agendaItem: AgendaItem; localizedTitle: string }) => {
    const [expanded, setExpanded] = useState(false);
    const toggleExpanded = () => setExpanded((prev) => !prev);
    const relatedDocs = useMemo(
      () => agendaItemDocsStore.documentsByAgendaItem[agendaItem.id] || [],
      [agendaItemDocsStore.documentsByAgendaItem, agendaItem.id]
    );

    useEffect(() => {
      agendaItemDocsStore.loadRelatedDocuments(agendaItem.id);
    }, [agendaItem.id]);

    return useMemo(
      () => (
        <>
          <div className={styles.meetingRow} style={{ width: "100%", margin: "0" }}>
            <div>
              <div className={styles.meetingRowItem}>{agendaItem[localizedTitle] || agendaItem.title_en}</div>
              {!!relatedDocs.length && (
                <div className={styles.meetingRowItem}>
                  <Link onClick={toggleExpanded}>{expanded ? "Collapse documents" : "Expand documents"}</Link>
                </div>
              )}
            </div>
          </div>
          <div className="mb-16">
            {expanded &&
              relatedDocs.map((doc) => (
                <div className="mt-16" key={`related-doc-${agendaItem.id}-${doc.id}`}>
                  <NavLink to={paths.construct.docDetail(doc.protocol)} className="wfp--link">
                    {doc.title}
                  </NavLink>
                  <div className="mt-4">
                    <AgendaItemDocSupplementaryInfo doc={doc} />
                  </div>
                </div>
              ))}
          </div>
        </>
      ),
      [agendaItemDocsStore.documentsByAgendaItem, expanded, agendaItem, localizedTitle]
    );
  }
);

const SingleMeetingTab = observer(({ lang, meeting, selected }: Props) => {
  const getLocalizedField = useLocalizedFieldsGetter(lang);
  const loading = meetingStore.status === ReqStatus.InProcess;

  const [agendaItemsShowMore, setAgendaItemsShowMore] = useState(false);
  const toggleAgendaItemsShowMore = () => setAgendaItemsShowMore((prev) => !prev);

  if (loading) {
    return <Loading />;
  }

  if (!meeting || !selected) {
    return null;
  }

  const shouldDisplayTimeSchedule =
    meeting.time_schedule?.length > 1 || meeting.start_time !== "00:00:00" || meeting.end_time !== "00:00:00";
  const meetingStatus = meetingStatusMap[meeting.status];

  return (
    <div className={styles.meeting}>
      {!!meeting && (
        <>
          <MeetingInfoTab infoVisible meeting={meeting} />
          <div className={styles.meetingBody}>
            <h2 className={styles.meetingTitle}>{meeting[getLocalizedField("title")] || meeting.title_en}</h2>
            <div className={styles.meetingRow}>
              <div style={{ alignItems: "flex-start" }}>
                <div className={styles.meetingColumn}>
                  <div className={styles.meetingColumnItem}>
                    <FormLabel className={styles.meetingLabel}>Meeting Type</FormLabel>
                    <div className={styles.meetingValue}>
                      {meetingTypeStore.meetingTypes[meeting.meeting_type] || meeting.meeting_type}
                    </div>
                  </div>
                  <div className={styles.meetingColumnItem}>
                    <FormLabel className={styles.meetingLabel}>Meeting Code</FormLabel>
                    <div className={styles.meetingValue}>{meeting.code}</div>
                  </div>
                  <div className={styles.meetingColumnItem}>
                    <FormLabel className={styles.meetingLabel}>Status</FormLabel>
                    <div className={styles.meetingValue}>{meetingStatus}</div>
                  </div>
                  {!!meeting[getLocalizedField("notes")] && (
                    <div className={styles.meetingColumnItem}>
                      <FormLabel className={styles.meetingLabel}>Public Meeting Notes</FormLabel>
                      <div
                        className={styles.meetingValue}
                        dangerouslySetInnerHTML={{ __html: meeting[getLocalizedField("notes")].toString() }}
                      ></div>
                    </div>
                  )}
                </div>
                <div className={styles.meetingColumn}>
                  <h5 className={styles.meetingColumnTitle}>Meeting dates</h5>
                  <div className={styles.meetingColumnItem}>
                    <FormLabel className={styles.meetingLabel}>Start date</FormLabel>
                    <div className={styles.meetingValue}>{formatDateReadable(meeting.start_date)}</div>
                  </div>
                  {!!meeting.end_date && (
                    <div className={styles.meetingColumnItem}>
                      <FormLabel className={styles.meetingLabel}>End date</FormLabel>
                      <div className={styles.meetingValue}>{formatDateReadable(meeting.end_date)}</div>
                    </div>
                  )}
                  {!!meeting.venue_display && (
                    <div className={styles.meetingColumnItem}>
                      <FormLabel className={styles.meetingLabel}>Venue</FormLabel>
                      <div className={styles.meetingValue}>{meeting.venue_display}</div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {shouldDisplayTimeSchedule && (
              <div className={styles.timeWrapper}>
                <h5>Time Schedule</h5>
                {meeting.time_schedule.map((time) => (
                  <div key={`time-schedule-${time.id}}`} className={styles.time}>
                    <div>
                      <FormLabel className={styles.meetingLabel}>{moment(time.day).format("DD/MM/YYYY")}</FormLabel>
                      {!!time.default && <div className="fs-14">Default</div>}
                    </div>
                    <div className={styles.timeBody}>
                      <div className={styles.timeBodyColumn}>
                        <div className={styles.timeBodyRow}>
                          <FormLabel className={cn(styles.meetingLabel, styles.timeBodyLabel)}>Start Time</FormLabel>
                          <div>{time.start_time_1}</div>
                        </div>
                        <div className={styles.timeBodyRow}>
                          <FormLabel className={cn(styles.meetingLabel, styles.timeBodyLabel)}>End Time</FormLabel>
                          <div>{time.end_time_1}</div>
                        </div>
                      </div>

                      {!!time.start_time_2 && !!time.end_time_2 && (
                        <>
                          <Tag type="info">Break</Tag>
                          <div className={styles.timeBodyColumn}>
                            <div className={styles.timeBodyRow}>
                              <FormLabel className={cn(styles.meetingLabel, styles.timeBodyLabel)}>
                                Start Time
                              </FormLabel>
                              <div>{time.start_time_2}</div>
                            </div>
                            <div className={styles.timeBodyRow}>
                              <FormLabel className={cn(styles.meetingLabel, styles.timeBodyLabel)}>End Time</FormLabel>
                              <div>{time.end_time_2}</div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!!meeting.agenda_items?.length && (
              <div className={styles.meetingColumn} style={{ marginTop: 30 }}>
                <h3 className={styles.meetingColumnTitle} style={{ marginBottom: 25 }}>
                  Agenda Items
                </h3>
                {(agendaItemsShowMore ? meeting.agenda_items : meeting.agenda_items.slice(0, 3)).map((agendaItem) => (
                  <AgendaItemComponent
                    key={`${agendaItem.id}-agenda-item-${meeting.id}`}
                    agendaItem={agendaItem}
                    localizedTitle={getLocalizedField("title")}
                  />
                ))}
                {meeting.agenda_items.length > 3 && (
                  <Link onClick={toggleAgendaItemsShowMore} className="mt-6">
                    {agendaItemsShowMore ? "Show less ▲" : "Show more ▼"}
                  </Link>
                )}
              </div>
            )}
            <div className={`${styles.meetingActions} mt-32`}>
              <NavLink to={paths.construct.cloneMeeting(+meeting.id)}>
                <Button kind="secondary" className="mr-24">
                  Clone
                </Button>
              </NavLink>

              <NavLink to={paths.construct.editMeeting(+meeting.id)}>
                <Button kind="secondary">Edit</Button>
              </NavLink>
            </div>
          </div>
        </>
      )}
    </div>
  );
});

const SingleMeetingPage = observer(() => {
  const { meetingId } = useParams();
  const [meeting, setMeeting] = useState<MeetingNode>();
  useEffect(() => {
    meetingStore.loadSingleMeeting(meetingId).then((meetingRes) => setMeeting(meetingRes));
    meetingTypeStore.loadMeetingTypes();
  }, [meetingId]);
  return (
    <>
      <NavLink to="/meetings">
        <Button className="m-16" kind="secondary">
          Back to Meetings List
        </Button>
      </NavLink>

      <TabsPageView
        breadcrumbsItems={[{ label: "Meetings", href: "/meetings" }, { label: "Meeting preview" }]}
        tabs={meetingLanguagesList.map((lang) => ({
          label: lang.title,
          id: lang.key,
          render: (selected) => <SingleMeetingTab lang={lang.key} meeting={meeting} selected={selected} />,
        }))}
        pageTitle="Meeting preview"
        withTabContentBackground
        withTabContentPadding={false}
      />
    </>
  );
});

export default SingleMeetingPage;
