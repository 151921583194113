import { DocumentId } from "~/document";

interface AllowedDocType {
  deprecated: boolean;
  enabled: boolean;
  id: DocumentId;
  label: string;
  last_modified_date: Date;
  requires_specific_permission: boolean;
  uuid: string;
  whitelist: null;
}

export enum UserPermission {
  DeleteDoc = "documents.delete_document",
  UploadDoc = "documents.can_upload",
  ViewDoc = "documents.view_document",
  ChangeDoc = "documents.change_document",
  AddDocVersion = "documents.add_documentversion",
  AddDocRelation = "documents.add_documentrelation",
}

export type UserId = number;
export type UserPreferences = null;
export type UserPermissions = Set<UserPermission>;

export interface UserGroup {}

export interface UserInfo {
  username: string | null;
  email: string | null;
  avatar: string | null;
  groups: UserGroup[];
  user: UserId;
  preferences: UserPreferences;
  permissions: UserPermissions;
  is_staff: boolean;
  is_superuser: boolean;
  allowed_document_types: Record<DocumentId, AllowedDocType>;
}
