import React from "react";
import { Button } from "@wfp/ui";

type ButtonProps = any;

interface Props {
  // what to copy on click
  copyContent: string;
  buttonProps?: ButtonProps;
  children: React.ReactNode;
  onCopy: () => void;
  onFail: () => void;
}

const CopyButton = (props: Props) => {
  const onClick = () => {
    navigator.permissions.query({ name: "clipboard-write" } as any).then((result) => {
      if (result.state == "granted" || result.state == "prompt") {
        navigator.clipboard.writeText(props.copyContent).then(
          function () {
            props.onCopy();
          },
          function () {
            props.onFail();
          }
        );
      }
    });
  };

  return (
    <>
      <Button {...(props.buttonProps || {})} onClick={onClick}>
        {props.children}
      </Button>
    </>
  );
};

export default CopyButton;
