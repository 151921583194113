import { makeAutoObservable, runInAction } from "mobx";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import notifStore from "./notifications";
import keyBy from "lodash/keyBy";
import { Document, DocumentId, DocumentProto } from "~/document";
import * as api from "~/api";
import { ReqStatus } from "~/util";

export type BookmarkId = string;
export interface BookmarkInfo {
  id: BookmarkId;
  title: string;
  user_display: string;
  document: DocumentId;
  document_data?: Document;
}

export class BookmarkStore {
  f = new PaginatedEndpoint<BookmarkInfo>("bookmarked_documents", notifStore);
  reqStatus: ReqStatus = ReqStatus.Initial;
  toggleReqStatus: ReqStatus = ReqStatus.Initial;

  constructor() {
    makeAutoObservable(this);
  }

  get byId() {
    return this.f.items;
  }

  get loading() {
    return this.f.loading || this.reqStatus === ReqStatus.InProcess;
  }

  async loadFor(docId: DocumentId): Promise<BookmarkInfo | null> {
    if (this.reqStatus === ReqStatus.InProcess) return null;
    if (this.byId[docId] !== undefined) {
      return this.byId[docId];
    }
    this.reqStatus = ReqStatus.InProcess;
    const resp = await api.get(`documents/${docId}/bookmarks`);
    if (resp === null) return null;
    this.reqStatus = ReqStatus.Success;
    runInAction(() => {
      this.f.items = { ...this.f.items, [docId]: resp.data };
    });
    return resp.data;
  }

  async toggleBookmarkedStatus(doc: Document, newState: boolean): Promise<BookmarkInfo | null> {
    const exists = this.byId[doc.id];
    runInAction(() => {
      this.toggleReqStatus = ReqStatus.InProcess;
    });
    try {
      if (!newState) {
        if (exists) {
          // remove
          const resp = await api.del(`bookmarked_documents/delete_by_document_id/${doc.id}`);
          runInAction(() => {
            this.f.items = { ...this.f.items, [doc.id]: undefined };
            this.toggleReqStatus = ReqStatus.Success;
          });
        }
        return null;
      } else {
        // new bookmark
        const resp = await api.post("bookmarked_documents", {
          title: doc.title,
          document: doc.id,
        });
        if (resp === null) return null;
        runInAction(() => {
          this.f.items = { ...this.f.items, [doc.id]: resp.data };
          this.toggleReqStatus = ReqStatus.Success;
        });
        return resp.data;
      }
    } catch (err) {
      this.toggleReqStatus = ReqStatus.Failed;
      return null;
    }
  }

  isBookmarked(id: Document["id"]): boolean {
    return this.byId[id]?.id !== undefined;
  }
}

const bookmarkStore = new BookmarkStore();

export default bookmarkStore;
