import { useContext } from "react";
import { FormHint, TextInput, List, ListItem, Text, Button, Tag as WFPUITag } from "@wfp/ui";
import { observer } from "mobx-react";
import { Container, Row, Col } from "react-grid-system";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import paths from "~/paths";
import SearchBarContext from "~/SearchBarContext";
import DocumentForm from "~/components/DocumentForm";
import cn from "classnames";
import SearchQuery, { SearchQueryCustom } from "~/searchQuery";

type SearchSummary = Record<string, { value: string | number | string[]; label: string }>;

const SearchSummaryColumn = (props: { searchSummary: SearchSummary }) => {
  return (
    <Col xs={0} md={3} className={cn(["oh"])}>
      <div className={styles.summaryColumn}>
        <Text kind="h6" className="mb-16">
          Search summary
        </Text>
        <List>
          {Object.entries(props.searchSummary).map(([itemTitle, vv]) => {
            const itemValue = vv.value;
            const label = vv.label;
            let value = null;

            if (!itemValue) {
              value = null;
            } else if (itemValue instanceof Array) {
              if (itemValue.length !== 0) {
                value = itemValue.map((v, idx) => (
                  <WFPUITag className={styles.summaryItemTag} key={idx} type="info">
                    {v}
                  </WFPUITag>
                ));
              }
            } else {
              value = (
                <WFPUITag className={styles.summaryItemTag} type="info">
                  {itemValue}
                </WFPUITag>
              );
            }

            return (
              <ListItem
                key={label}
                className={styles.summaryItem}
                title={<Text className={cn(["text-02", styles.itemTitle])}>{label.toUpperCase()}</Text>}
              >
                {value}
              </ListItem>
            );
          })}
        </List>
      </div>
    </Col>
  );
};

const AdvancedSearchMenu = observer(() => {
  const { searchQuery, setSearchQuery } = useContext(SearchBarContext);
  const searchSummary: SearchSummary = searchQuery.toFullJSON();
  const navigate = useNavigate();
  const canSubmit = searchQuery.isOneOfTheFieldsSelected();
  const onSubmit = () => navigate(paths.construct.browse(searchQuery));
  return (
    <Container className={styles.search} style={{ paddingLeft: 0, paddingRight: 0, margin: 0 }} fluid>
      <Row gutterWidth={0}>
        <SearchSummaryColumn searchSummary={searchSummary} />
        <Col xs={12} md={9} className={styles.rightColumn}>
          <Container className={styles.search} style={{ paddingLeft: 0, paddingRight: 0 }} fluid>
            <Text kind="h1" className="mb-24">
              Advanced search
            </Text>
            <Row className="mb-24">
              <Col xs={12}>
                <TextInput
                  placeholder="Search documents by title, content, tags..."
                  onChange={(e) => setSearchQuery(searchQuery.set("searchAnywhere", (e.target as any).value))}
                  labelText={
                    <>
                      Search anywhere
                      <FormHint>
                        A longer description. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy
                        eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos
                        et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus
                        est
                      </FormHint>
                    </>
                  }
                />
              </Col>
            </Row>

            <DocumentForm
              multipleDocs={true}
              value={searchQuery}
              onChange={(key, value) => {
                if (key === "custom/is_title_visible_to_public") {
                  setSearchQuery(searchQuery.setCustom(key.replace("custom/", "") as keyof SearchQueryCustom, value));
                  return;
                }
                if (key.includes("custom")) return;
                setSearchQuery(searchQuery.set(key.replace("custom/", "") as keyof SearchQuery, value));
              }}
            />

            <Row>
              <Col xs={12} md={3}>
                <Button disabled={!canSubmit} onClick={onSubmit}>
                  Perform search
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
});

export default AdvancedSearchMenu;
