import React from "react";
import styles from "./styles.module.scss";

const TabContent = (props: { children: React.ReactNode, withBackground?: boolean, withPadding?: boolean }) => {
  const {children, withBackground = true, withPadding = true} = props;
  const inlineStyles = {};
  if (!withPadding) {
    inlineStyles['padding'] = '0px';
  }
  if (withBackground) {
    inlineStyles['background'] = '#ebf1f4';
  }
  return (
    <div className={styles.tabContent} style={inlineStyles}>
      {children}
    </div>
  );
};

export default TabContent;
