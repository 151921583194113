import { Text, Modal } from "@wfp/ui";
import { ModalController } from "~/util/hooks";
import { VErrors } from "~/validation";

const HelpModal = ({ controller, errors }: { controller: ModalController; errors: VErrors<any> }) => {
  type T = Map<any, T | Array<string>>;
  const renderSubMap = (map: T) => {
    const ve = Array.from(map.entries());
    return (
      <ul>
        {ve.map(([fieldName, subMapOrList]) => {
          if (subMapOrList instanceof Map) {
            return <li>{renderSubMap(subMapOrList)}</li>;
          }
          return (
            <li>
              <ul>
                {(subMapOrList as Array<string>).map((reason) => {
                  return (
                    <li className="mb-8 text-error text-dec-circle ml-24">
                      <span className="fs-14">{reason}</span>
                    </li>
                  );
                })}
              </ul>
            </li>
          );
        })}
      </ul>
    );
  };
  return (
    <Modal
      open={controller.shown}
      modalHeading="Submit requirements"
      modalLabel="Upload"
      onRequestClose={controller.close}
      modalFooter={() => <></>}
    >
      <Text className="fs-14 mb-16">These are the requirements you haven't met:</Text>
      {renderSubMap(errors)}
    </Modal>
  );
};

export default HelpModal;
