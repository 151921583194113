import { Icon, Text, Button, Loading } from "@wfp/ui";
import styles from "./styles.module.scss";
import SearchQuery from "~/searchQuery";
import { iconClose } from "@wfp/icons";
import IconButton from "../IconButton";
import savedSearchStore, { SavedSearchInfo } from "~/stores/savedSearch";
import { iconArrowRight } from "@wfp/icons";
import ReactDOM from "react-dom";
import React, { useMemo } from "react";
import { ReqStatus } from "~/util";
import { observer } from "mobx-react";

const GlobalElem = (props: { children: React.ReactNode }) => ReactDOM.createPortal(props.children, document.body);

interface SuggestionListProps {
  suggestions: SavedSearchInfo[];
  onSearch: (sq: SearchQuery, saved: SavedSearchInfo) => void;
  shown: boolean;
  onClose: () => void;
}

const LoadingWrapper = (props: { children: React.ReactNode; loading: boolean }) => {
  if (props.loading) {
    return (
      <div className="df h-100 w-100 fc f-100">
        <Loading className="loader loader_sm" size={10} withOverlay={false}>
          Loading...
        </Loading>
      </div>
    );
  }
  return <>{props.children}</>;
};

const SuggestionsList = observer((props: SuggestionListProps) => {
  const { onClose, shown, suggestions, onSearch } = props;
  const loading = savedSearchStore.f.deletionStatus === ReqStatus.InProcess;
  const rendered = useMemo(() => {
    const items = suggestions.map((sug, idx) => {
      const title = `Search for "${sug.search_value}"`;
      return (
        <Button
          key={idx}
          kind="ghost"
          className={styles.suggestionItem}
          title={title}
          aria-label={title}
          onClick={(e) => {
            e.preventDefault();
            onSearch(SearchQuery.fromSavedSearch(sug), sug);
          }}
        >
          <div className="df fdr fac">
            <Icon className={styles.suggestionsArrow} icon={iconArrowRight} description="" />
            <Text>{sug.description}</Text>
          </div>
          <IconButton
            description="Remove"
            className="is-12 ph-0"
            icon={iconClose}
            onClick={async (e) => {
              e.stopPropagation();
              await savedSearchStore.f.delete(sug.id);
            }}
          />
        </Button>
      );
    });
    return <LoadingWrapper loading={loading}>{items}</LoadingWrapper>;
  }, [suggestions, loading]);
  return (
    <>
      {shown && (
        <GlobalElem>
          <div className={styles.suggestionsWrapper} onClick={() => onClose()} />
        </GlobalElem>
      )}
      <div className={styles.suggestions}>{rendered}</div>
    </>
  );
});

export default SuggestionsList;
