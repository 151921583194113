import { observer } from "mobx-react";
import { useMultiBooleanHash } from "~/util/hooks";
import LastUpdatedDocsTab from "./LastUpdatedDocsTab";
import BookmarksTab from "./BookmarksTab";
import SavedSearchTab from "./SavedSearchTab";
import MyDocsTab from "./MyDocsTab";
import TemplatesTab from "./TemplatesTab";
import TabsPageView from "~/components/TabsPageView";
import React from "react";

const tabs = [
  { id: "docs", label: "My documents", render: (selected: boolean) => <MyDocsTab selected={selected} /> },
  {
    id: "last-updated",
    label: "Last updated by me",
    render: (selected: boolean) => <LastUpdatedDocsTab selected={selected} />,
  },
  {
    id: "bookmarks",
    label: "Bookmarked documents",
    render: (selected: boolean) => <BookmarksTab selected={selected} />,
  },
  { id: "saved", label: "Saved searches", render: (selected: boolean) => <SavedSearchTab selected={selected} /> },
  { id: "templates", label: "My templates", render: (selected: boolean) => <TemplatesTab selected={selected} /> },
];

const KEYS = new Set(tabs.map((a) => a.id));

const PersonalArea = observer(() => {
  const nav = useMultiBooleanHash(KEYS);
  const selected = nav.value !== null ? nav.value : tabs[0].id;
  const setSelected = (s: string) => {
    nav.setValue(s);
  };
  const selectedId = selected !== null ? tabs.findIndex((a) => a.id === selected) : 0;
  return (
    <TabsPageView breadcrumbsItems={[{label: 'My personal area'}]} tabs={tabs} pageTitle="My personal area" defaultSelectedTab={selectedId} onTabChange={setSelected} />
  );
});

export default PersonalArea;
