import notificationStore, { NotificationStore } from "~/stores/notifications";
import { ReqStatus } from "~/util";
import { makeAutoObservable, runInAction } from "mobx";
import * as api from "~/api";

export type GovernanceFrameworkId = string;

export interface GovernanceFrameworkNode {
  id: GovernanceFrameworkId;
  api_url: string;
  name: string;
}

export class GovernanceFrameworkStore {
  notifStore: NotificationStore;
  governanceFrameworks: GovernanceFrameworkNode[];
  status: ReqStatus = ReqStatus.Initial;

  get loading(): boolean {
    return this.status === ReqStatus.InProcess;
  }

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.governanceFrameworks = [];
    this.notifStore = notifStore;
  }

  loadGovernanceFrameworks = async (): Promise<GovernanceFrameworkNode[]> => {
    if (this.status === ReqStatus.Success) return this.governanceFrameworks;
    runInAction(() => {
      this.status = ReqStatus.InProcess;
    });
    try {
      const resp = await api.get("ebms:governanceframeworks");
      const governanceFrameworks = resp!.data;
      runInAction(() => {
        this.governanceFrameworks = governanceFrameworks;
        this.status = ReqStatus.Success;
      });
      return governanceFrameworks;
    } catch (err) {
      this.notifStore.error("Failed to load governanceFrameworks");
      this.status = ReqStatus.Failed;
      return [];
    }
  }
}

const governanceFrameworkStore = new GovernanceFrameworkStore(notificationStore);

export default governanceFrameworkStore;
