import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Modal, TextInput, Link, Button, Search } from "@wfp/ui";
import { Container, Row, Col } from "react-grid-system";
import styles from "./styles.module.scss";
import paths from "~/paths";
import SearchQuery from "~/searchQuery";
import cn from "classnames";
import savedSearchStore, { SavedSearch, SavedSearchInfo } from "~/stores/savedSearch";
import { observer } from "mobx-react";
import AdvancedFiltersDisplay from "./AdvancedFiltersDisplay";
import SuggestionsList from "../SuggestionsList";
import { ReqStatus } from "~/util";
import { config, Transition } from "@react-spring/core";
import { animated } from "react-spring";
import DocumentsContext from "~/pages/Documents/DocumentsContext";
import { ToastNotificationContext } from "~/components/ToastNotificationContext";

interface Props {
  initialQuery: SearchQuery | null;
  searchQuery: SearchQuery;
  className?: string;
  onSearch: (searchQuery: SearchQuery) => void;
  onClear?: () => void;
  saveSearchEnabled?: boolean;
}

const SearchBar = observer((props: Props) => {
  const { searchQuery, setSearchQuery } = useContext(DocumentsContext);

  const cantSearch = !searchQuery.isValid();

  // suggestion list trigger
  const [focused, setFocused] = useState(false);
  const { showNotificationWithTimeout, setNotificationData } = useContext(ToastNotificationContext);

  useEffect(() => {
    (async () => {
      try {
        await savedSearchStore.f.load("", null);
      } catch (err) {}
    })();
  }, []);

  const [prevSearchQuery, setPrevSearchQuery] = useState<SearchQuery | null>(null);
  useEffect(() => {
    setPrevSearchQuery(props.initialQuery);
  }, [props.initialQuery]);

  const onSearch = (sq: SearchQuery) => {
    if (sq.isEmpty() && props.onClear) {
      props.onClear();
    }
    if (!sq.isValid()) return;
    setFocused(false);
    setPrevSearchQuery(sq);
    props.onSearch(sq);
  };

  const onSearchChange = (value: string) => {
    if (value && value.trim().startsWith("WFP-")) {
      setSearchQuery(searchQuery.set("protocol", value));
    } else {
      setSearchQuery(searchQuery.set("title", value));
    }
  };

  const savedSearchEntry = savedSearchStore.isSaved(searchQuery);
  const emptyQuery = searchQuery.isEmpty();

  const [showSaveSearchModal, setShowSaveSearchModal] = useState<boolean>(false);
  const [savedSearchName, setSavedSearchName] = useState<string>("");
  const [chosenSearchQuery, setChosenSearchQuery] = useState<SavedSearchInfo | null>(null);
  const suggestions =
    chosenSearchQuery !== null
      ? savedSearchStore.f.itemsList.filter((a) => a.id !== chosenSearchQuery.id)
      : savedSearchStore.f.itemsList;

  useEffect(() => {
    setSavedSearchName(searchQuery.title);
  }, [searchQuery.title]);

  const canSaveSearch = savedSearchName.trim().length !== 0 || savedSearchStore.createReqStatus === ReqStatus.InProcess;

  const saveSearchEnabled = props.saveSearchEnabled ?? false;

  const saveSearch = async () => {
    savedSearchStore
      .saveQueryAs(searchQuery, savedSearchName)
      .then(() =>
        setNotificationData({ kind: "success", title: "Success", subtitle: "Search has been saved successfully" })
      )
      .catch(() =>
        setNotificationData({ kind: "error", title: "Search was not saved due to some API error", subtitle: "" })
      )
      .finally(() => {
        showNotificationWithTimeout();
        setShowSaveSearchModal(false);
      });
  };

  const saveSearchButton = (() => {
    if (!saveSearchEnabled || emptyQuery || savedSearchStore.f.loading) return null;
    if (!savedSearchEntry) {
      return (
        <Link
          href="#"
          className="fs-14"
          disabled={!canSaveSearch}
          onClick={(e: any) => {
            e.preventDefault();
            setShowSaveSearchModal(true);
          }}
        >
          Save search
        </Link>
      );
    }
    return (
      <Link href={paths.construct.savedSearchManagement()} className="fs-14 text-02" title="Manage your saved searches">
        Saved as "{savedSearchEntry!.description}"
      </Link>
    );
  })();

  const shown = focused && suggestions.length !== 0 && !savedSearchStore.f.loading;

  return (
    <Container className={cn([styles.search, props.className])}>
      <Modal
        open={showSaveSearchModal}
        modalHeading="Save this search"
        modalLabel="Search"
        onRequestClose={() => setShowSaveSearchModal(false)}
        onRequestSubmit={saveSearch}
        primaryButtonDisabled={!canSaveSearch}
        primaryButtonText="Confirm"
      >
        <TextInput
          placeholder="Search description"
          value={savedSearchName}
          onChange={(e: any) => setSavedSearchName(e.target.value)}
        />
      </Modal>

      <Row gutterWidth={8} className="mb-12">
        <Col xs={9} xl={10} className="ph-0">
          <Search
            className={styles.searchInput}
            onFocus={() => setFocused(true)}
            id="some-search"
            kind="large"
            name="search-query"
            closeButtonLabelText="Clear search"
            autoComplete="off"
            placeholder="Search..."
            value={searchQuery.title}
            onChange={onSearchChange}
            onClick={(e: any) => e.stopPropagation()}
            onKeyDown={(e: any) => {
              if (e.keyCode === 13) {
                // enter
                onSearch(searchQuery);
              }
            }}
          />
          <div className="relpos" style={{ zIndex: 99999999 }}>
            <Transition
              items={shown}
              from={{ opacity: 0 }}
              enter={{
                opacity: 1,
                boxShadow: "0px 0px 2px 0px #00000017, 0px 0px 8px 0px #00000012",
              }}
              leave={{ opacity: 0 }}
              config={config.wobbly}
            >
              {(props, show) =>
                show && (
                  <animated.div style={{ ...props }} className={styles.documentViewWrapper}>
                    <SuggestionsList
                      onClose={() => setFocused(false)}
                      shown={shown}
                      suggestions={suggestions}
                      onSearch={(sq, saved) => {
                        setChosenSearchQuery(saved);
                        onSearch(sq);
                      }}
                    />
                  </animated.div>
                )
              }
            </Transition>
          </div>
        </Col>
        <Col xs={3} xl={2}>
          <Button onClick={() => onSearch(searchQuery)} style={{ width: "100%" }}>
            Search
          </Button>
        </Col>
      </Row>
      <Row gutterWidth={8}>
        <Col xs={10}>
          <AdvancedFiltersDisplay onSearch={onSearch} searchQuery={prevSearchQuery} />
        </Col>
        <Col xs={2}>
          <div className="w-100 tar">{saveSearchButton}</div>
        </Col>
      </Row>
    </Container>
  );
});

export default SearchBar;
