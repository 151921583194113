import cn from "classnames";
import styles from "./styles.module.scss";

interface Props {
  count: number;
  kind: "line" | "rectangle" | "picture";
  isStatic?: boolean;
  randomSize?: boolean;
  align?: "right" | "left";
}

const Skeleton = (props: Props) => {
  // TODO: Implement dynamic mode
  const isStatic = props.isStatic ?? true;
  const isRandom = props.randomSize ?? true;
  const kind = props.kind;
  const align = props.align ?? "left";
  let res: JSX.Element[] = [];
  for (let i = 0; i < props.count; ++i) {
    const style: any = {};
    if (isRandom) {
      if (kind === "line") {
        const width = `${70 + Math.random() * 30}%`;
        style["width"] = width;
      }
    }
    const elem = (
      <div
        className={cn({
          [styles.skeleton]: true,
          [styles.lineSkeleton]: kind === "line",
          [styles.rectSkeleton]: kind === "rectangle",
          [styles.pictureSkeleton]: kind === "picture",
          [styles.skeletonRight]: align === "right",
        })}
        style={style}
      ></div>
    );
    res.push(elem);
  }
  return <>{res}</>;
};

export default Skeleton;
