const HighlightedText = (props: { text: string; range: [number, number] }) => {
  const { text, range } = props;
  const leftSide = text.substr(0, range[0]);
  const highlightedPart = text.substring(range[0], range[1]);
  const rightSide = text.substr(range[1], text.length);
  return (
    <>
      {leftSide}
      <mark>{highlightedPart}</mark>
      {rightSide}
    </>
  );
};

export default HighlightedText;
