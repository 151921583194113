import React from "react";
import { Icon } from "@wfp/ui";
import { iconWarningGlyph } from "@wfp/icons";

type Props = {
  visible: boolean;
  text: string;
  fullHeight?: boolean;
}

const InputErrorText = ({visible, text, fullHeight}: Props) => {
  return visible ? (
    <div className="wfp--form-requirement" style={{display: 'block', maxHeight: fullHeight ? 'initial' : 0}}>
      <Icon icon={iconWarningGlyph} style={{marginRight: 3}} fill="#c5192d" />
      <span>{text}</span>
    </div>
  ) : null;
};

export default InputErrorText;
