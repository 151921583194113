import React, { useCallback, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { FormLabel, InlineLoading, Modal, ModalProps, Tab, Tabs, TextArea, TextInput } from "@wfp/ui";
import agendaItemStore, { AgendaItem, AgendaItemStatus } from "~/stores/agendaItems";
import { meetingLanguagesList } from "~/stores/meeting";
import Select from "react-select";
import { observer } from "mobx-react";

type Props = {
  defaultAgendaItem: Partial<AgendaItem>;
  isEditing?: boolean;
  group?: "numbered" | "bottom";
  meetingId: string;
} & ModalProps;

const AgendaItemModal = observer(
  ({
    onRequestSubmit,
    defaultAgendaItem,
    isEditing,
    primaryButtonText = "Submit",
    group,
    meetingId,
    ...props
  }: Props) => {
    const [form, setForm] = useState<Partial<AgendaItem>>(defaultAgendaItem);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [mainInputTouched, setMainInputTouched] = useState(false);

    const onSubmit = useCallback(async () => {
      if (form.title_en && form.meeting) {
        if (isEditing) {
          const { api_url, history, meeting, meetingdocs, ...editingPayload } = form;
          const updatedAgendaItem = await agendaItemStore.patchAgendaItem(editingPayload);
          if (updatedAgendaItem) {
            setForm(updatedAgendaItem);
          }
        } else {
          const payload = { ...form, group };
          await agendaItemStore.postAgendaItem(payload);
          setForm({ title_en: "", status: "draft", meeting: payload.meeting, group });
        }
        await onRequestSubmit();
      }
    }, [form, isEditing, onRequestSubmit]);
    const positionOptions = useMemo(() => {
      if (!isEditing) return [];
      const agendaItems = agendaItemStore.agendaItemsByMeeting?.[meetingId] || [];
      const hasGroupChanged = form.group !== defaultAgendaItem.group;
      let positionOptionsNumber = agendaItems.filter(({ group }) => group === form.group).length;
      if (hasGroupChanged) {
        positionOptionsNumber += 1;
      }

      return Array.from(Array(positionOptionsNumber)).map((_, index) => ({
        label: index + 1,
        value: index + 1,
      }));
    }, [isEditing, form, defaultAgendaItem]);

    return (
      <Modal
        {...props}
        primaryButtonText={primaryButtonText}
        modalHeading="Add agenda item"
        onRequestSubmit={onSubmit}
        primaryButtonDisabled={!form.title_en}
      >
        <Tabs
          selected={selectedIndex}
          onSelectionChange={(i: number) => {
            setSelectedIndex(i);
          }}
          disableAnimation
        >
          {meetingLanguagesList.map((lang, i) => {
            const isEnglish = i === 0;
            const titleKey = `title_${lang.key}`;
            const notesKey = `notes_${lang.key}`;
            return (
              <Tab
                key={`create-agenda-item-${lang.key}`}
                label={lang.title}
                selected={selectedIndex === i}
                tabIndex={i}
                href={lang.key}
                role={lang.key}
              >
                <div className={styles.formBody}>
                  {agendaItemStore.loading && <InlineLoading />}
                  <TextInput
                    labelText={isEnglish ? "Title*" : "Title"}
                    onChange={(e) => setForm((prev) => ({ ...prev, [titleKey]: e.target.value }))}
                    onBlur={() => {
                      if (isEnglish) {
                        setMainInputTouched(true);
                      }
                    }}
                    value={form[titleKey] || ""}
                    invalid={isEnglish && mainInputTouched && !form.title_en}
                    invalidText="Title is a required field"
                  />
                  <div>
                    <FormLabel>Status</FormLabel>
                    <Select
                      classNamePrefix="select"
                      onChange={({ value }) => setForm((prev) => ({ ...prev, status: value }))}
                      value={{ label: AgendaItemStatus[form.status], value: form.status }}
                      options={Object.entries(AgendaItemStatus)
                        .slice(0, 2)
                        .map(([value, label]) => ({ label, value }))}
                      placeholder="Select meeting type"
                    />
                  </div>
                  <TextArea
                    labelText="Notes"
                    onChange={(e) => setForm((prev) => ({ ...prev, [notesKey]: e.target.value }))}
                    value={form[notesKey]}
                  />
                  {isEditing && (
                    <div className="df jcsb gap-15">
                      <div className="f-100">
                        <FormLabel>Group</FormLabel>
                        <Select
                          options={[
                            { label: "top", value: "top" },
                            { label: "numbered", value: "numbered" },
                            { label: "bottom", value: "bottom" },
                          ]}
                          value={{ label: form.group, value: form.group }}
                          onChange={({ value }) => setForm((prev) => ({ ...prev, group: value, position: 1 }))}
                        />
                      </div>
                      <div className="f-100">
                        <FormLabel>Position</FormLabel>
                        <Select
                          options={positionOptions}
                          value={{ label: form.position, value: form.position }}
                          onChange={({ value }) => setForm((prev) => ({ ...prev, position: value }))}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </Tab>
            );
          })}
        </Tabs>
      </Modal>
    );
  }
);

export default AgendaItemModal;
