import React, { useContext, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { MeetingId, MeetingLanguage } from "~/stores/meeting";
import { Blockquote, Button, InlineLoading } from "@wfp/ui";
import { observer } from "mobx-react";
import agendaItemStore, { AgendaItem } from "~/stores/agendaItems";
import AgendaItemModal from "~/components/AgendaItems/AgendaItemModal/AgendaItemModal";
import agendaItemDocsStore from "~/stores/agendaItemDocs";
import AgendaItemsList from "~/components/AgendaItems/AgendaItemsField/AgendaItemsList";
import AgendaItemsDNDWrapper, {
  AgendaItemsDNDContext,
} from "~/components/AgendaItems/AgendaItem/AgendaItemsDNDWrapper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";

type Props = {
  lang: MeetingLanguage;
  meetingId: MeetingId;
};

const AgendaItemsField = observer(({ lang, meetingId }: Props) => {
  const [agendaItemModalOpened, setAgendaItemModalOpened] = useState(false);
  const [creatingItemNumbered, setCreatingItemNumbered] = useState(false);
  const { shouldCollapseAllDocs, toggleCollapseAllDocs } = useContext(AgendaItemsDNDContext);

  const showAgendaItemModal = (numbered: boolean) => {
    setCreatingItemNumbered(numbered);
    setAgendaItemModalOpened(true);
  };
  const hideAgendaItemModal = () => {
    setAgendaItemModalOpened(false);
  };

  const agendaItemModalSubmitCallback = () => {
    return agendaItemStore.loadAgendaItems(meetingId).finally(hideAgendaItemModal);
  };

  const agendaItems = useMemo(
    () => agendaItemStore.agendaItemsByMeeting?.[meetingId] || [],
    [agendaItemStore.agendaItemsByMeeting?.[meetingId]]
  );

  const agendaItemsIds = useMemo(() => agendaItems.map(({ id }) => id), [agendaItems]);

  const availableDocsIds = useMemo(
    () => agendaItemDocsStore.getAvailableDocsForMeeting(meetingId, agendaItemsIds),
    [
      agendaItemsIds,
      meetingId,
      agendaItemDocsStore.documentsByMeeting[meetingId],
      agendaItemDocsStore.documentsByAgendaItem,
    ]
  );

  useEffect(() => {
    if (meetingId) {
      agendaItemStore.loadAgendaItems(meetingId);
    }
  }, [meetingId]);

  return (
    <div>
      {meetingId && (
        <div className="section-title df fvc jcs">
          {shouldCollapseAllDocs ? (
            <FontAwesomeIcon className="caret-icon" icon={solid("caret-up")} onClick={toggleCollapseAllDocs} />
          ) : (
            <FontAwesomeIcon className="caret-icon" icon={solid("caret-down")} onClick={toggleCollapseAllDocs} />
          )}
          <div>Agenda Items</div> {agendaItemStore.loading && <InlineLoading style={{ height: 18 }} className="f-0" />}
        </div>
      )}
      {!meetingId && (
        <div className="disabled-btn-container">
          <Button disabled kind="secondary">
            Agenda Items
          </Button>
          <Blockquote>Please save this meeting before adding links</Blockquote>
        </div>
      )}
      {!!agendaItems && !!meetingId && (
        <div className={styles.agendaItems}>
          <div className={styles.agendaItemsBody}>
            <AgendaItemsList lang={lang} meetingId={meetingId} availableDocsIds={availableDocsIds} />
          </div>
          <div className={styles.agendaItemsFooter}>
            <Button kind="secondary" onClick={() => showAgendaItemModal(true)}>
              Add numbered
            </Button>
            <Button kind="secondary" onClick={() => showAgendaItemModal(false)}>
              Add unnumbered
            </Button>
          </div>

          <AgendaItemModal
            open={agendaItemModalOpened}
            meetingId={meetingId}
            onRequestClose={hideAgendaItemModal}
            defaultAgendaItem={{
              meeting: meetingId,
              title_en: "",
              status: "draft",
            }}
            group={creatingItemNumbered ? "numbered" : "bottom"}
            onRequestSubmit={agendaItemModalSubmitCallback}
          />
        </div>
      )}
    </div>
  );
});

export default (props: Props) => (
  <AgendaItemsDNDWrapper>
    <AgendaItemsField {...props} />
  </AgendaItemsDNDWrapper>
);
