import keyBy from "lodash/keyBy";
import { runInAction, makeAutoObservable } from "mobx";
import * as api from "~/api";
import { deleteTokens, refreshAccessToken, LocalStorageKeys, getAuthTokens, logout } from "~/auth/pkce";
import notifStore, { NotificationStore } from "./notifications";
import { UserPermission, UserInfo } from "~/user";

export class AuthStore {
  loading: boolean = true;
  loggedIn: boolean = false;
  userInfo: UserInfo | null = null;
  notifStore: NotificationStore;

  constructor(notifStore: NotificationStore) {
    makeAutoObservable(this);
    this.notifStore = notifStore;
    this.loadFromLocalStorage();
  }

  async fetchUserInfo() {
    this.loading = true;

    if (!this.loggedIn) {
      runInAction(() => {
        this.userInfo = null;
      });
      return;
    }

    const username = localStorage.getItem(LocalStorageKeys.USERNAME);
    const email = localStorage.getItem(LocalStorageKeys.EMAIL);
    if (!username || !email) return null;

    runInAction(() => {
      this.userInfo = {
        username,
        email,
        avatar: null,
      } as any;
    });
    this.loading = false;

    // load user permissions & other stuff
    try {
      const avatarResp = await api.get("user-options/for-user");
      if (avatarResp !== null) {
        const avatarRespData: Partial<UserInfo> = avatarResp.data;
        runInAction(() => {
          this.userInfo = {
            ...this.userInfo,
            ...(avatarRespData as any),
            permissions: new Set(avatarResp.data.permissions),
            allowedDocTypes: keyBy(avatarResp.data.allowed_document_types, "id"),
          };
        });
      }
    } catch (err) {
      this.notifStore.error("Failed to load basic user information");
    }

    // load the avatar
    try {
      const avatarResp = await api.get("user-image");
      if (avatarResp !== null) {
        const avatar = avatarResp.data;
        if ("message" in avatar) {
          // no user image
        } else {
          runInAction(() => {
            (this.userInfo as any).avatar = `data:${avatar.content_type};base64,${avatar.image_base64}`;
          });
        }
      }
    } catch (err) {
      this.notifStore.error("Failed to load the user avatar");
    }
  }

  getExpirationDate() {
    const e = localStorage.getItem(LocalStorageKeys.EXPIRATION);
    if (!e) return null;
    return parseInt(e);
  }

  logOut = async () => {
    runInAction(() => {
      this.loggedIn = false;
    });
    deleteTokens();
    await logout();
  };

  async getAuthToken() {
    const expiration = this.getExpirationDate();
    const currTime = Math.round(new Date().getTime() / 1000);

    if (expiration === null) {
      // need to log out in this case
      this.logOut();
      return null;
    }

    let accessToken = localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN);

    if (currTime >= expiration || accessToken === null) {
      // refresh if expired
      accessToken = await refreshAccessToken();
    }

    return accessToken;
  }

  async getRefreshToken() {
    return localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);
  }

  loadFromLocalStorage() {
    const refreshToken = localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN);

    if (!refreshToken) {
      this.loggedIn = false;
      return;
    }

    // we assume that we can refresh the actual auth token during the api call
    this.loggedIn = true;
  }

  // Handle the code provided by the auth callback
  async loginWithCode(code: string) {
    // will be saved into the local storage
    const authTokens = await getAuthTokens(code);
    this.loadFromLocalStorage();
  }

  hasPermission(perm: UserPermission) {
    if (this.userInfo === null || this.userInfo.permissions === undefined) {
      return false;
    }
    return this.userInfo.permissions.has(perm);
  }
}

const authStore = new AuthStore(notifStore);

export default authStore;
