import React, { useEffect, useMemo } from "react";
import styles from "./styles.module.scss";
import TabTable from "~/pages/PersonalArea/TabTable";
import { Column, usePagination, useSortBy, useTable } from "react-table";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import meetingStore, { MeetingNode, meetingStatusMap } from "~/stores/meeting";
import { observer } from "mobx-react";
import { FetcherState } from "~/pages/PersonalArea/common";
import { PaginatedResult } from "~/util/PaginatedEndpoint";
import paths from "~/paths";
import Accordions, { useMeetingAccordionState } from "~/components/Accordions/Accordions";
import { useSkipInitialRender } from "~/util/hooks";
import moment from "moment";
import { PUB_DATE_FORMAT } from "~/constants";
import meetingTypeStore from "~/stores/meetingTypes";
import { paramsToObject } from "~/util";
import { runInAction } from "mobx";

type Props = {
  fetcher: FetcherState<MeetingNode>;
  items: PaginatedResult<MeetingNode>;
};

const MeetingsTable = observer(({ fetcher, items }: Props) => {
  let [searchParams] = useSearchParams();
  const meetingsTableData = useMemo(() => items.results || [], [items]);

  const meetingTypes = useMemo(() => meetingTypeStore.meetingTypes, [meetingTypeStore.meetingTypesList]);

  const columns = useMemo(
    () =>
      [
        {
          Header: "Title",
          accessor: `title_en`,
          Cell: ({ value, cell }) => {
            const { id } = cell.row.original;
            return (
              <NavLink
                data-testid={`${value}-meeting`}
                title={value}
                className="wfp--link two-lines-text-truncate"
                to={paths.construct.meetingPreview(id.toString())}
              >
                {value}
              </NavLink>
            );
          },
          width: 350,
          minWidth: 150,
          maxWidth: 600,
        },
        {
          Header: "Meeting type",
          accessor: "meeting_type",
          Cell: ({ cell }) => {
            const meetingType = cell.row.original.meeting_type;
            return meetingTypes[meetingType] || "";
          },
        },
        {
          Header: "Meeting code",
          accessor: "code",
        },
        {
          Header: "Start date",
          accessor: "start_date",
          Cell: ({ cell }) => {
            return moment(cell.row.original.start_date).format(PUB_DATE_FORMAT);
          },
        },

        {
          Header: "Status",
          accessor: "status",
          Cell: ({ cell }) => {
            const status = cell.row.original.status;
            return meetingStatusMap[status] || "";
          },
        },
      ] as Column<MeetingNode>[],
    [meetingTypes]
  );

  const accordionData = useMeetingAccordionState(fetcher.itemsCombined);

  const tableInstance = useTable(
    {
      columns: columns,
      data: meetingsTableData as MeetingNode[],
    },
    usePagination
  );

  useEffect(() => {
    runInAction(() => {
      meetingStore.meetingsQueryParams = { ordering: searchParams.get(fetcher.orderingKey) || "" };
    });
  }, [searchParams.get(fetcher.orderingKey), fetcher.orderingKey]);

  const onSort = () => fetcher.onSortingChange(tableInstance.state.sortBy);
  useSkipInitialRender(onSort, [tableInstance.state.sortBy]);
  useSkipInitialRender(() => fetcher.reload(), [meetingStore.meetingsQueryParams]);

  useEffect(() => {
    meetingTypeStore.loadMeetingTypes();
  }, []);

  return (
    <div className={styles.meetingsTableContainer}>
      <div className="hide-mobile">
        <TabTable
          fetcher={fetcher}
          tableInstance={tableInstance}
          emptyTitle="No meetings"
          paginationProps={{
            pageSizesDisabled: true,
          }}
          emptyMessage="No meetings uploaded"
        />
      </div>
      <Accordions items={accordionData} fetcher={fetcher} containerClassName="only-mobile" />
    </div>
  );
});

export default MeetingsTable;
