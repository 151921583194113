import divisionStore, { Division, DivisionId } from "~/stores/divisions";
import { useRef, useCallback, useState } from "react";
import { observer } from "mobx-react";
import AsyncPaginatedSelect from "~/components/AsyncPaginatedSelect";

interface Props {
  value: DivisionId[];
  onChange: (newValue: DivisionId[]) => void;
  selectProps?: any;
}

const DivisionSelect = observer((props: Props) => {
  const fetcher = divisionStore.f;
  const idToOpt = (id: DivisionId) => {
    const item = fetcher.items[id];
    if (!item) return null;
    return {
      label: item.name,
      value: item.id,
    };
  };
  return (
    <AsyncPaginatedSelect
      fetcher={divisionStore.f}
      itemIdToOption={idToOpt}
      value={props.value}
      onChange={props.onChange}
      selectProps={{
        isMulti: true,
        ...(props.selectProps || {}),
      }}
    />
  );
});

export default DivisionSelect;
