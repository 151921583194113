import { makeAutoObservable } from "mobx";
import PaginatedEndpoint from "~/util/PaginatedEndpoint";
import notifStore from "~/stores/notifications";
import { ProjectId, ProjectInfo } from "~/document";

export type { ProjectId, ProjectInfo };

export class ProjectStore {
  f = new PaginatedEndpoint<ProjectInfo>("projects", notifStore);

  constructor() {
    makeAutoObservable(this);
  }
}

const projectStore = new ProjectStore();

export default projectStore;
