import React, { useContext, useMemo } from "react";
import styles from "./styles.module.scss";
import AccordionItem, { AccordionItemType } from "~/components/Accordions/AccordionItem";
import { Button, Loading, Link } from "@wfp/ui";
import { FetcherState } from "~/pages/PersonalArea/common";
import { meetingEventTypes, MeetingNode, meetingStatusMap } from "~/stores/meeting";
import { NavLink } from "react-router-dom";
import paths from "~/paths";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MeetingLanguageContext } from "~/pages/Meetings/MeetingsTab";
import { splitMeetingCodes } from "~/pages/UploadPage";
import { DocItemType, Document } from "~/document";
import DocItem from "~/components/DocItem";

type Props = {
  items: AccordionItemType[];
  containerClassName?: string;
  fetcher: FetcherState<any>;
};

export const useMeetingAccordionState = (meetings: MeetingNode[]) => {
  const { lang } = useContext(MeetingLanguageContext);

  return useMemo(() => {
    const data: AccordionItemType[] = [];
    meetings.forEach((meeting) => {
      const item: AccordionItemType = { title: meeting[`title_${lang}`], data: [] };
      item.data.push(
        {
          label: "Meeting code",
          value: meeting.code,
        },
        {
          label: "Event type",
          value: meetingEventTypes[meeting.event_type],
        },
        {
          label: "Event title",
          value: meeting[`title_${lang}`] || meeting["title_en"],
        },
        {
          label: "Start date",
          value: meeting.start_date,
        },
        {
          label: "Meeting type",
          value: meeting.meeting_type,
        },
        {
          label: "Status",
          value: meetingStatusMap[meeting.status],
        },
        {
          value: (
            <div className="df gap-15">
              <NavLink to={paths.construct.editMeeting(+meeting.id)}>
                <FontAwesomeIcon icon={regular("pen-to-square")} />
              </NavLink>
              <NavLink to={paths.construct.meetingPreview(+meeting.id)}>
                <FontAwesomeIcon icon={solid("magnifying-glass")} />
              </NavLink>
              <NavLink to={paths.construct.cloneMeeting(+meeting.id)}>
                <FontAwesomeIcon icon={regular("clone")} />
              </NavLink>
            </div>
          ),
        }
      );
      data.push(item);
    });
    return data;
  }, [meetings, lang]);
};

export const useDocItemsAccordionState = (documents: DocItemType[]) => {
  return useMemo(() => {
    const data: AccordionItemType[] = [];
    documents.forEach((document) => {
      const item: AccordionItemType = { title: document.title, data: [] };
      item.data.push(
        {
          label: "Document title",
          value: (
            <NavLink style={{ width: 200, minWidth: 100 }} to={paths.construct.docDetail(document.protocol.toString())}>
              <Link>{document.title}</Link>
            </NavLink>
          ),
        },
        {
          label: "Document Symbol",
          value: document.symbol,
        },
        {
          label: "Meeting codes",
          value: document.meetings.join(", "),
        },
        {
          label: "Date",
          value: document.creation_date,
        },
        {
          label: "Language",
          value: document.language_display,
        }
      );
      data.push(item);
    });

    return data;
  }, [documents]);
};

const Accordions = ({ items, containerClassName = "", fetcher }: Props) => {
  return (
    <div className={`${styles.container} ${containerClassName}`}>
      {items.map((item, i) => (
        <AccordionItem item={item} key={`accordion-item-${item.title}-${i}`} />
      ))}
      <div className={styles.footer}>
        {fetcher.nextPageExists && (
          <Button className="mr-4" onClick={fetcher.fetchNextPage} disabled={fetcher.loading}>
            Show more
          </Button>
        )}
        {fetcher.loading && <Loading className="loader loader_sm" withOverlay={false} />}
      </div>
    </div>
  );
};

export default Accordions;
