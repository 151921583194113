import { observer } from "mobx-react";
import { useEffect, useMemo } from "react";
import { Column, usePagination, useTable } from "react-table";
import savedSearchStore, { SavedSearchInfo } from "~/stores/savedSearch";
import TabContent from "./TabContent";
import TabTable, { CommonTabProps } from "./TabTable";
import { TITLE_MAX_CHARS, useDataFetcher } from "./common";
import { NavLink } from "react-router-dom";
import paths from "~/paths";
import SearchQuery from "~/searchQuery";
import IconButton from "~/components/IconButton";
import { iconDelete } from "@wfp/icons";
import { formatDateReadableRange } from "~/util";
import languageStore from "~/stores/language";
import countryStore, { CountryId } from "~/stores/country";
import { flatten } from "lodash/fp";

interface SavedSearchInfoRow extends SavedSearchInfo {
  isBeingDeleted?: boolean;
  pageIndex?: number;
}

const SavedSearchTab = observer((props: CommonTabProps) => {
  const fetcher = useDataFetcher(props.selected, savedSearchStore.f.loadBare, { clientOnlyPagination: true });
  const bookmarks = fetcher.items.results;
  const idsBeingDeleted = savedSearchStore.f.idsBeingDeleted;

  const isDeleting = (id: any) => idsBeingDeleted.has(id);

  const data: SavedSearchInfoRow[] = useMemo(() => {
    return bookmarks.reduce((acc, item) => {
      acc.push({
        ...item,
        isBeingDeleted: isDeleting(item.id),
      });
      return acc;
    }, [] as SavedSearchInfoRow[]);
  }, [bookmarks, idsBeingDeleted]);

  useEffect(() => {
    // preload countries
    const ids = flatten(
      bookmarks.map((a) => a.complex_search_value?.countries).filter((a) => a !== undefined)
    ) as CountryId[];
    countryStore.f.loadIds(ids);
  }, [bookmarks]);

  const columns: ReadonlyArray<Column<SavedSearchInfoRow>> = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "description",
        Cell: (props) => {
          const doc = props.row.original;
          let title = doc.description;
          title = title.length > TITLE_MAX_CHARS ? `${title.substr(0, TITLE_MAX_CHARS)}...` : title;
          const sq = SearchQuery.fromSavedSearch(doc);
          return (
            <div>
              <NavLink className="wfp--link" to={paths.construct.browse(sq)}>
                {title}
              </NavLink>
            </div>
          );
        },
      },
      {
        Header: "Query",
        accessor: "search_value",
      },
      {
        Header: "Languages",
        Cell: (props: any) => {
          const doc = props.row.original;
          return (
            <div className="space-x-6">
              {doc.complex_search_value.languages
                ? doc.complex_search_value.languages.map(languageStore.idToText).join(", ")
                : "None"}
            </div>
          );
        },
      },
      {
        Header: "Countries",
        Cell: (props: any) => {
          const doc = props.row.original;
          return (
            <div className="space-x-6">
              {doc.complex_search_value.countries && doc.complex_search_value.countries.length !== 0
                ? doc.complex_search_value.countries.map(countryStore.idToText).join(", ")
                : "N/A"}
            </div>
          );
        },
      },
      {
        Header: "Expires at",
        Cell: (props: any) => {
          const doc = props.row.original;
          return (
            <div className="space-x-6">
              {doc.complex_search_value.expiration_date
                ? formatDateReadableRange(doc.complex_search_value.expiration_date)
                : "N/A"}
            </div>
          );
        },
      },
      {
        Header: "Release date",
        Cell: (props: any) => {
          const doc = props.row.original;
          return (
            <div className="space-x-6">
              {doc.complex_search_value.release_date
                ? formatDateReadableRange(doc.complex_search_value.release_date)
                : "N/A"}
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: (props) => {
          const doc = props.row.original;
          return (
            <div className="space-x-6">
              <IconButton
                icon={iconDelete}
                className="text-error icon-error"
                description="Delete the bookmark"
                onClick={async (e) => {
                  e.preventDefault();
                  await savedSearchStore.f.delete(doc.id);
                  await fetcher.reload();
                }}
              >
                {doc.isBeingDeleted ? "Deleting..." : "Delete"}
              </IconButton>
            </div>
          );
        },
      },
    ],
    [data, countryStore.f.items]
  );

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const tableInstance = useTable({ columns, data, initialState: { pageIndex: 0 } }, usePagination);
  return (
    <TabContent>
      <TabTable
        fetcher={fetcher}
        tableInstance={tableInstance}
        emptyTitle="No search bookmarks"
        emptyMessage="You have zero saved search queries"
        fluid
      />
    </TabContent>
  );
});

export default SavedSearchTab;
