import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import cn from "classnames";

type Props = {
  onChange: (htmlString: string) => void;
  defaultValue?: string;
  disabled?: boolean;
};

const TextAreaWithLink = ({ onChange, defaultValue, disabled }: Props) => {
  const [markup, setMarkup] = useState("");

  useEffect(() => {
    onChange(markup);
  }, [markup]);

  useEffect(() => {
    setMarkup(defaultValue || "");
  }, [defaultValue]);

  return (
    <div className={cn(styles.container)}>
      <ReactQuill
        readOnly={disabled}
        style={{ width: "100%", fontFamily: '"Open Sans", sans-serif' }}
        modules={{ toolbar: ["link", "bold", "italic"] }}
        formats={["link", "bold", "italic"]}
        value={markup}
        className={cn({ "quill--disabled": disabled })}
        onChange={setMarkup}
      />
    </div>
  );
};

export default TextAreaWithLink;
