import { iconDownloadGlyph } from "@wfp/icons";
import { Document, documentDownloadLink } from "~/document";
import { Button } from "@wfp/ui";

const DocumentDownloadButton = (props: { doc: Document; className?: string }) => {
  return (
    <Button
      small
      kind="secondary"
      className={props.className}
      href={documentDownloadLink(props.doc)}
      target="_blank"
      icon={iconDownloadGlyph}
    >
      Download file
    </Button>
  );
};

export default DocumentDownloadButton;
