import React, { useContext } from "react";
import styles from "./styles.module.scss";
import { MoveAgendaItem, useDropAgendaItem } from "~/components/AgendaItems/AgendaItem/useAgendaItemDND";
import { AgendaItemGroup } from "~/stores/agendaItems";
import { AgendaItemsDNDContext } from "~/components/AgendaItems/AgendaItem/AgendaItemsDNDWrapper";

type Props = {
  group: AgendaItemGroup;
  moveAgendaItem: MoveAgendaItem;
};

const AgendaItemPlaceholder = ({ moveAgendaItem, group }: Props) => {
  const { ref, drop } = useDropAgendaItem(group, 1, moveAgendaItem);
  const { isDNDActive } = useContext(AgendaItemsDNDContext);

  drop(ref);

  return <div ref={ref}>{isDNDActive && <div className={styles.agendaDocPlaceholder}></div>}</div>;
};

export default AgendaItemPlaceholder;
