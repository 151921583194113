import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import ToastNotificationWrapper from "~/components/ToastNotificationContext";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

const AppContext = ({ children }: { children: React.ReactNode }) => {
  return (
    <Router>
      <DndProvider backend={HTML5Backend}>
        <ToastNotificationWrapper>{children}</ToastNotificationWrapper>
      </DndProvider>
    </Router>
  );
};

export default AppContext;
