import { Loading } from "@wfp/ui";

const FullPageLoader = (props: { loading: boolean; children: any }) => {
  const { children, loading } = props;
  if (loading) {
    return (
      <div className="w-100 h-100 f-100 df fc">
        <Loading className="loader loader_sm" withOverlay={false}>
          Loading...
        </Loading>
      </div>
    );
  }
  return children ?? "";
};

export default FullPageLoader;
